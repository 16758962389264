<template>
    <div>
        <div
            :id="'resetPasswordModal' + id"
            class="modal danger-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="resetPasswordModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="resetPasswordModal-title" class="modal-title">Reset Password</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                        <h2 class="marginTop40">Reset</h2>

                        <h3 v-if="passwordReset.user != null" class="fw-normal capitalize">
                            {{ passwordReset.user.displayName }}
                        </h3>
                        <h4 v-if="passwordReset.user != null" class="marginBottom20 fw-normal capitalize">
                            {{ passwordReset.user.email ? '(' + passwordReset.user.email + ')' : '' }}
                        </h4>

                        <p class="marginTop20">If the user has an email address,</p>
                        <p>you can either send password reset instructions</p>
                        <p>to their email or set a temporary password.</p>

                        <p class="marginTop20">If the user does not have an email</p>
                        <p>address, you will only have an option</p>
                        <p class="marginBottom20">to set a temporary password.</p>

                        <template v-if="passwordReset.status != 'processing'">
                            <div v-if="passwordReset.status == 'success'" class="alert alert-success marginBottom20" role="alert" aria-live="polite">
                                <i class="fa fa-check marginRight8" aria-hidden="true" />You have successfully performed password reset!
                            </div>

                            <div v-if="passwordReset.status == 'fail'" class="alert alert-danger-new marginBottom20" role="alert" aria-live="polite">
                                <i class="fa fa-xmark marginRight8" aria-hidden="true" />Error in your password reset, please try again!
                            </div>

                            <button
                                v-if="passwordReset.user.email"
                                class="btn btn-success textWrapAuto width100 margin0"
                                @click.prevent="resetPassword('token')"
                            >
                                <i class="fa fa-paper-plane marginRight8" aria-hidden="true" />Send Password Reset Instructions via Email
                            </button>

                            <button class="btn btn-default textWrapAuto width100 marginLeft0 marginTop20" @click.prevent="resetPassword('pin')">
                                Set Temporary Password to
                                {{ passwordReset.pin }}
                            </button>
                        </template>
                        <template v-else>
                            <div class="col-xs-12" style="text-align: center">Resetting..</div>
                        </template>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['options'],
    data() {
        return {
            id: '',
            passwordReset: {
                user: {},
                status: 'none',
                pin: '',
                type: '',
                organisation: '',
            },
        };
    },
    created() {
        this.id = _.get(this.options, 'id', '');
    },
    mounted() {
        var that = this;
        Events.listen('open-reset-password-modal' + this.id, function (data) {
            that.openResetPasswordModal(data);
        });
    },
    methods: {
        //email/pin type
        resetPassword(type = 'token') {
            this.passwordReset.status = 'processing';
            this.passwordReset.type = type;
            var that = this;
            if (type == 'token') {
                axios
                    .post('password/send-token', {
                        email: this.passwordReset.user.email,
                    })
                    .then(function (response) {
                        that.passwordReset.status = 'success';
                    })
                    .catch(function (errors) {
                        that.passwordReset.status = 'fail';
                    });
            }
            if (type == 'pin') {
                axios
                    .post('users/' + this.passwordReset.user.uuid + '/send-temporary-password', { temporaryPassword: this.passwordReset.pin })
                    .then(function (response) {
                        that.passwordReset.status = 'success';
                    })
                    .catch(function (errors) {
                        that.passwordReset.status = 'fail';
                    });
            }
        },
        openResetPasswordModal(data) {
            this.passwordReset.user = data.user;
            this.passwordReset.status = 'none';
            /*if(this.passwordReset.user.email==null){
            	 this.passwordReset.pin = this.passwordReset.user.identity;
            	//generic
            }else{
            	this.passwordReset.pin = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
            }*/
            this.passwordReset.pin = Math.floor(100000 + Math.random() * 900000).toString();
            $('#resetPasswordModal' + this.id).modal('show');
        },
    },
};
</script>
