<template>
    <div :id="id" ref="mathDiv" :class="{ 'highlight-area': canHighlight && enableHighlighting }">
        <Highlight-Toolbar v-if="canHighlight && false" :question-uuid="questionUuid" @color-selected="setColor" @enable-highlight="enableHighlight" />
        <slot :model="{ output: output }">
            <div class="questionDiv" v-dompurify-html="profanity ? filterProfanity(output) : output" />
        </slot>
    </div>
</template>

<script>
export default {
    props: ['input', 'safe', 'canHighlight', 'questionUuid'],
    data() {
        return {
            id: '',
            attrs: this.$attrs,
            output: '',
            profanity: false,
            //selectedRange: null,
            selectedColor: null,
            selectedText: null,
            enableHighlighting: false,
        };
    },
    watch: {
        input: function (e) {
            this.parseMath();
        },
    },
    created() {
        this.id = this.$attrs.id ? this.$attrs.id : Math.random().toString(36).slice(2);
        this.profanity = _.get(this.$attrs, 'profanity', false);
    },
    mounted() {
        this.parseMath();
    },
    beforeUnmount() {
        let node = this.$refs.mathDiv;
        if (!node) {
            return;
        }
        if (MathJax.typesetClear) {
            if (node) {
                MathJax.typesetClear([node]);
            }
        }
    },
    methods: {
        /*stripSpan(node) {
            let template = document.createElement('template');
            template.innerHTML = node.innerHTML;
            console.log(template.innerHTML);
            node.parentNode.replaceChild(template.content, node);
        },
        walk(node, ancestorHasHighlight = false) {
            let currentNodeHasHighlight =
                node.classList &&
                node.classList.contains('cognalearn-question-highlight');
            //iterate
            if (node.firstChild) {
                this.walk(
                    node.firstChild,
                    currentNodeHasHighlight || ancestorHasHighlight
                );
            }
            //iterate through siblings
            let next = node.nextSibling;
            while (next) {
                this.walk(next, ancestorHasHighlight);
                next = next.NextSibling;
            }
            if (currentNodeHasHighlight && ancestorHasHighlight) {
                this.stripSpan(node);
            } else if (currentNodeHasHighlight) {
                let previousNodeSiblingHasHighlight =
                    node.previousSibling &&
                    node.previousSibling.classList &&
                    node.previousSibling.classList.contains(
                        'cognalearn-question-highlight'
                    );
                if (previousNodeSiblingHasHighlight) {
                    //merge this node to the previous node
                    node.previousSibling.innerHTML += node.innerHTML;
                    node.parentNode.removeChild(node);
                }
            }
        },
        startRange(event) {
            this.rangeFromHere = true;
        },
        saveRange(event) {
            var that = this;
            if (!this.rangeFromHere) {
                return;
            }
            var range = document.getSelection().getRangeAt(0);
            if (range.startOffset == range.endOffset) {
                return;
            }
            let span = document.createElement('span');
            span.classList.add('cognalearn-question-highlight');
            span.appendChild(range.extractContents());
            range.insertNode(span);
            this.walk(document.getElementById(this.attrs.id));
            document.getSelection().removeAllRanges();
            $(span).on('click', function () {
                that.stripSpan(span);
            });
        },
        addClickListener() {},*/
        parseMath() {
            let node = this.$refs.mathDiv;
            if (!node) {
                return;
            }
            if (MathJax.typesetClear) {
                MathJax.typesetClear([node]);
                this.output = this.input;
                Vue.nextTick(function () {
                    MathJax.typesetPromise([node]).then(() => {});
                });
            }
        },
        setColor(color) {
            this.selectedColor = color;
        },
        enableHighlight(value) {
            this.enableHighlighting = value;
        },
        highlightText(event) {
            const selection = window.getSelection();
            if (!selection.rangeCount) return;

            const range = selection.getRangeAt(0);
            const docFragment = range.extractContents();

            const span = document.createElement('span');
            span.style.backgroundColor = this.selectedColor;

            const walker = document.createTreeWalker(docFragment, NodeFilter.SHOW_TEXT, null, false);
            const nodesToHighlight = [];
            let node;
            while ((node = walker.nextNode())) {
                nodesToHighlight.push(node);
            }
            nodesToHighlight.forEach((textNode) => {
                const span = document.createElement('span');
                span.style.backgroundColor = this.selectedColor;
                textNode.parentNode.replaceChild(span, textNode);
                span.appendChild(textNode);
            });

            range.insertNode(docFragment);
            selection.removeAllRanges();
        },
    },
};
</script>
<style lang="scss">
.cognalearn-question-highlight {
    background: var(--highlight-color);
}

.highlight-area {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
}
</style>
