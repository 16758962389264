<template>
    <div>
        <div
            id="modality"
            ref="modal"
            class="modal primary-modal createQuestionsModalV2"
            :class="{ modifyCreateQuestionsModal: selectedType }"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modality-title"
        >
            <div class="modal-dialog modal-lg">
                <VForm ref="form" v-slot="{ values, setFieldValue, meta, errors }" :validation-schema="schema" :initial-values="initialValues">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                                <i class="fa-solid fa-xmark" aria-hidden="true" />
                            </button>

                            <h2 id="modality-title" class="modal-title">
                                <template v-if="formType == 'create'"> New Question </template>

                                <template v-if="formType == 'edit'"> Edit Question </template>
                            </h2>
                        </div>
                        <div class="modal-body">
                            <question-form
                                :display-can-allow-student-rich-text-responses-field="showCanAllowStudentRichTextResponsesField"
                                :values="values"
                                :set-field-value="setFieldValue"
                                :target="target"
                                :point-distribution-teams="pointDistributionTeams"
                            ></question-form>

                            <div v-if="!(meta.valid && meta.dirty) || (!doSomeTeamsMeetCriteria && selectedType == 'pointDistribution')" class="marginTop20">
                                <div class="alert alert-warning marginBottom20">
                                    <i class="fas fa-exclamation-triangle marginRight8" aria-hidden="true"></i>
                                    <div>
                                        In order to save this question, you must:
                                        <ul class="marginBottom0 paddingLeft15">
                                            <li v-if="!values.question.name">Criteria field required</li>
                                            <li v-if="stemRequirementNotMet(values) && selectedType != 'pointDistribution'">All question stems are required</li>
                                            <li v-if="!doSomeTeamsMeetCriteria && selectedType == 'pointDistribution'">
                                                At least one team needs to consist of <b>3 or more</b> members.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="flexSpaceBetween align-items">
                                <Field
                                    v-if="auth.user().account.version == 'full' && displayAddToQuestionBankField ? true : false"
                                    v-slot="{ field }"
                                    type="checkbox"
                                    name="addToQuestionBank"
                                    :value="true"
                                    :unchecked-value="false"
                                >
                                    <div class="marginTop20 paddingBottom20">
                                        <div class="flex align-items toggle-display">
                                            <div class="flexOnly justifyCenter marginRight10">
                                                <label class="form-switch">
                                                    <input v-bind="field" type="checkbox" :disabled="referenceQuestionUuid ? true : undefined" />
                                                    <span class="slider round" />
                                                </label>
                                            </div>
                                            <div>
                                                <label class="control-label fontBlack margin0 fw-normal"> Add to Question Bank </label>
                                            </div>
                                        </div>
                                    </div>
                                </Field>
                                <!--<div v-if="optionUrl && auth.user().account.version == 'full' ? true : false" class="checkboxField">
                                        <input
                                            id="addToQuestion"
                                            v-model="form.model.addToQuestionBank"
                                            type="checkbox"
                                            :disabled="(form.model.referenceQuestionUuid ? true : false) ? true : undefined"
                                        />
                                        <label for="addToQuestion" class="fw-normal"> Add To Question Bank </label>
                                    </div>-->
                                <div>
                                    <button class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    <button
                                        class="btn btn-success"
                                        :disabled="
                                            !(meta.valid && meta.dirty) || (!doSomeTeamsMeetCriteria && values.type == 'pointDistribution') ? true : undefined
                                        "
                                        @click.prevent="submit(values)"
                                    >
                                        <template v-if="formType == 'create'">Save</template>
                                        <template v-if="formType == 'edit'">Update</template>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </VForm>
            </div>
        </div>
    </div>
</template>

<script>
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import KrAuth from '../../../components/auth/auth';
export default {
    components: {
        'question-form': require(`./form-partials/partial.question-new.vue`).default,
        'question-display': require(`./partials/question-display.vue`).default,
        VForm: Form,
        Field: Field,
    },
    props: ['options'],
    data() {
        var auth = new KrAuth();
        const schema = yup.object({
            type: yup.string().required(),
            question: yup.object().shape({
                name: yup.string().required().label('Criteria'),
                description: yup.string().nullable(),
            }),
            settings: yup.object().shape({
                answerQuestionsForEachTeammate: yup.boolean(),
                pointDistributionStyle: yup.string().nullable(),
                pointsAllocatedPerMember: yup.number(),
                shouldDistributeAllPoints: yup.boolean(),
                shouldDistributePointsWithoutRepeatingValues: yup.boolean(),
                shouldGiveOneTeammateAboveTenPoints: yup.boolean(),
                shouldGiveOneTeammateBelowTenPoints: yup.boolean(),
                allowToGiveThemselvesPoints: yup.boolean(),
            }),
            questionStems: yup
                .array(
                    yup.object({
                        stem: yup.string().when('type', (type, schema) => {
                            if (type != 'pointDistribution') {
                                return schema.required();
                            } else {
                                return schema.nullable();
                            }
                        }),
                        isRequired: yup.boolean(),
                    })
                )
                .when('type', (type, schema) => {
                    if (type != 'pointDistribution') {
                        return schema.min(1);
                    } else {
                        return schema.nullable();
                    }
                }),
            remarks: yup.string().nullable(),
            minimum: yup.number().required().min(1, 'Mininum must be a positive number'),
            maximum: yup
                .number()
                .required()
                .when('minimum', (minimum, schema) => {
                    if (minimum !== null) {
                        return schema.min(minimum, 'Maximum must be greater or equal to minimum');
                    }
                }),
            ratingSettings: yup
                .array(
                    yup.object().shape({
                        weight: yup.number(),
                        label: yup.string().nullable(),
                        rubric: yup.string().nullable(),
                    })
                )
                .when('type', (type, schema) => {
                    if (type == 'ratingV2') {
                        return schema.min(1);
                    } else {
                        return schema.nullable();
                    }
                }),
            canAllowStudentRichTextResponses: yup.boolean(),
            isRequired: yup.boolean(), //for rating
            addToQuestionBank: yup.boolean(),
        });
        const initialValues = {
            type: '',
            question: {
                name: '',
                description: '',
                isHTML: true,
            },
            settings: {
                answerQuestionsForEachTeammate: true,
                pointDistributionStyle: 'michaelsen',
                pointsAllocatedPerMember: 10,
                shouldDistributeAllPoints: true,
                shouldDistributePointsWithoutRepeatingValues: true,
                shouldGiveOneTeammateAboveTenPoints: true,
                shouldGiveOneTeammateBelowTenPoints: true,
                allowToGiveThemselvesPoints: false,
            },
            questionStems: [
                {
                    stem: '',
                    isRequired: true,
                },
            ],
            ratingSettings: [
                { weight: 1, label: '', rubric: '' },
                { weight: 2, label: '', rubric: '' },
                { weight: 3, label: '', rubric: '' },
                { weight: 4, label: '', rubric: '' },
                { weight: 5, label: '', rubric: '' },
            ],
            remarks: '',
            minimum: 1,
            maximum: 100,
            questionIsHTML: true,
            canAllowStudentRichTextResponses: true,
            isRequired: true,
            addToQuestionBank: true,
        };
        return {
            form: this.$refs.form,
            auth: auth,
            component_done_loading: false,
            displayType: _.get(this, 'options.displayType', 'modal'),
            formType: 'create',
            createType: '',
            question: {},
            viewForm: true,
            schema: schema,
            initialValues: initialValues,
            optionUrl: false,
            showCanAllowStudentRichTextResponsesField: true,
            id: _.get(this.options, 'id', 'question-cru'),
            selectedType: '',
            target: '', //
            courseUuid: _.get(this.options, 'courseUuid', ''),
            pointDistributionTeams: [],
            referenceQuestionUuid: '',
            displayAddToQuestionBankField: true,
            method: 'put', //for edit
        };
    },
    computed: {
        doSomeTeamsMeetCriteria() {
            if (this.pointDistributionTeams.length > 0) {
                var outcome = this.pointDistributionTeams[0].some((key) => key.members.length >= 3);
                return outcome;
            } else {
                return false;
            }
        },
    },
    mounted() {
        //this.form.model.addToQuestionBank = this.auth.user().account.version == 'full' ? true : false;
        this.initForm();
        // Prevent bootstrap dialog from blocking focusin
        $(document).on('focusin', function (e) {
            if ($(e.target).closest('.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root').length) {
                e.stopImmediatePropagation();
            }
        });
    },
    methods: {
        openModal() {
            var that = this;
            this.$nextTick(() => {
                $(that.$refs['modal']).modal('show');
            });
        },
        closeModal() {
            var that = this;
            this.$nextTick(() => {
                $(that.$refs['modal']).modal('hide');
            });
        },
        create(options = {}) {
            this.displayAddToQuestionBankField = options.displayAddToQuestionBankField != null ? options.displayAddToQuestionBankField : true;
            this.createType = 'form';
            this.formType = 'create';
            this.optionUrl = options.url ? options.url : false;
            this.showCanAllowStudentRichTextResponsesField = options.showCanAllowStudentRichTextResponsesField === false ? false : true;
            this.selectedType = options.selectedType ? options.selectedType : '';
            this.target = options.target ? options.target : '';
            this.initForm();
            this.openModal();
        },
        edit(question, options = {}) {
            this.displayAddToQuestionBankField = options.displayAddToQuestionBankField != null ? options.displayAddToQuestionBankField : true;
            this.formType = 'edit';
            this.optionUrl = options.url ? options.url : false;
            this.showCanAllowStudentRichTextResponsesField = options.showCanAllowStudentRichTextResponsesField === false ? false : true;
            this.target = options.target ? options.target : '';
            this.selectedType = question.type;
            this.initForm();
            this.hydrate(question);
            this.selectedType = options.type;
            this.method = options.method ? options.method : 'put';
            this.openModal();
        },
        initForm() {
            let initialValues = _.cloneDeep(this.initialValues);
            this.referenceQuestionUuid = null;
            if (this.selectedType == 'pointDistribution') {
                initialValues.question.name = 'Quantitative Feedback';
                initialValues.question.description =
                    "Rate your teammates based on how much they contributed to your learning and your team's success. Be honest—this is your chance to recognize those who worked hard and fairly reflect everyone's effort.\n\n" +
                    "If you give everyone the same score, you might be unfairly rewarding those who didn't contribute as much and not properly recognizing those who did the most.\n\n" +
                    "Give higher scores to teammates who put in a lot of effort and lower scores to those who didn't contribute as much. These ratings will help decide how much of the team's overall grade each person earns.";
                initialValues.questionStems = [];
                initialValues.ratingSettings = [];
                this.checkSections([
                    { section: 1, name: 'Team 1', members: { length: 5 } },
                    { section: 1, name: 'Team 2', members: { length: 5 } },
                    { section: 1, name: 'Team 3', members: { length: 5 } },
                    { section: 1, name: 'Team 4', members: { length: 5 } },
                    { section: 1, name: 'Team 5', members: { length: 5 } },
                ]);
            }
            this.$refs.form.resetForm({ values: initialValues, force: true });
            this.$refs.form.setFieldValue('type', this.selectedType);
            if (this.target == 'all') {
                this.$refs.form.setFieldValue('settings.allowToGiveThemselvesPoints', true);
            } else {
                this.$refs.form.setFieldValue('settings.allowToGiveThemselvesPoints', false);
            }
        },
        hydrate(question) {
            let q = _.cloneDeep(question);
            this.question = q;
            q.question = {
                name: q.name,
                description: q.description,
                isHTML: true,
            };
            this.initForm();
            let questionStems = [];
            if (q.type != 'pointDistribution') {
                if (!Array.isArray(q.questionStems)) {
                    let keys = Object.keys(q.questionStems);
                    keys.forEach((key) => {
                        questionStems.push(q.questionStems[key]);
                    });
                    q.questionStems = questionStems;
                }
            }
            this.$refs.form.setValues(q);
            this.referenceQuestionUuid = q.referenceQuestionUuid;
        },
        submit(values = {}) {
            var data = _.cloneDeep(values);
            var that = this;
            let url = '';
            let questionStems = {};
            if (this.target == 'peer') {
                data.settings.allowToGiveThemselvesPoints = false;
            }
            if (data.type != 'pointDistribution') {
                for (var i = 0; i < data.questionStems.length; i++) {
                    questionStems[i + 1] = data.questionStems[i];
                }
            } else {
                if (data.settings.pointDistributionStyle == 'fink') {
                    data.settings.shouldGiveOneTeammateAboveTenPoints = false;
                    data.settings.shouldGiveOneTeammateBelowTenPoints = false;
                }
            }
            if (this.target == 'peers' || this.target == 'self') {
                data.settings.allowToGiveThemselvesPoints = false;
            }
            // console.log(data.settings)
            if (this.formType == 'create') {
                url = this.optionUrl ? this.optionUrl : '/questions/peer-evaluation';
                axios.post(url, { ...data, questionStems: questionStems }).then(function (response) {
                    if (!response.krFormError) {
                        that.formType = 'create';
                        that.createType = 'create';
                        that.initForm();
                        that.closeModal();
                        that.$emit('created', response.data.data);
                    }
                });
            } else {
                url = this.optionUrl ? this.optionUrl : '/questions/peer-evaluation/' + this.question.uuid;
                axios({ url: url, method: this.method, data: { ...data, questionStems: questionStems } }).then(function (response) {
                    if (!response.krFormError) {
                        that.formType = 'edit';
                        let q = _.cloneDeep(values);
                        q.uuid = that.question.uuid;
                        q.name = values.question.name;
                        q.description = values.question.description;
                        that.hydrate(response.data.data ? response.data.data : q);
                        that.closeModal();
                        that.$emit('updated', response.data.data ? response.data.data : q);
                    }
                });
            }
        },
        fetchTeams() {
            var that = this;
            axios.get('/courses/' + this.courseUuid + '/teams').then(function (response) {
                that.checkSections(response.data.data);
            });
        },
        checkSections(array) {
            this.pointDistributionTeams = [];
            array.forEach((team) => {
                if (!this.pointDistributionTeams[team.section - 1]) {
                    this.pointDistributionTeams[team.section - 1] = [];
                }
                this.pointDistributionTeams[team.section - 1].push(team);
            });
        },
        stemRequirementNotMet(values) {
            if (this.selectedType != 'pointDistribution') {
                if (values.questionStems) {
                    if (values.questionStems.map((stem) => stem.stem != '').every((value) => value)) {
                        return false;
                    }
                }
            }

            return true;
        },
    },
};
</script>
