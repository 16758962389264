<template>
    <div
        ref="modal"
        class="modal danger-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="deleteRoleModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="deleteRoleModal-title" class="modal-title">Delete Role</h2>
                </div>
                <div class="modal-body textAlignCenter" :class="{ padding20px82px: !isMobileView }">
                    <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                    <h2 class="marginTop40">Delete Role</h2>

                    <h3 class="marginBottom20 fw-normal capitalize">
                        {{ role.name }}
                    </h3>

                    <dl v-if="collaborators.length > 0" class="textAlignLeft">
                        <dt><b>Note:</b> The following staff have {{ role.name }} to courses.</dt>
                        <dd v-for="collaborator in collaborators" class="marginTop10">
                            <span class="inlineFlex"> {{ collaborator.displayName }} - {{ collaborator.courseName }} </span>
                        </dd>
                    </dl>

                    <template v-if="collaborators.length == 0">
                        <p class="marginTop20">You are about to remove {{ role.name }}.</p>
                        <p>This action is permanent and cannot be undone.</p>
                    </template>
                    <template v-else>
                        <p class="marginTop20">To proceed, please choose a new role for these users.</p>
                    </template>

                    <div v-if="collaborators.length > 0" class="marginTop20 flex justifyCenter">
                        <div class="col-xs-12 col-sm-8 textAlignLeft">
                            <label for="select-course-collaborator-role" class="control-label">
                                Select Course Collaborator Role<template>
                                    <span class="visible-hidden">Required</span>
                                    <span class="important" aria-hidden="true">*</span>
                                </template>
                            </label>
                            <div class="form-group-select-wrapper form-dropdown">
                                <select id="select-course-collaborator-role" v-model="newRole" class="form-control">
                                    <option value="" disabled selected>Course Collaborator Role</option>
                                    <option v-for="roleOption in roles" :key="roleOption" :value="roleOption">
                                        {{ roleOption.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                    <button class="btn btn-danger" data-dismiss="modal" @click.prevent="submit">
                        <i class="fa-solid fa-trash-can marginRight8" aria-hidden="true" />
                        Confirm, Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';

const emit = defineEmits(['role-deleted']);
const modal = ref(null);
const role = ref({});
const collaborators = ref([]);
const roles = ref([]);
const newRole = ref('');

const deleteRole = (r = {}) => {
    role.value = r;
    nextTick(() => {
        $(modal.value).modal('show');
        fetchCollaborator();
        fetchRoles();
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const fetchCollaborator = () => {
    axios.post(`course/roles/${role.value.id}/collaborators`).then((response) => {
        collaborators.value = response.data.data;
    });
};

const fetchRoles = () => {
    axios.get('course/roles').then((response) => {
        roles.value = response.data.data.filter((role) => role.name !== 'Course Owner');
    });
};

const submit = () => {
    axios
        .delete(`course/roles/roles/${role.value.id}`, {
            params: {
                roleId: collaborators.value.length > 0 ? newRole.value.id : null,
            },
        })
        .then(function (response) {
            closeModal();
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'You have deleted the role',
            });

            emit('role-deleted');
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong, please try again later',
            });
        });
};

defineExpose({ deleteRole });
</script>
