<template>
    <!-- <div v-if="!form.isHidden(name)" class="form-group" :class="{'has-error': form.errors.has(name)}">
    <label class="control-label" v-dompurify-html="display"></label>
    <div class="">
    	<div :style="'max-width:100%;position: relative;height:'+ height+ 'px;width:'+width+ 'px;border: 2px solid #eee;border-radius: 4px;text-align: center;'">
    		<img :src="image" :style= "'max-width:100%;height:'+height+'px;width:'+width+'px;'">
    	</div>
        <a class="btn btn-primary" @click.prevent="openFileStack" :style="'margin-top:10px;width:'+width+'px;'"><i v-if="is_uploading" class="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i> <template v-else>Upload</template></a>
        <a v-if="image" class="btn btn-danger" @click.prevent="removeImage" :style="'margin-top:0px;width:'+width+'px;'">Remove</a>
        <span class="help-block" v-show="form.errors.has(name)">
            <strong>{{ form.errors.get(name) }}</strong>
        </span>
    </div>
 </div> -->
    <div v-if="!form.isHidden(name)" class="form-group" :class="{ 'has-error': form.errors.has(name) }">
        <label v-if="display" class="control-label" :for="name" v-dompurify-html="display" />
        <div>
            <div class="displayInlineBlock">
                <div :id="name + '_preview_div'" class="preview-image" role="button">
                    <div v-if="image" class="actual-image" :style="'background-image:url(' + image + ');'">
                        <button
                            class="remove-btn"
                            :aria-label="name == 'coverImage' ? 'Remove course thumbnail' : 'Remove profile image'"
                            @click.prevent="removeImage"
                        >
                            <i class="fa fa-trash" aria-hidden="true" />
                        </button>
                    </div>
                    <template v-if="!image">
                        <button
                            class="upload-profile-picture-button"
                            :aria-label="name == 'coverImage' ? 'Upload course thumbnail' : 'Upload profile image'"
                            @click.prevent="openFileStack"
                        >
                            <i class="fa fa-picture-o fa-2x" aria-hidden="true" />
                            <br />
                            <span aria-hidden="true">Upload New</span>
                        </button>
                    </template>
                </div>
            </div>
            <span v-show="form.errors.has(name)" class="help-block">
                <strong>{{ form.errors.get(name) }}</strong>
            </span>
        </div>
    </div>
</template>
<script>
import * as filestack from 'filestack-js';
export default {
    props: ['display', 'form', 'name', 'options'],
    data() {
        //image:form.model[this.name]
        return {
            image: '',
            width: '200',
            height: '200',
            is_uploading: false,
            client: null,
        };
    },
    created() {
        this.image = _.get(this.form.model, this.name);
        if (_.get(this, 'options.width', false)) {
            this.width = this.options.width;
        }
        if (_.get(this, 'options.height', false)) {
            this.height = this.options.height;
        }
    },
    mounted() {
        var that = this;
        Events.listen(this.name + '_close', () => {
            //console.log('event get list '+this.name);
            $('.fsp-picker__close-button:eq(0)').click();
        });
    },
    methods: {
        openFileStack() {
            this.client = filestack.init(process.env.VUE_APP_FILESTACK_API_KEY);
            var that = this;
            this.client
                .picker({
                    accept: 'image/*',
                    maxFiles: 1,
                    minFiles: 1,
                    onUploadStarted: function (file) {
                        that.is_uploading = true;
                    },
                    onFileUploadFinished: function (file) {
                        that.is_uploading = false;
                        that.image = file.url;
                        _.set(that.form.model, that.name, file.url);
                        that.$emit('changed');
                    },
                    onOpen() {},
                })
                .open();
        },
        removeImage() {
            this.image = null;
            _.set(this.form.model, this.name, null);
        },
    },
};
</script>
<style scoped lang="scss">
.remove-btn,
.remove-btn:active,
.remove-btn:visited,
.remove-btn:focus,
.remove-btn:hover {
    z-index: 3;
    bottom: 0;
    right: 0;
    background: #ca3333;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 10px;
    line-height: 1;
    color: #fff;
    position: absolute;
    display: -ms-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
}

.actual-image {
    background-color: #273142;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    background-position: center;
    background-size: cover;
}

.preview-image {
    .actual-image {
        margin: 0;
        border-radius: 0;

        .remove-btn {
            right: -5px;
            bottom: -5px;
        }
    }
}

.upload-profile-picture-button {
    display: block;
    background-color: #273142;
    width: 100px;
    height: 100px;
    border-radius: 0;
    color: #fff;
    font-weight: normal;
    padding: 6px 6px;
    font-size: 16px;
}
</style>
