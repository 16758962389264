<template>
    <div class="eGalleryQuestionGroup">
        <template v-for="(question, idx) in testObj.questions[current_question_group_idx]">
            <question
                :test-obj="testObj"
                :question="question"
                :question_group_idx="current_question_group_idx"
                :question_idx="idx"
                :show-realtime-toggle="showRealtimeToggle"
                :activities-status="activitiesStatus"
            ></question>
        </template>
    </div>
</template>
<script>
export default {
    props: ['testObj', 'options', 'activitiesStatus'],
    components: {
        question: require(`./question/question.vue`).default,
    },
    data() {
        return {
            current_question_group_idx: 0,
            current_question_idx: 0,
            showRealtimeToggle: _.get(this.options, 'showRealtimeToggle', true),
            fromDashboard: false,
        };
    },
    created() {
        this.init();
    },
    mounted() {
        var that = this;
        Events.listen('change-question', function (to) {
            that.changeQuestion(to, !that.fromDashboard);
        });
        this.processEchoListeners();
        Vue.nextTick(function () {
            Events.fire('current-question-changed', {
                question_group_idx: that.current_question_group_idx,
                question_idx: that.current_question_idx,
            });
        });
    },
    beforeUnmount() {
        Events.off('change-question');
    },
    methods: {
        processEchoListeners() {
            let h1 = (e) => {
                if (!this.testObj.eGalleryProgression) {
                    this.testObj.eGalleryProgression = {};
                }
                this.testObj.eGalleryProgression.currentQuestionUuid = e.eGalleryCurrentQuestionUuid;
                for (var i = 0; i < this.testObj.questions.length; i++) {
                    for (var j = 0; j < this.testObj.questions[i].length; j++) {
                        //get current saved question position
                        if (this.testObj.questions[i][j].uuid == this.testObj.eGalleryProgression.currentQuestionUuid) {
                            this.changeQuestion({ question_group_idx: i, question_idx: j }, false);
                            return;
                        }
                    }
                }
            };
            let c1 = window.Echo.private(`test.${this.testObj.uuid}.teacher`).listen('DiscussionCurrentQuestionUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'DiscussionCurrentQuestionUpdated',
                handler: h1,
            });

            /*let h2 = (e) => {
                for(var i = 0; i <this.testObj.questions.length;i++){
                    for(var j = 0; j<this.testObj.questions[i].length;j++){
                        //get current saved question position
                        this.testObj.questions[i][j].displayAnswer = e.displayAnswer===0?false:true;
                        this.testObj.questions[i][j].displayAnswerStatistics = e.displayAnswerStatistics===0?false:true;
                        //this.testObj.questions[i][j].displayAnswerStatistics = message.data.displayAnswerStatistics===0?false:true;
                    }
                }
            }
            let c2= window.Echo.private(`test.${this.testObj.uuid}.teacher`).listen('DiscussionQuestionsSyncToggled', h2 );
            this.echoChannels.push({channel:c2,event:'DiscussionQuestionsSyncToggled',handler:h2})*/
        },
        init() {
            var that = this;
            if (this.$route.name == 'activities.tests.dashboard') {
                this.fromDashboard = true;
            }
            let currentQuestionUuid = _.get(this.testObj, 'eGalleryProgression.currentQuestionUuid', false);
            if (currentQuestionUuid == null) {
                Vue.nextTick(function () {
                    that.changeQuestion({
                        question_group_idx: 0,
                        question_idx: 0,
                    });
                });
            }
            for (var i = 0; i < this.testObj.questions.length; i++) {
                for (var j = 0; j < this.testObj.questions[i].length; j++) {
                    //get current saved question position
                    if (currentQuestionUuid == this.testObj.questions[i][j].uuid) {
                        var q_group_idx = i;
                        var q_idx = j;
                        Vue.nextTick(function () {
                            that.changeQuestion(
                                {
                                    question_group_idx: q_group_idx,
                                    question_idx: q_idx,
                                },
                                false
                            );
                        });
                    }
                }
            }
        },
        changeQuestion(to, sendToAPI = true) {
            this.current_question_group_idx = to.question_group_idx;
            this.current_question_idx = to.question_idx;
            Events.fire('current-question-changed', {
                question_group_idx: to.question_group_idx,
                question_idx: to.question_idx,
            });
            if (sendToAPI) {
                axios
                    .put('tests/' + this.testObj.uuid + '/discussions/current-question', {
                        currentQuestionUuid: this.testObj.questions[this.current_question_group_idx][this.current_question_idx].uuid,
                    })
                    .then(function (response) {});
            }
        },
    },
};
</script>
