<template>
    <footer role="contentinfo" class="no-print">
        <p class="fs-12px">
            Copyright &copy CognaLearn Pte Ltd 2015 ~ {{ year }} (build: {{ BUILD_DATE }})
            <template v-if="!isBelow436px"> &nbsp;&nbsp;|&nbsp;&nbsp; </template>
            <br v-if="isBelow436px" />
            <a href="https://www.intedashboard.com/terms-of-use/" target="_blank" aria-label="Terms of Use opens in a new tab."> Terms of Use </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://www.intedashboard.com/privacy-policy/" target="_blank" aria-label="Privacy Policy opens in a new tab."> Privacy Policy </a>
        </p>
    </footer>
</template>
<script>
import KrAuth from '../../../../components/auth/auth.js';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            BUILD_DATE: process.env.VUE_APP_BUILD_DATE,
            year: moment().format('YYYY'),
        };
    },
    watch: {
        $route: {
            handler: function () {
                if (_.get(this.$route.params, 'otp', false)) {
                    $('footer').hide();
                } else {
                    $('footer').show();
                }
            },
            deep: true,
        },
    },
    mounted() {
        if (_.get(this.$route.params, 'otp', false)) {
            $('footer').hide();
        }
    },
};
</script>
