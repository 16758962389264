<template>
    <div
        ref="modal"
        class="modal"
        :class="{ 'danger-modal': testIsPublished, 'warning-modal': !testIsPublished }"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="hideActivityModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="hideActivityModal-title" class="modal-title">
                        <template v-if="testIsPublished">Hide</template>
                        <template v-else>Show</template>
                        Activity
                    </h2>
                </div>
                <div class="modal-body textAlignCenter">
                    <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                    <h2 class="marginTop40">
                        <template v-if="testIsPublished">Hide</template>
                        <template v-else>Show</template>
                    </h2>
                    <h3 class="marginBottom20 fw-normal">
                        {{ values.name }}
                    </h3>

                    <p>Students for this section will</p>
                    <p v-if="testIsPublished">no longer be able to see it.</p>
                    <p v-else>be able to see it.</p>

                    <p class="marginTop20">Do you want to continue hiding it?</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeModal()">No, Cancel</button>
                    <button class="btn" :class="{ 'btn-danger': testIsPublished, 'btn-success': !isPublished }" @click.prevent="submit()">
                        <template v-if="testIsPublished">Yes, Hide</template>
                        <template v-else>Yes, Show</template>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';

const emit = defineEmits(['isTestPublished']);

const modal = ref(null);
const values = ref({});
const testIsPublished = ref(false);

const hideActivity = (v = {}, isPublished = false) => {
    values.value = v;
    testIsPublished.value = isPublished;

    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .post(`tests/${values.value.uuid}/publish`)
        .then(function (response) {
            closeModal();

            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Test publish has been successfully changed',
            });

            emit('isTestPublished');
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Unable to hide activity at this moment, please try again later',
            });
        });
};

defineExpose({ hideActivity });
</script>
