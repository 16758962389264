<template>
    <div
        v-if="component_done_loading"
        :key="test.uuid"
        class="col-xs-12 right-container"
        :class="{
            dashboard: ['peerEvaluationV2'].includes(test.type) && test.isPublished && test.settings.type == 'synchronous',
            timerStart: ['ongoing', 'paused'].includes(test.status),
            noTimeLimit: !test.settings.hasTimeLimit,
        }"
    >
        <div class="row">
            <div class="col-xs-12">
                <activity-topbar :test-obj="test" />
            </div>
        </div>

        <template v-if="['peerEvaluationV2'].includes(test.type)">
            <div class="hidden-xs hidden-sm dashboardCols">
                <div class="col-md-6">
                    <activity-overview :test-obj="test" />
                </div>
                <div class="col-md-6">
                    <activity-attendance :test-obj="test" />
                </div>
            </div>
            <div class="hidden-md hidden-lg">
                <div>
                    <activity-overview :test-obj="test" />
                </div>
                <div class="marginTop20">
                    <activity-attendance :test-obj="test" />
                </div>
            </div>

            <div class="marginTop20">
                <activity-evaluation-analysis-new :test-obj="test" />
            </div>
        </template>
    </div>
</template>
<script>
export default {
    data() {
        return {
            test: {},
            component_done_loading: false,
            debouncedNewUpdate: null,
        };
    },
    created() {
        var that = this;
        this.debouncedNewUpdate = _.debounce(
            function () {
                that.fetchTest().then(function (response) {
                    let a = {};
                    _.merge(a, that.test, response.data.data);
                    that.test = a;
                    that.test.questions = response.data.data.questions;
                    that.test.activityStatus = response.data.data.activityStatus;
                    that.test.attendance = response.data.data.attendance;
                });
            },
            1000,
            { maxWait: 3000 }
        );
        Events.fire('topbar_update', {
            title: '<i class="fas fa-pencil-ruler"></i>&nbsp;Activity',
        });
        that.fetchTest().then(
            axios.spread(function (overview, analysis) {
                let a = {};
                _.merge(a, that.test, overview.data.data, analysis.data.data);
                that.test = a;
                that.test.activityStatus = overview.data.data.activityStatus;
                that.test.attendance = overview.data.data.attendance;
                Events.fire('topbar_update', {
                    breadcrumb: [
                        { name: 'Home' },
                        { name: 'Courses', link: 'courses.index' },
                        {
                            name: that.test.course.name + (that.test.course.code ? ' (' + that.test.course.code + ')' : ''),
                            link: 'courses.view',
                            params: { id: that.test.course.uuid },
                        },

                        {
                            name: `${that.test.moduleName}`,
                            link: 'courses.view',
                            params: { id: that.test.course.uuid },
                            query: { activityUuid: that.test.activityUuid },
                        },
                        {
                            name: `${that.test.name} (${that.test.sectionName})`,
                        },
                    ],
                });
                that.component_done_loading = true;
                that.processEchoListeners();
            })
        );
    },
    methods: {
        processEchoListeners() {
            var that = this;
            let h1 = (e) => {
                if (e.test.uuid != that.test.uuid) {
                    return false;
                }
                that.debouncedNewUpdate();
            };
            let c1 = window.Echo.private(`course.${this.test.course.uuid}.teacher`).listen('TestStatusUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestStatusUpdated',
                handler: h1,
            });

            let h2 = (e) => {
                if (e.testUuid != that.test.uuid) {
                    return false;
                }
                that.debouncedNewUpdate();
            };
            let c2 = window.Echo.private(`course.${this.test.course.uuid}.teacher`).listen('StudentTestStatusUpdated', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'StudentTestStatusUpdated',
                handler: h2,
            });

            let h3 = (e) => {
                if (e.testUuids.includes(that.test.uuid)) {
                    that.$router.push({
                        name: 'courses.view',
                        params: { id: that.test.course.uuid },
                    });
                }
            };
            let c3 = window.Echo.private(`course.${this.test.course.uuid}.teacher`).listen('ActivityUnpublished', h3);
            this.echoChannels.push({
                channel: c3,
                event: 'ActivityUnpublished',
                handler: h3,
            });
            /*window.Echo.private(`test.${this.test.uuid}.teacher`).listen('TeamAnswerSaved', (e) => {
        if(e.testUuid!=that.test.uuid){
          return false;
        }
        that.debouncedUpdate();
      });
      window.Echo.private(`test.${that.test.uuid}.teacher`).listen('StudentAnswerSaved', (e) => {
        if(e.testUuid!=that.test.uuid){
          return false;
        }
        that.debouncedNewUpdate();
      });*/
            let h4 = (e) => {
                for (var i = 0; i < this.test.questions.length; i++) {
                    for (var j = 0; j < this.test.questions[i].length; j++) {
                        if (this.test.questions[i][j].uuid == e.activityQuestion.uuid) {
                            this.test.questions[i][j].displayAnswer = e.activityQuestion.displayAnswer ? true : false;
                            this.test.questions[i][j].displayAnswerStatistics = e.activityQuestion.displayAnswerStatistics ? true : false;
                            this.test.questions[i][j].enableStackingColumns = e.activityQuestion.enableStackingColumns ? true : false;
                        }
                    }
                }
            };
            let c4 = window.Echo.private(`test.${this.test.uuid}.teacher`).listen('DiscussionQuestionStatusUpdated', h4);
            this.echoChannels.push({
                channel: c4,
                event: 'DiscussionQuestionStatusUpdated',
                handler: h4,
            });

            let h5 = (e) => {
                that.debouncedNewUpdate();
            };
            let c5 = window.Echo.private(`course.${this.test.course.uuid}.teacher`).listen('CourseUserPlacementsUpdated', h5);
            this.echoChannels.push({
                channel: c5,
                event: 'CourseUserPlacementsUpdated',
                handler: h5,
            });

            let h6 = (e) => {
                if (e.testUuids.includes(that.test.uuid)) {
                    that.debouncedNewUpdate();
                }
            };
            let c6 = window.Echo.private(`course.${this.test.course.uuid}.teacher`).listen('ActivityPublished', h6);
            this.echoChannels.push({
                channel: c6,
                event: 'ActivityPublished',
                handler: h6,
            });

            let h7 = (e) => {
                if (e.testUuids.includes(that.test.uuid)) {
                    that.fetchTest().then(function (response) {
                        that.test = response.data.data;
                    });
                }
            };
            let c7 = window.Echo.private(`course.${this.test.course.uuid}.teacher`).listen('TestMaterialsUpdated', h7);
            this.echoChannels.push({
                channel: c7,
                event: 'TestMaterialsUpdated',
                handler: h7,
            });
            let h8 = (e) => {
                if (e.testUuids.includes(that.test.uuid)) {
                    that.$router.push({
                        name: 'courses.view',
                        params: { id: that.test.course.uuid },
                    });
                }
            };
            let c8 = window.Echo.private(`course.${this.test.course.uuid}.teacher`).listen('ActivityDeleted', h8);
            this.echoChannels.push({
                channel: c8,
                event: 'ActivityDeleted',
                handler: h8,
            });

            let h9 = (e) => {
                if (e.test.uuid == that.test.uuid) {
                    that.test.status = e.test.status;
                    that.test.settings.startTime = e.test.settings.startTime;
                    that.test.settings.endTime = e.test.settings.endTime;
                    that.test.startTime = e.test.settings.startTime;
                    that.test.endTime = e.test.settings.endTime;
                }
            };
            let c9 = window.Echo.private(`course.${this.test.course.uuid}.teacher`).listen('TestPeriodUpdated', h9);
            this.echoChannels.push({
                channel: c9,
                event: 'TestPeriodUpdated',
                handler: h9,
            });
            let h10 = (e) => {
                if (e.testUuid != that.test.uuid) {
                    return false;
                }
                location.reload();
            };
            let c10 = window.Echo.private(`test.${this.test.uuid}.teacher`).listen('ResetAnswers', h10);
            this.echoChannels.push({
                channel: c10,
                event: 'ResetAnswers',
                handler: h10,
            });
            let h11 = (e) => {
                this.test.expectedEndingTime = e.expectedEndingTime;
                this.test.startedEarly = true;
            };
            let c11 = window.Echo.private(`test.${this.test.uuid}.teacher`).listen('EarlyTestStarted', h11);
            this.echoChannels.push({
                channel: c11,
                event: 'EarlyTestStarted',
                handler: h11,
            });
            let h12 = (e) => {
                this.test.expectedEndingTime = null;
            };
            let c12 = window.Echo.private(`test.${this.test.uuid}.teacher`).listen('EarlyTestEnded', h12);
            this.echoChannels.push({
                channel: c12,
                event: 'EarlyTestEnded',
                handler: h12,
            });
        },
        fetchTest() {
            return axios.all(
                [
                    axios.get('/tests/' + this.$route.params.id + '/dashboard/overview'),
                    axios.get('/tests/' + this.$route.params.id + '/dashboard/peer-evaluation'),
                ],
                { _internal: { hasLoadingBar: false } }
            );
        },
    },
    components: {
        'activity-topbar': require(`./partials/topbar.vue`).default,
        'activity-overview': require(`./partials/overview.vue`).default,
        'activity-evaluation-analysis-new': require(`./partials/evaluation/analysis-new.vue`).default,
        'activity-attendance': require(`./partials/attendance.vue`).default,
    },
    watch: {
        $route(to, from) {
            if (!$.isNumeric(to.hash.split('#')[1])) {
                window.location.reload();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.dashboardCols .x_panel .container {
    height: 312px;
    display: flex;
}

.dashboardCols .x_panel {
    margin-bottom: 0;
}

.dashboardCols .x_header {
    flex-shrink: 0;
}

.width396px {
    width: 396px;
}

.width284px {
    width: 284px;
}

.autoWidthStatus {
    width: calc(100% - 40px - 396px - 284px);
}

@media (min-width: 1200px) {
    .lg-marginTop20 {
        margin-top: 20px;
    }
}

@media (min-width: 992px) {
    .dashboardCols {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .dashboardCols .column {
        flex: 1;
        margin-right: 20px;
    }

    .dashboardCols .column:last-child {
        margin-right: 0;
    }

    .dashboardCols > div {
        margin-left: 20px;
    }

    .dashboardCols {
        & > div {
            &:first-child,
            &:only-child {
                margin-left: 0;
            }
        }
    }

    .dashboardCols {
        .col-md-6 {
            &:first-child {
                padding-right: 10px;
            }

            &:last-child {
                margin-left: 0;
                padding-left: 10px;
            }
        }
    }
}
</style>
