<template>
    <div class="create row">
        <div class="col-xs-12">
            <div>
                <kr-field
                    display="Name"
                    :form="form"
                    name="name"
                    :options="{
                        placeholder: 'e.g., My new course',
                        autofocus: 'autofocus',
                        required: true,
                    }"
                />
            </div>
            <div>
                <kr-field display="Course Code" :form="form" name="code" :options="{ placeholder: 'e.g., CR001' }" />
            </div>

            <div>
                <label class="control-label">Period<span class="important" aria-hidden="true">*</span><span class="visible-hidden">Required</span></label>
            </div>

            <div class="col-xs-12 flex marginBottom20">
                <div class="col-xs-12 col-md-6">
                    <kr-date
                        :form="form"
                        name="startDate"
                        :display="'Course Start Date'"
                        :options="{
                            time: false,
                            placeholder: 'Select course start date',
                        }"
                        @change="handleStartDateChange"
                    />
                </div>
                <div class="col-xs-12 col-md-6">
                    <kr-date
                        :form="form"
                        name="endDate"
                        :display="'Course End Date'"
                        :options="{
                            minDate: form.model.startDate,
                            time: false,
                            placeholder: 'Select course end date',
                        }"
                        :errorMessage="showWarning ? 'Start date is after end date' : ''"
                        @change="handleEndDateChange"
                    />
                </div>
            </div>

            <kr-field
                display="Course Overview"
                :form="form"
                name="description.description"
                :options="{
                    type: 'text-area',
                    rows: 5,
                    placeholder: 'e.g., Knowledge is power',
                }"
            />

            <div>
                <label class="control-label" for="coverImage_preview_div"> Course Thumbnail </label>
                <kr-file-picture :form="form" name="coverImage" :options="{ width: '600', height: '600' }" />
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
const emit = defineEmits(['updatedDate', 'newStartDateValue', 'newEndDateValue']);

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
});

const showWarning = ref(false);

const handleStartDateChange = (event) => {
    if (props.form.model.endDate && new Date(props.form.model.endDate) < new Date(event)) {
        props.form.errors.append('endDate');
        showWarning.value = true;
    } else {
        props.form.errors.clear('endDate');
        showWarning.value = false;
    }

    emit('updatedDate', true);
    emit('newStartDateValue', event);
};

const handleEndDateChange = (event) => {
    if (props.form.model.startDate && new Date(event) < new Date(props.form.model.startDate)) {
        props.form.errors.append('endDate');
        showWarning.value = true;
    } else {
        props.form.errors.clear('endDate');
        showWarning.value = false;
    }

    emit('updatedDate', true);
    emit('newEndDateValue', event);
};
</script>
