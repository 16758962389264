<template>
    <!--PUT HTML HERE-->
    <div>
        <div class="col-xs-12 right-container">
            <kr-search
                name="accounts_listing"
                :url="{ url: '/accounts' }"
                :columns="{
                    organization: 'src:organisationName|display:' + $t('modules.accounts.listing.table.organisationNameLabel') + '|sortable:true|width:20%',
                    isSuspended: 'display:' + $t('modules.accounts.listing.table.statusLabel') + '|sortable:false|width:10%|mobile:hide',
                    type: 'display:' + $t('modules.accounts.listing.table.typeLabel') + '|sortable:true|width:10%|mobile:hide',
                    payment_method:
                        'src:paymentMethod|display:' + $t('modules.accounts.listing.table.paymentMethodLabel') + '|sortable:true|width:15%|mobile:hide',
                    startDate: 'display:' + $t('modules.accounts.listing.table.startDateLabel') + '|sortable:true|width:10%|mobile:hide',
                    expiryDate: 'display:' + $t('modules.accounts.listing.table.endDateLabel') + '|sortable:true|width:10%|mobile:hide',
                }"
                :options="{
                    suspended_field: true,
                    pay_type_field: true,
                    payment_method_field: true,
                    clear_field: true,
                    default_query_fields: {
                        sort: 'dateCreated',
                        order: 'desc',
                    },
                    search_history_id: 'accounts.index',
                    newServerMode: {
                        includes: ['q', 'sort', 'order', 'organisationName', 'isSuspended', 'type', 'paymentMethod', 'startDate', 'expiryDate'],
                    },
                }"
                @retrieve-data="getSearchData"
            >
                <template #upper-right>
                    <router-link v-slot="{ navigate }" :to="{ name: 'accounts.create' }" custom>
                        <button class="btn btn-primary" @click="navigate">
                            <i class="fa fa-plus marginRight8" aria-label="Add" />
                            {{ $t('modules.accounts.listing.newAccountButtonLabel') }}
                        </button>
                    </router-link>
                </template>
                <!-- <template slot="top-display-from-to" slot-scope="props">
                    <div class="displayNo">
                        {{props.model.from}} - {{props.model.to}} of {{props.model.meta.paginator.total}}
                    </div>
                </template> -->
                <template #table-title="props">
                    <th colspan="10">
                        <template v-if="searchData.query.isSuspended == '0'"> {{ $t('modules.accounts.listing.table.activeStatusLabel') }} </template>
                        <template v-if="searchData.query.isSuspended == '1'"> {{ $t('modules.accounts.listing.table.suspendedStatusLabel') }} </template>
                        <template v-if="searchData.query.isSuspended == '*'"> {{ $t('modules.accounts.listing.table.allStatusLabel') }} </template>
                        Accounts
                        <template v-if="props.model.meta.paginator.total > 0">
                            ({{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }})
                        </template>
                    </th>
                </template>
                <template #bottom-display-from-to="props">
                    <div v-if="props.model.meta.paginator.total > 0" class="displayNo">
                        {{ props.model.from }} - {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }}
                    </div>
                </template>
                <template #ndata="props">
                    <tr>
                        <td class="borderNone borderTop1pxGrey">
                            <span>
                                No account yet,
                                <router-link :to="{ name: 'accounts.create' }" tag="a" class="link"> Click here </router-link>
                                to create one
                            </span>
                        </td>
                    </tr>
                </template>
                <template #cdata="props">
                    <td>
                        <router-link
                            v-tooltip="{ content: 'View Account' }"
                            tag="a"
                            :to="{
                                name: 'accounts.view',
                                params: { id: props.model.uuid },
                            }"
                            class="link"
                            :aria-label="props.model.organisationName"
                        >
                            {{ props.model.organisationName }}
                        </router-link>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <div style="width: 80px">
                            <template v-if="!props.model.isSuspended"> <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true" />Active </template>
                            <template v-else> <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Suspended </template>
                        </div>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        {{ props.model.type }}
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <template v-if="props.model.paymentMethod == 'Student-Paid'">
                            <div class="statusTag statusTagPrimary">Student-Paid</div>
                        </template>
                        <template v-else>
                            <div class="statusTag statusTagWarning">Institution</div>
                        </template>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <span v-dompurify-html="parseReadableDate(props.model.startDate, false)" />
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <span v-dompurify-html="parseReadableDate(props.model.expiryDate, false)" />
                    </td>
                    <td>
                        <div class="buttonGroupInline">
                            <router-link
                                v-slot="{ navigate }"
                                v-tooltip="{
                                    content: $t('modules.superusers.listing.table.viewButtonTooltipLabel'),
                                }"
                                :to="{
                                    name: 'accounts.view',
                                    params: { id: props.model.uuid },
                                }"
                                custom
                            >
                                <button class="btn btn-primary" aria-label="View Account" @click="navigate">
                                    <i class="fas fa-eye marginRight8" aria-hidden="true" /> {{ $t('modules.accounts.listing.table.viewButtonLabel') }}
                                </button>
                            </router-link>
                        </div>
                    </td>
                </template>
            </kr-search>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../components/auth/auth';
import KrForm from '../../../components/forms/form';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            searchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                query: { isSuspended: '0' },
            },
            user: {},
        };
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-id-card"></i>&nbsp;Accounts',
            breadcrumb: [{ name: 'Home' }, { name: this.$t('modules.accounts.listing.breadcrumbLabel'), link: 'accounts.index' }],
        });
    },
    methods: {
        /*openImpersonationModal(user) {
            this.user = user;
            $('#impersonationModal').modal('show');
        },
        impersonate() {
            $('#impersonationModal').modal('hide');
            var that = this;
            let userUuid = this.user.uuid;
            axios
                .post('auth/impersonate', { userUuid: userUuid })
                .then(function (response) {
                    let token = response.data.data.token;
                    axios
                        .post('/auth/validate?impersonate', {}, { headers: { Authorization: `Bearer ${token}` } })
                        .then(function (response) {
                            that.auth.login(response.data, {
                                impersonate: true,
                            });
                        })
                        .catch(function (errors) {
                            that.$notify({
                                group: 'form',
                                type: 'error',
                                title: 'Error',
                                text: 'Something went wrong, please try again later ',
                            });
                        });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },*/
        getSearchData(data) {
            this.searchData = data;
        },
        /*openResetPasswordModal(account) {
            Events.fire('open-reset-password-modal', {
                user: account.adminTeacherAccount,
                organisation: account.organisationName,
            });
        },*/
    },
};
</script>
