<template>
    <div>
        <div>
            <h3 class="margin0 fs-18px">Question Stem</h3>
            <kr-math
                :input="question.question"
                :safe="!question.question.questionIsHTML"
                class="question"
                :can-highlight="true"
                :question-uuid="question.uuid"
            />
        </div>

        <div v-if="question.attachments && question.attachments.length != 0">
            <div class="marginTop20 fontBold"><i class="fa fa-paperclip marginRight8" aria-hidden="true" />Question Attachment(s)</div>
            <div class="textLink">
                <template v-for="(file, idx) in question.attachments" :key="'mcq-' + idx + '-' + file.filename">
                    <div class="flex">
                        <i class="fa fa-file-image-o marginRight8" aria-hidden="true" />
                        <template
                            v-if="
                                (testObj.type == 'application' && (!isTestCompleted || (isTestCompleted && testObj.allowStudentsToViewAttachments))) ||
                                testObj.type != 'application'
                            "
                        >
                            <template v-if="isPdf(file)">
                                <template v-if="testObj.settings.allowPdfDownload">
                                    <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">
                                        {{ file.filename }}
                                    </a>
                                </template>
                                <template v-else>
                                    <a class="textLink" :aria-label="file.filename" @click.prevent="openViewPdfModal(file)">{{ file.filename }}</a>
                                </template>
                            </template>
                            <template v-else>
                                <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                            </template>
                        </template>
                        <template v-else>
                            <a v-tooltip="{ content: 'Viewing disabled' }" class="textLink disabled" :aria-label="file.filename">{{ file.filename }} </a>
                        </template>
                    </div>
                </template>
            </div>
        </div>

        <div class="marginTop20">
            <template v-if="testObj._isPreview && !testObj._isTeacherPreview">
                <fieldset>
                    <legend>Question {{ getQuestionNo(question.group - 1, question.order - 1) }} answer choices</legend>
                    <template v-for="option in question.options">
                        <div class="radioField flex">
                            <label>
                                <span v-if="!testObj.settings.hideQuestionOptionLabels" class="withOptionKey"> {{ option.key }}. </span>
                                <span v-dompurify-html="option.content" />
                            </label>
                        </div>
                    </template>
                </fieldset>
            </template>

            <template v-else-if="testObj.settings.confidenceBasedTesting && question.type == 'mcqs'">
                <fieldset>
                    <legend>Question {{ getQuestionNo(question.group - 1, question.order - 1) }} answer choices</legend>
                    <template v-for="option in question.options" :key="'mcqs-' + question.uuid + '-' + option.key">
                        <div :id="question.uuid + '-' + option.key + '-div'" class="radioField" :class="{ flex: !isMobileView }">
                            <div class="numberInputButtons">
                                <button
                                    class="btn btn-outline-dark"
                                    v-tooltip="
                                        canChangePointSpreading(option.key, model[option.key] - 1)
                                            ? `Current Value: ${model[option.key]} Decrease value to ${model[option.key] - 1}`
                                            : `Current Value: 0, Cannot have negative value`
                                    "
                                    :class="{
                                        disabled: !canChangePointSpreading(option.key, model[option.key] - 1),
                                    }"
                                    @click.prevent="
                                        canChangePointSpreading(option.key, model[option.key] - 1)
                                            ? changePointSpreading(option.key, model[option.key] - 1)
                                            : null
                                    "
                                >
                                    <i class="fas fa-minus" aria-hidden="true" />
                                </button>

                                <input
                                    v-tooltip="
                                        disabled || !canChangePointSpreading(option.key, model[option.key] + 1)
                                            ? `Current Value: ${model[option.key]}, You have used all the points please use minus button to decrease value`
                                            : `Current Value: ${model[option.key]}`
                                    "
                                    type="number"
                                    class="form-control"
                                    min="0"
                                    :value="model[option.key]"
                                    :readonly="disabled || !canChangePointSpreading(option.key, model[option.key] + 1) ? true : undefined"
                                    :aria-label="`Current Value: ${model[option.key]}`"
                                    @keypress="isNumber($event, option.key)"
                                    @keyup="changePointSpreading(option.key, $event.target.value)"
                                />

                                <button
                                    v-tooltip="
                                        canChangePointSpreading(option.key, model[option.key] + 1)
                                            ? `Current Value: ${model[option.key]} Increase value to ${model[option.key] + 1}`
                                            : `Current Value: ${model[option.key]}, You have used all the points`
                                    "
                                    class="btn btn-outline-dark margin0"
                                    :class="{
                                        disabled: !canChangePointSpreading(option.key, model[option.key] + 1),
                                    }"
                                    @click.prevent="
                                        canChangePointSpreading(option.key, model[option.key] + 1)
                                            ? changePointSpreading(option.key, model[option.key] + 1)
                                            : null
                                    "
                                >
                                    <i class="fas fa-plus" aria-hidden="true" />
                                </button>
                            </div>
                            <label :class="{ width100: isMobileView }">
                                <span v-if="!testObj.settings.hideQuestionOptionLabels" class="withOptionKey"> {{ option.key }}. </span>
                                <kr-math :input="option.content" :safe="!option.contentIsHTML" />
                            </label>
                        </div>
                    </template>
                </fieldset>
            </template>

            <template v-else-if="question.type == 'mcqs'">
                <fieldset>
                    <legend>Question {{ getQuestionNo(question.group - 1, question.order - 1) }} answer choices</legend>

                    <template v-for="(option, op_idx) in question.options" :key="'mcqs-' + question.uuid + '-' + op_idx">
                        <div :id="question.uuid + '-' + option.key + '-div'" class="radioField flex" :class="{ highlight: option.highlight }">
                            <template v-if="isCorrect(option.key) === true || isWrong(option.key) === true">
                                <template v-if="isCorrect(option.key) === true">
                                    <i class="correct fa fa-check-circle marginRight10" role="img" aria-label="Correct Answer Choice" tabindex="0" />
                                </template>
                                <template v-else-if="isWrong(option.key) === true">
                                    <i class="wrong fa fa-times-circle marginRight10" role="img" aria-label="Incorrect Answer Choice" tabindex="0" />
                                </template>
                            </template>
                            <input
                                v-else
                                :id="question.uuid + '-' + option.key"
                                v-model="model"
                                :name="question.uuid"
                                type="radio"
                                :disabled="disabled ? true : undefined"
                                :value="option.key"
                                @change="$emit('changed', model)"
                            />
                            <label
                                :for="question.uuid + '-' + option.key"
                                class="paddingTop2"
                                :aria-describedby="question.uuid + '-' + option.key + '_savedTime'"
                            >
                                <span v-if="!testObj.settings.hideQuestionOptionLabels" class="withOptionKey">
                                    <template v-if="testObj.settings.randomizeAnswerOptionOrder && testObj.type == 'trat'">
                                        {{ String.fromCharCode(65 + op_idx) }}.
                                    </template>
                                    <template v-else> {{ option.key }}. </template>
                                </span>
                                <kr-math :input="option.content" :safe="!option.contentIsHTML" />
                            </label>
                        </div>
                        <p :id="question.uuid + '-' + option.key + '_savedTime'" class="savedTime" v-dompurify-html="displayLastAttemptDate(option)" />
                    </template>
                </fieldset>
            </template>

            <template v-else-if="question.type == 'mcqm'">
                <fieldset>
                    <legend>
                        Question {{ getQuestionNo(question.group - 1, question.order - 1) }} answer choices
                        <template v-if="question.countForRequiredAnswersForMcqm > 0">
                            (Please pick exactly {{ question.countForRequiredAnswersForMcqm }} options)
                        </template>
                    </legend>

                    <div
                        v-for="option in question.options"
                        :id="question.uuid + '-' + option.key + '-div'"
                        :key="'mcqm-' + question.uuid + '-' + option.key"
                        class="checkboxField flex"
                    >
                        <template v-if="isCorrect(option.key) === true || isWrong(option.key) === true">
                            <template v-if="isCorrect(option.key) === true">
                                <i class="correct fa fa-check-circle marginRight10" role="img" aria-label="Correct Answer Choice" tabindex="0" />
                            </template>
                            <template v-else-if="isWrong(option.key) === true">
                                <i class="wrong fa fa-times-circle marginRight10" role="img" aria-label="Incorrect Answer Choice" tabindex="0" />
                            </template>
                        </template>
                        <input
                            v-else
                            :id="question.uuid + '-' + option.key"
                            v-model="model"
                            :name="question.uuid"
                            type="checkbox"
                            :disabled="
                                disabled
                                    ? true
                                    : question.countForRequiredAnswersForMcqm > 0 &&
                                      model.length == question.countForRequiredAnswersForMcqm &&
                                      !model.includes(option.key)
                                    ? true
                                    : undefined
                            "
                            :value="option.key"
                            @change="$emit('changed', model)"
                        />
                        <label :for="question.uuid + '-' + option.key" class="paddingTop3">
                            <span class="withOptionKey"> {{ option.key }}. </span>
                            <kr-math :input="option.content" :safe="!option.contentIsHTML" />
                        </label>
                    </div>
                </fieldset>
            </template>
        </div>
    </div>
</template>

<script>
import { testMixins } from '../../../../../../../../mixins/test';

export default {
    mixins: [testMixins],
    props: ['testObj', 'question', 'value', 'disabled', 'isTestCompleted'],
    data() {
        return {
            model: null,
        };
    },
    computed: {
        pointSpreadingPointsLeft() {
            if (!this.testObj.settings.confidenceBasedTesting) {
                return null;
            }
            let total = this.question.options.length;
            //return total;
            var that = this;
            Object.keys(this.model).forEach(function (key, index) {
                total -= parseInt(that.model[key]);
            });
            return total;
        },
    },
    watch: {
        value: {
            handler: function () {
                this.model = this.value;
            },
        },
        question: {
            handler: function () {
                this.model = this.value;
            },
            deep: true,
        },
    },
    created() {
        this.model = this.value;
    },
    methods: {
        displayLastAttemptDate(option) {
            if (!this.question.studentAnswer.attempts2 || this.testObj.type != 'irat') {
                return '';
            }
            for (var i = this.question.studentAnswer.attempts2.length - 1; i >= 0; i--) {
                let key = this.testObj.settings.randomizeAnswerOptionOrder ? option.originalKey : option.key;
                if (key == this.question.studentAnswer.attempts2[i].attempt) {
                    //console.log(this.question.studentAnswer.attempts2[i])
                    return `Selected this answer at ${
                        this.convertToReadableDate(this.question.studentAnswer.attempts2[i].timestamp, 'YYYY-MM-DD h:mm A').date
                    } (${this.convertToReadableDate(this.question.studentAnswer.attempts2[i].timestamp, 'YYYY-MM-DD h:mm A').current_timezone})`;
                }
            }
            return '';
        },
        isNumber(event, key) {
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            const keyPressed = event.key;
            if (!keysAllowed.includes(keyPressed)) {
                event.preventDefault();
            } else {
                event.target.value = event.target.value.replace(/^0+/, '');
            }
        },
        canChangePointSpreading(key, value) {
            if (this.disabled) {
                return false;
            }
            if (value == '') {
                value = '0';
            }
            let oldPoint = parseInt(this.model[key]);
            let newPoint = parseInt(value);
            let pointsLeft = this.pointSpreadingPointsLeft;
            if (newPoint < 0) {
                return false;
            }
            pointsLeft = pointsLeft + oldPoint;
            if (pointsLeft - newPoint >= 0 || (newPoint > oldPoint && this.pointSpreadingPointsLeft > 0)) {
                return true;
            } else {
                return false;
            }
        },
        changePointSpreading(key, value) {
            if (value == '') {
                this.model[key] = 0;
                value = 0;
            }
            if (this.canChangePointSpreading(key, value)) {
                if (parseInt(value) > parseInt(this.model[key]) + this.pointSpreadingPointsLeft) {
                    value = parseInt(this.model[key]) + this.pointSpreadingPointsLeft;
                }
                this.model[key] = value;
                //Vue.set(this.model, key, value);
                this.$emit('changed', this.model);
            }
        },
        isCorrect(key) {
            if (this.testObj.type == 'irat' && _.get(this.testObj, 'settings.randomizeAnswerOptionOrder', false)) {
                key = this.question.options[key.charCodeAt(0) - 65].originalKey;
            }
            if (!(this.testObj.type == 'trat' || (this.testObj.type == 'irat' && _.get(this.testObj, 'settings.immediateFeedback', false)))) {
                return null;
            }
            let isCorrect = _.get(this.question, 'studentAnswer.isCorrect', false);
            let attempts = _.clone(_.get(this.question, 'studentAnswer.attempts', []));
            if (attempts.length == 0) {
                return false;
            }

            if (isCorrect) {
                if (key == attempts[attempts.length - 1]) {
                    return true;
                }
            } else {
                return false;
            }
        },
        isWrong(key) {
            if (this.testObj.type == 'irat' && _.get(this.testObj, 'settings.randomizeAnswerOptionOrder', false)) {
                key = this.question.options[key.charCodeAt(0) - 65].originalKey;
            }
            if (!(this.testObj.type == 'trat' || (this.testObj.type == 'irat' && _.get(this.testObj, 'settings.immediateFeedback', false)))) {
                return null;
            }
            let isCorrect = _.get(this.question, 'studentAnswer.isCorrect', false);
            let attempts = _.clone(_.get(this.question, 'studentAnswer.attempts', []));
            if (attempts.length == 0) {
                return false;
            }
            if (isCorrect) {
                attempts.pop();
                if (attempts.includes(key)) {
                    return true;
                }
            } else {
                if (attempts.includes(key)) {
                    return true;
                }
            }
            return false;
        },
        init() {
            this.model = this.value;
        },
        openViewPdfModal(attachment) {
            var that = this;
            Events.fire('view-pdf-modal', attachment, {
                canDownload: that.testObj.settings.allowPdfDownload,
            });
        },
    },
};
</script>
