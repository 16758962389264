<template>
    <div ref="modal" class="modal primary-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="modality-title">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 class="modal-title">
                        <template v-if="formType == 'create'">Create Template</template>
                    </h2>
                </div>
                <div class="modal-body">
                    <template v-if="activity">
                        <label class="control-label"> Activity Name </label>
                        <p>{{ activity.name }}</p>
                    </template>
                    <VForm
                        ref="form"
                        v-slot="{ values, setFieldValue, meta, errors }"
                        :validation-schema="schema"
                        :initial-values="initialValues"
                        class="marginTop20"
                    >
                        <BasicInfoForm :values="values"></BasicInfoForm>

                        <div class="textAlignRight marginTop30">
                            <button
                                v-tooltip="!(meta.valid && meta.dirty) ? 'Please enter template name' : ''"
                                class="btn btn-success"
                                :class="{ disabled: !(meta.valid && meta.dirty) }"
                                @click.prevent="!(meta.valid && meta.dirty) ? null : submit(values)"
                            >
                                Create
                            </button>
                        </div>
                    </VForm>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { notify } from '@kyvg/vue3-notification';
import { ref, nextTick } from 'vue';
import { Form as VForm, Field } from 'vee-validate';
import BasicInfoForm from './../form-partials/partial.basic_info.vue';
import * as yup from 'yup';
var formType = ref('create');
const form = ref(null);
const modal = ref(null);
const activity = ref(null);
var schema = yup.object({
    activityType: yup.string().required(),
    name: yup.string().required(),
    description: yup.string().nullable(),
    activityUuid: yup.string().required(),
});
var initialValues = ref({
    activityType: 'irat',
    name: '',
    description: '',
    activityUuid: '',
});
function openModal() {
    nextTick(() => {
        $(modal.value).modal('show');
    });
}
function closeModal() {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
}
function submit(values) {
    axios
        .post('templates', values)
        .then(function (response) {
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Template successfully saved',
            });
            closeModal();
        })
        .catch(function (errors) {
            console.log(errors);
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong. Please try again later',
            });
        });
}

function hydrate(data) {
    if (!data) {
        form.value.setValues(initialValues.value);
    } else {
        form.value.setValues(data);
    }
}
function createFromActivity(data) {
    activity.value = data;
    hydrate({ activityType: data.type, activityUuid: data.uuid });
    openModal();
}
defineExpose({
    createFromActivity,
});
</script>
