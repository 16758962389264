<template>
    <div
        ref="modal"
        class="modal warning-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="requestAccessModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="requestAccessModal-title" class="modal-title">Request Access</h2>
                </div>

                <div class="modal-body textAlignCenter">
                    <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                    <h3 class="marginTop40">Requesting Access to</h3>
                    <h4>
                        {{ course.name }}
                        <template v-if="course.code"> ({{ course.code }}) </template>
                    </h4>

                    <p class="marginTop20">Please choose a role for the course collaborator.</p>
                    <p>If approved, it will appear on your</p>
                    <p>My Courses tab.</p>

                    <div class="marginTop20 flex justifyCenter">
                        <div class="col-xs-12 col-sm-8 textAlignLeft">
                            <label for="select-course-collaborator-role" class="control-label">
                                Select Course Collaborator Role<template>
                                    <span class="visible-hidden">Required</span>
                                    <span class="important" aria-hidden="true">*</span>
                                </template>
                            </label>
                            <div class="form-group-select-wrapper form-dropdown">
                                <select id="select-course-collaborator-role" v-model="role" class="form-control">
                                    <option value="" disabled selected>Course Collaborator Role</option>
                                    <option v-for="roleOption in roles" :key="roleOption" :value="roleOption">
                                        {{ roleOption.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeModal()">Cancel</button>
                    <button
                        v-tooltip="role == '' ? 'Please select a collaborator role' : ''"
                        class="btn btn-success"
                        :class="{ 'disabled hasToolTip': role == '' }"
                        @click.prevent="role == '' ? '' : submit()"
                    >
                        Request Access
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';

const router = useRouter();
const modal = ref(null);
const course = ref({});
const roles = ref([]);
const role = ref('');

const request = (c = {}) => {
    course.value = c;
    fetchRoles();
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    role.value = '';
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const fetchRoles = () => {
    axios.get('course/roles').then((response) => {
        roles.value = response.data.data.filter((role) => role.name !== 'Course Owner');
    });
};

const submit = () => {
    axios
        .post(`courses/${course.value.uuid}/collaborator-user`, {
            roleId: role.value.id,
        })
        .then(function (response) {
            closeModal();
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Please wait for approval',
            });
        })
        .catch(function (errors) {
            role.value = '';
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Unable to request access at this moment, please try again later',
            });
        });
};

defineExpose({ request });
</script>
