<template>
    <div
        ref="modal"
        class="modal danger-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="deleteReportModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="deleteReportModal-title" class="modal-title">Delete Report</h2>
                </div>

                <div class="modal-body textAlignCenter">
                    <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                    <h3 class="marginTop40">Delete</h3>
                    <h4>
                        {{ report.name }}
                    </h4>

                    <p class="marginTop20">Are you sure you want to delete this report?</p>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                    <button class="btn btn-danger" @click.prevent="submit">Yes, Delete Report</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';

const modal = ref(null);
const course = ref({});
const report = ref({});

const deleteReport = (c = {}, r = {}) => {
    course.value = c;
    report.value = r;
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .delete(`/courses/${course.value.uuid}/downloadables/${report.value.id}`)
        .then((response) => {
            closeModal();
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Report has successfully been deleted',
            });
            Events.fire('downloadables_listing_refresh');
        })
        .catch((error) => {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Unable to delete report at this moment, please try again later',
            });
        });
};

defineExpose({ deleteReport });
</script>
