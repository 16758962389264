<template>
    <div v-if="component_done_loading" :id="question.uuid + '-question'" class="panel">
        <div role="alert" aria-live="assertive" class="visible-hidden">
            You are now at Question
            {{ getQuestionNo(question_group_idx, question_idx) }}
        </div>
        <div class="panel-heading flexSpaceBetween">
            <div>
                <h3 class="margin0 fs-18px">Question {{ getQuestionNo(question_group_idx, question_idx) }}</h3>
            </div>
            <template v-if="question.type == 'openended' && question.eGallery.status == 'ongoing' && question.eGalleryWalkSettings.startTime != null">
                <div
                    class="timer_Div darkGreen flex align-items justifyCenter"
                    :class="{ miniTimer: miniTimerVisible }"
                    role="timer"
                    aria-live="polite"
                    aria-atomic="true"
                    tabindex="0"
                >
                    <span class="visible-hidden">{{ formattedTime }}</span>
                    <div class="textAlignCenter" aria-hidden="true">
                        <label class="successColor">
                            <span class="visible-hidden">{{ time.minutes }}</span>
                            Minutes
                        </label>
                        <div class="time flex justifyCenter" aria-hidden="true">
                            <div class="number-div">
                                {{ time.minutes.charAt(0) }}
                            </div>
                            <div class="number-div">
                                {{ time.minutes.charAt(1) }}
                            </div>
                        </div>
                    </div>

                    <div class="successColor marginLeft8 marginRight8">:</div>

                    <div class="textAlignCenter" aria-hidden="true">
                        <label class="successColor">
                            <span class="visible-hidden">{{ time.seconds }}</span>
                            Seconds
                        </label>
                        <div class="time flex justifyCenter" aria-hidden="true">
                            <div class="number-div">
                                {{ time.seconds.charAt(0) }}
                            </div>
                            <div class="number-div">
                                {{ time.seconds.charAt(1) }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="panel-body eGallery-panel">
            <template v-if="question.type == 'mcqs' || question.type == 'mcqm'">
                <mcq :test-obj="testObj" :question="question" />
            </template>
            <template v-else-if="question.type == 'openended'">
                <openended :test-obj="testObj" :question="question" />
            </template>
            <template v-else-if="question.type == 'sequence'">
                <sequence :test-obj="testObj" :question="question" />
            </template>
            <template v-else-if="question.type == 'matching' && question.options.matchingType == 'one-to-one'">
                <matching :test-obj="testObj" :question="question" />
            </template>
            <template v-else-if="question.type == 'matching' && question.options.matchingType == 'one-to-many'">
                <categorize :test-obj="testObj" :question="question" />
            </template>
        </div>

        <div
            :id="question.uuid + '-enlarge-image-modal'"
            class="modal modal-manual"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'enlargeImageModal-' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'enlargeImageModal-' + question.uuid + '-title'" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true" />Enlarged Image
                        </h2>
                    </div>
                    <div class="modal-body padding0">
                        <img :key="srcValue" :src="srcValue" width="100%" alt="" role="presentation" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { testMixins } from './../../../../../../../../mixins/test';
export default {
    mixins: [testMixins],
    props: ['testObj', 'question', 'question_group_idx', 'question_idx'],
    data() {
        return {
            component_done_loading: false,
            timer: null,
            time: { minutes: '00', seconds: '00' },
            scrollFunction: null,
            miniTimerVisible: false,
            srcValue: '',
            formattedTime: '',
        };
    },
    watch: {
        question: {
            handler: function () {
                if (
                    this.question.type == 'openended' &&
                    ['ongoing'].includes(this.question.eGallery.status) &&
                    this.question.eGalleryWalkSettings.timeLimitInMinutes
                ) {
                    this.initTimer();
                }
            },
            deep: true,
        },
        openEnlargeImageModal(src, id) {
            if (id == this.question.uuid) {
                $('#' + this.question.uuid + '-enlarge-image-modal').modal('show');

                this.srcValue = src;

                $('#' + this.question.uuid + '-enlarge-image-modal').resizable({
                    handles: 'ne, se, sw, nw',
                });
            }
        },
    },
    created() {
        var that = this;

        this.component_done_loading = true;
        /*this.scrollFunction = function() {
            if (
                (this.testObj.others.applicationType == 'team' &&
                    $(window).scrollTop() > 407) ||
                (this.testObj.others.applicationType != 'team' &&
                    $(window).scrollTop() > 322)
            ) {
                that.miniTimerVisible = true;
            } else {
                that.miniTimerVisible = false;
            }
        }.bind(this);*/

        Vue.nextTick(function () {
            //that.scrollFunction();
            $('#' + that.question.uuid + '-enlarge-image-modal').draggable();
        });
    },
    mounted() {
        window.addEventListener('click', (event) => {
            const isImg = event.target.nodeName === 'IMG';
            if (!isImg) {
                return;
            }

            var questionUUID = event.target.closest('.panel').getAttribute('id').slice(0, 36);
            this.openEnlargeImageModal(event.target.src, questionUUID);
        });

        if (this.question.type == 'openended' && ['ongoing'].includes(this.question.eGallery.status) && this.question.eGalleryWalkSettings.timeLimitInMinutes) {
            this.initTimer();
        }

        window.addEventListener('scroll', this.scrollFunction);
    },
    beforeUnmount() {
        window.removeEventListener('click', (event) => {
            const isImg = event.target.nodeName === 'IMG';
            if (!isImg) {
                return;
            }

            var questionUUID = event.target.closest('.panel').getAttribute('id').slice(0, 36);
            this.openEnlargeImageModal(event.target.src, questionUUID);
        });

        window.removeEventListener('scroll', this.scrollFunction);
    },
    methods: {
        stopTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
        },
        initTimer() {
            var that = this;
            this.stopTimer();
            var startTime = this.convertToReadableDate(_.get(this.question.eGalleryWalkSettings, 'startTime', '')).dateObj;
            var endTime = startTime.add(this.question.eGalleryWalkSettings.timeLimitInMinutes, 'minutes');
            this.timer = setInterval(function (startTime) {
                var now = startTime;
                var distance = moment.duration(endTime.diff(now));
                if (distance > 0) {
                    that.time.minutes = ('0' + (distance.minutes() + distance.hours() * 60)).slice(-2);
                    that.time.seconds = ('0' + distance.seconds()).slice(-2);
                } else {
                    that.time.minutes = '00';
                    that.time.seconds = '00';
                }

                if (that.time.minutes > 0) {
                    that.formattedTime = `${that.time.minutes} minutes`;
                } else {
                    that.formattedTime = `${that.time.seconds} seconds`;
                }
            }, 1000);
        },
        openEnlargeImageModal(src, id) {
            if (id == this.question.uuid) {
                $('#' + this.question.uuid + '-enlarge-image-modal').modal('show');

                this.srcValue = src;

                $('#' + this.question.uuid + '-enlarge-image-modal').resizable({
                    handles: 'ne, se, sw, nw',
                });
            }
        },
    },
    components: {
        mcq: require(`./partials/mcq.vue`).default,
        openended: require(`./partials/openended.vue`).default,
        sequence: require(`./partials/sequence.vue`).default,
        matching: require(`./partials/matching.vue`).default,
        categorize: require(`./partials/categorize.vue`).default,
    },
};
</script>
<style lang="scss" scoped>
.timer_Div {
    .time .number-div {
        background: #fff;
        padding: 6px;
        border-radius: 3px;
        font-size: 28px;
        font-weight: bold;
        line-height: 0.8;
    }

    .time .number-div:last-child {
        margin-left: 5px;
    }

    .colon {
        color: #fff;
        padding: 0 10px;
    }

    .timeText {
        font-size: 0.778rem;
        margin-bottom: 5px;
    }
}

.miniTimer {
    position: fixed;
    top: 10px;
    right: 100px;
    z-index: 1001;

    &.timer_Div {
        .timeText {
            text-align: center;
            font-size: 10px;
        }
        .time {
            .number-div {
                font-size: 20px;
            }
        }
    }
}
</style>
