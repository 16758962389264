<template>
    <div v-if="component_done_loading">
        <div v-if="pageType == 'form'" class="marginBottom20">
            <button class="btn btn-success" aria-label="Go to Optional Settings" @click.prevent="submitMaterials">
                Go to Optional Settings<i class="fas fa-caret-right marginLeft8" aria-hidden="true"></i>
            </button>
            <button class="btn btn-default" aria-label="Save and Exit" @click.prevent="saveAndExit">
                <i class="fa fa-floppy-o marginRight8" aria-hidden="true"></i>Save and Exit
            </button>
        </div>

        <div v-if="pageType != 'confirmation'" class="pageTitle">
            <h1>Upload Materials</h1>
            <p class="col-md-6">This page lets you upload prework materials for students to review before the main activities begin.</p>
        </div>

        <kr-panel :with-footer="false" :options="{ canToggle: pageType == 'confirmation' }">
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>Materials Uploaded ({{ form.model.materials.length }})</h2>
                </div>

                <div class="col-xs-12 col-sm-6 flex gap10" :class="{ justifyEnd: !isBelow768px }">
                    <template v-if="pageType == 'confirmation'">
                        <template v-if="mode == 'edit'">
                            <button class="btn btn-success margin0 flexInherit" @click.prevent="submitMaterials()">Update</button>
                            <button class="btn btn-default margin0 flexInherit" exact @click.prevent="mode = 'view'">Cancel</button>
                            <button class="btn btn-default margin0 flexInherit" @click.prevent="openFileStack">
                                <i class="fas fa-upload marginRight8" aria-hidden="true"></i>Upload Prework Material
                            </button>
                        </template>
                        <template v-if="mode == 'view'">
                            <button
                                class="btn btn-default margin0 flexInherit"
                                @click.prevent="
                                    mode = 'edit';
                                    init();
                                "
                            >
                                <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i>Edit
                            </button>
                        </template>
                    </template>
                    <template v-else>
                        <button class="btn btn-default margin0 flexInherit" @click.prevent="openFileStack">
                            <i class="fas fa-upload marginRight8" aria-hidden="true"></i> Upload Prework Material
                        </button>
                    </template>
                </div>
            </template>
            <template #content>
                <template v-if="form.model.materials.length == 0"> Currently no materials were added. </template>
                <template v-else>
                    <div class="materialsTable table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th v-if="pageType == 'form' || mode == 'edit'" class="width140px maxWidth140px">
                                        <div class="checkboxField">
                                            <input id="selectAllMaterials" type="checkbox" :checked="isAllToggled" @click="toggleAll($event)" />
                                            <label for="selectAllMaterials" class="control-label">Select All</label>
                                        </div>
                                    </th>
                                    <th style="width: 50%">File</th>
                                    <th class="hidden-xs hidden-sm">Size</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(attachment, idx) in form.model.materials">
                                    <td v-if="pageType == 'form' || mode == 'edit'">
                                        <div class="checkboxField">
                                            <input v-model="selected_materials_idx" type="checkbox" :value="idx" />
                                        </div>
                                    </td>
                                    <td>
                                        {{ attachment.filename }}
                                    </td>
                                    <td class="hidden-xs hidden-sm">
                                        {{ (attachment.size / 1000000).toFixed(2) }}
                                        MB
                                    </td>

                                    <td>
                                        <div class="hidden-xs hidden-sm buttonGroupInline">
                                            <a class="btn btn-default" target="_blank" :href="attachment.url" aria-label="Preview Attachment"> Preview </a>
                                            <button
                                                v-if="pageType == 'form' || mode == 'edit'"
                                                class="btn btn-danger"
                                                aria-label="Remove Attachment"
                                                @click="deleteMaterial(idx)"
                                            >
                                                <i class="fa fa-trash marginRight8" aria-hidden="true"></i>Remove
                                            </button>
                                        </div>
                                        <div class="hidden-md hidden-lg buttonGroupInline">
                                            <a class="btn btn-default" target="_blank" :href="attachment.url" aria-label="Preview Attachment">
                                                <i class="fas fa-eye" aria-hidden="true"></i>
                                            </a>
                                            <button
                                                v-if="pageType == 'form' || mode == 'edit'"
                                                class="btn btn-danger"
                                                aria-label="Remove Attachment"
                                                @click="deleteMaterial(idx)"
                                            >
                                                <i class="fa fa-trash" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="pageType == 'form' || mode == 'edit'" class="col-xs-12 col-md-4 buttonGroup marginTop20">
                        <button
                            class="btn btn-danger"
                            :disabled="selected_materials_idx.length == 0 ? true : undefined"
                            :aria-label="'Remove Selected (' + selected_materials_idx.length + ')'"
                            @click="deleteSelectedMaterials"
                        >
                            <i class="fa fa-trash marginRight8" aria-hidden="true"></i>Remove Selected ({{ selected_materials_idx.length }})
                        </button>
                        <button
                            class="btn btn-default"
                            :disabled="selected_materials_idx.length == 0 ? true : undefined"
                            aria-label="Cancel"
                            @click.prevent="
                                selected_materials_idx = [];
                                selected_materials_idx.splice();
                            "
                        >
                            Cancel
                        </button>
                    </div>
                </template>
            </template>
        </kr-panel>
        <div v-if="pageType == 'form'" class="marginTop10">
            <!--<button class="btn btn-default ">
                <i class="fas fa-caret-left"></i>&nbsp;Back
            </button>-->
            <button class="btn btn-success" aria-label="Go to Optional Settings" @click.prevent="submitMaterials">
                Go to Optional Settings<i class="fas fa-caret-right marginLeft8" aria-hidden="true"></i>
            </button>
            <button class="btn btn-default" aria-label="Save and Exit" @click.prevent="saveAndExit">
                <i class="fa fa-floppy-o marginRight8" aria-hidden="true"></i>Save and Exit
            </button>
        </div>
    </div>
</template>
<script>
import KrForm from '../../../../../../components/forms/form';
import * as filestack from 'filestack-js';
export default {
    props: ['activityObj', 'options'],
    data() {
        return {
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    materials: 'type!:array',
                },
                props: {
                    successMessage: false,
                },
            }),
            pageType: 'form',
            mode: 'view',
            selected_materials_idx: [],
        };
    },
    computed: {
        isAllToggled() {
            return this.form.model.materials.length == this.selected_materials_idx.length && this.form.model.materials.length != 0;
        },
    },
    created() {
        var that = this;
        this.pageType = _.get(this.options, 'pageType', 'form');
        //this.activityObj = false;
        /*if(!this.activityObj){
        		this.fetchActivity().then(function(response){
					that.activity = response.data.data;
					that.$emit('activity-updated',that.activity);
					that.init();
					that.component_done_loading = true;
				});
        	}else{*/
        this.init();
        this.component_done_loading = true;
    },
    methods: {
        saveAndExit() {
            this.submitMaterials(true);
        },
        init() {
            let materials = _.get(this, 'activityObj.materials');
            this.form.model.materials = [];
            for (var i = 0; i < materials.length; i++) {
                this.form.model.materials.push(materials[i].material);
            }
        },
        toggleAll(event) {
            if (this.isAllToggled) {
                this.selected_materials_idx = [];
            } else {
                this.selected_materials_idx = [];
                for (var i = 0; i < this.form.model.materials.length; i++) {
                    this.selected_materials_idx.push(i);
                }
            }
        },
        submitMaterials(exitOnSave = false) {
            var that = this;
            this.form.put('activities/' + this.activityObj.uuid + '/materials').then(function (response) {
                if (!response.krFormError) {
                    that.$emit('activity-updated', response.data.data, 'materials');
                    if (exitOnSave === true) {
                        Vue.nextTick(function () {
                            that.$emit('save-and-exit');
                        });
                    } else {
                        if (that.pageType == 'form') {
                            Vue.nextTick(function () {
                                that.$emit('next-step');
                            });
                        } else {
                            that.mode = 'view';
                        }
                        Vue.nextTick(function () {
                            that.init();
                        });
                    }
                }
            });
        },
        deleteMaterial(idx) {
            this.form.model.materials.splice(idx, 1);
            var index = this.selected_materials_idx.indexOf(idx);
            if (index !== -1) this.selected_materials_idx.splice(index, 1);

            for (var i = idx; i < this.selected_materials_idx.length; i++) {
                //reindex selected questions
                this.selected_materials_idx[i] -= 1;
            }
        },
        deleteSelectedMaterials() {
            this.selected_materials_idx.sort(function (a, b) {
                return b - a;
            });
            console.log(this.selected_materials_idx);
            let length = this.selected_materials_idx.length;
            for (var i = length - 1; i >= 0; i--) {
                this.deleteMaterial(this.selected_materials_idx[i], 1);
            }
            this.selected_materials_idx = [];
        },
        openFileStack() {
            this.client = filestack.init(process.env.VUE_APP_FILESTACK_API_KEY);
            var that = this;
            this.client
                .picker({
                    accept: [
                        '.jpg',
                        '.jpeg',
                        '.gif',
                        '.png',
                        '.csv',
                        '.doc',
                        '.docx',
                        '.dot',
                        '.dotx',
                        '.key',
                        '.numbers',
                        '.odt',
                        '.odp',
                        '.ods',
                        '.pages',
                        '.pdf',
                        '.ppt',
                        '.pot',
                        '.potx',
                        '.pps',
                        '.ppsx',
                        '.pub',
                        '.rtf',
                        '.sldx',
                        '.txt',
                        '.xls',
                        '.xlt',
                        '.xlsx',
                        '.mp4',
                        '.mp3',
                    ],
                    maxFiles: 20,
                    uploadInBackground: false,
                    onUploadStarted: function (file) {
                        //that.is_uploading = true;
                    },
                    onUploadDone: function (res) {
                        //that.is_uploading = false;
                        let currentLength = that.form.model.materials.length;
                        for (var i = 0; i < res.filesUploaded.length; i++) {
                            _.set(that.form.model, 'materials' + '.' + currentLength++, res.filesUploaded[i]);
                        }
                        that.form.model.materials.splice();
                    },
                })
                .open();
        },
    },
};
</script>
