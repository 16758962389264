<template>
    <div>
        <kr-panel :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <h2>Team Analysis</h2>
            </template>
            <template #content>
                <div v-if="component_done_loading">
                    <div
                        v-if="testObj.others.isApplicationGraded"
                        class="border1pxGrey padding15 br-3 bg-plain-white marginBottom20"
                        :class="{ 'col-xs-6 col-xs-offset-6': !isMobileView }"
                    >
                        <h3 class="fs-18px margin0 marginBottom15">
                            <b>Legend</b>
                        </h3>
                        <div class="flex">
                            <div class="flexOnly marginRight20">
                                <div class="marginRight8">
                                    <i class="fas fa-minus successColor d-block lh-0-5" aria-hidden="true" />
                                    <i class="fas fa-minus newCorrectColor d-block lh-0-5" aria-hidden="true" />
                                </div>
                                <p>100%</p>
                            </div>
                            <div class="flexOnly marginRight20">
                                <i class="fas fa-minus primaryColor marginRight8" aria-hidden="true" />
                                <p>> 50% and 100%</p>
                            </div>
                            <div class="flexOnly marginRight20">
                                <i class="fas fa-minus warningColor marginRight8" aria-hidden="true" />
                                <p>≤ 50% and > 0%</p>
                            </div>
                            <div class="flexOnly">
                                <i class="fas fa-minus dangerColor marginRight8" aria-hidden="true" />
                                <p>0%</p>
                            </div>
                        </div>
                    </div>

                    <div class="hidden-xs hidden-sm table-responsive new-table-container-analysis-team">
                        <div class="scrollable-table-wrapper flex">
                            <div class="new-left-table-scrollbar-application-team-analysis" @scroll="handleLeftScroll">
                                <div style="height: 0.1px" />
                            </div>
                            <div class="new-right-table-scrollbar-application-team-analysis" @scroll="handleRightScroll">
                                <div style="height: 0.1px" />
                            </div>
                        </div>

                        <div class="scrollable-table-wrapper flex" tabindex="0">
                            <div class="new-left-table-application-team-analysis" @scroll="handleLeftScroll">
                                <caption class="visible-hidden">
                                    Team Analysis table
                                </caption>

                                <div class="sticky-top-header zIndex1001">Details</div>
                                <table class="table">
                                    <thead class="zIndex1001">
                                        <tr>
                                            <th v-if="canClearAnswers" />
                                            <th>
                                                Team
                                                <template v-if="sort === 'team'">
                                                    <button v-if="order === 'asc'" aria-label="Sort Ascending" class="padding0" @click.prevent="toggle('team')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </button>
                                                    <button v-else aria-label="Sort Descending" class="padding0" @click.prevent="toggle('team')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </button>
                                                </template>
                                                <button v-else aria-label="Sort" class="padding0" @click.prevent="toggle('team')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </button>
                                            </th>
                                            <th class="width112px">
                                                Reporter
                                                <template v-if="sort === 'reporter'">
                                                    <button
                                                        v-if="order === 'asc'"
                                                        aria-label="Sort Ascending"
                                                        class="padding0"
                                                        @click.prevent="toggle('reporter')"
                                                    >
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </button>
                                                    <button v-else aria-label="Sort Descending" class="padding0" @click.prevent="toggle('reporter')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </button>
                                                </template>
                                                <button v-else aria-label="Sort" class="padding0" @click.prevent="toggle('reporter')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </button>
                                            </th>
                                            <th>
                                                Status
                                                <template v-if="sort === 'status'">
                                                    <button
                                                        v-if="order === 'asc'"
                                                        aria-label="Sort Ascending"
                                                        class="padding0"
                                                        @click.prevent="toggle('status')"
                                                    >
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </button>
                                                    <button v-else aria-label="Sort Descending" class="padding0" @click.prevent="toggle('status')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </button>
                                                </template>
                                                <button v-else aria-label="Sort" class="padding0" @click.prevent="toggle('status')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </button>
                                            </th>
                                            <th v-if="testObj.others.isApplicationGraded">
                                                % Correct
                                                <template v-if="sort === 'finishedQuestionPercentCorrect'">
                                                    <button
                                                        v-if="order === 'asc'"
                                                        aria-label="Sort Ascending"
                                                        class="padding0"
                                                        @click.prevent="toggle('finishedQuestionPercentCorrect')"
                                                    >
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </button>
                                                    <button
                                                        v-else
                                                        aria-label="Sort Descending"
                                                        class="padding0"
                                                        @click.prevent="toggle('finishedQuestionPercentCorrect')"
                                                    >
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </button>
                                                </template>
                                                <button v-else aria-label="Sort" class="padding0" @click.prevent="toggle('finishedQuestionPercentCorrect')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </button>
                                            </th>
                                            <th v-if="testObj.others.isApplicationGraded">
                                                Score
                                                <template v-if="sort === 'totalPoint'">
                                                    <button
                                                        v-if="order === 'asc'"
                                                        aria-label="Sort Ascending"
                                                        class="padding0"
                                                        @click.prevent="toggle('totalPoint')"
                                                    >
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </button>
                                                    <button v-else aria-label="Sort Descending" class="padding0" @click.prevent="toggle('totalPoint')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </button>
                                                </template>
                                                <button v-else aria-label="Sort" class="padding0" @click.prevent="toggle('totalPoint')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </button>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(team, idx) in teams" :key="idx">
                                            <template v-if="team.members && team.members.length > 0">
                                                <td v-if="canClearAnswers">
                                                    <div class="dropdown">
                                                        <i
                                                            class="fas fa-grip-vertical pointer"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        />

                                                        <div class="dropdown-menu dropdown-menu-new capitalize" aria-labelledby="dropdownMenuButton">
                                                            <ul>
                                                                <li>
                                                                    <a
                                                                        v-tooltip="'Clear ' + team.team + ' Answers'"
                                                                        class="danger-state"
                                                                        href="#"
                                                                        :aria-label="'Clear ' + team.team + ' Answers'"
                                                                        @click.prevent="clearAnswers(team)"
                                                                    >
                                                                        <span> <i class="fa fa-trash-o marginRight8" aria-hidden="true" />Clear Answers </span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td :aria-label="'row ' + idx + 'team name ' + team.team" tabindex="0">
                                                    {{ team.team }}
                                                </td>

                                                <td>
                                                    <div class="flexOnly align-items">
                                                        <select @change="changeTeamLeader(idx, $event.target.value)">
                                                            <template v-for="(member, member_idx) in team.members" :key="member.uuid">
                                                                <option :value="member_idx" :selected="member.isReporter">
                                                                    {{ member.displayName }}
                                                                </option>
                                                            </template>
                                                        </select>

                                                        <i
                                                            v-if="
                                                                testObj.teamsNotAllowedToUpdateTeamLeader &&
                                                                testObj.teamsNotAllowedToUpdateTeamLeader.includes(team.uuid)
                                                            "
                                                            :aria-label="'unfreeze team reporter for ' + team.team"
                                                            class="fas fa-lock red"
                                                            style="margin-left: 10px"
                                                            aria-hidden="true"
                                                            tabindex="0"
                                                            @click="freezeUnfreezeTeamLeader(idx)"
                                                            @keyup.enter="freezeUnfreezeTeamLeader(idx)"
                                                        />

                                                        <i
                                                            v-else
                                                            :aria-label="'freeze team team report for ' + team.team"
                                                            class="fas fa-unlock green"
                                                            style="margin-left: 10px"
                                                            aria-hidden="true"
                                                            tabindex="0"
                                                            @click="freezeUnfreezeTeamLeader(idx)"
                                                            @keyup.enter="freezeUnfreezeTeamLeader(idx)"
                                                        />
                                                    </div>
                                                </td>

                                                <td tabindex="0" :aria-label="'row ' + idx + 'team status ' + team.status">
                                                    {{ team.status }}
                                                </td>

                                                <td
                                                    v-if="testObj.others.isApplicationGraded"
                                                    tabindex="0"
                                                    :aria-label="'row ' + idx + ' ' + team.finishedOnPercentCorrect + '% Correct'"
                                                >
                                                    {{ team.finishedQuestionPercentCorrect ? team.finishedQuestionPercentCorrect.toFixed(1) : '0.0' }}%
                                                </td>

                                                <td
                                                    v-if="testObj.others.isApplicationGraded"
                                                    tabindex="0"
                                                    :aria-label="'row ' + idx + ' score ' + team.totalPoint"
                                                >
                                                    {{ team.totalPoint }}
                                                </td>
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <scroll-box-shadow
                                class="new-right-table-application-team-analysis"
                                :options="{
                                    setScrollBar: true,
                                }"
                                @scroll-event="handleRightScrollEvent"
                            >
                                <div class="sticky-top-header">Questions</div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <template v-for="(question, idx) in questions" :key="'team-analysis-question-' + idx">
                                                <th tabindex="0">
                                                    <span class="buttonGroupInline">
                                                        <button
                                                            class="btn gradient btn-default"
                                                            :aria-label="'Question number ' + (idx + 1) + ' click to view the question'"
                                                            @click.prevent="openQuestionInfoModal(question)"
                                                        >
                                                            {{ idx + 1 }}.<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                                        </button>
                                                        <template v-for="(option, option_idx) in question.options">
                                                            <div
                                                                v-if="option.isCorrect"
                                                                :key="option_idx"
                                                                class="statusTag tagCorrect marginLeft10 whiteSpaceNowrap"
                                                                :aria-label="'correct option ' + option.key"
                                                            >
                                                                <span class="paddingTop2">
                                                                    {{ option.key }}
                                                                </span>
                                                                <i class="fa fa-check-circle marginLeft8" aria-hidden="true" />
                                                            </div>
                                                        </template>
                                                        <template v-for="newAnswerIdx in testObj.acceptedNewAnswers">
                                                            <template v-if="newAnswerIdx.activityQuestionId == question.id">
                                                                <div
                                                                    class="statusTag tagNewCorrect marginLeft10 whiteSpaceNowrap"
                                                                    :aria-label="'accepted options ' + newAns"
                                                                >
                                                                    <template v-for="(newAns, newAnsIdx) in newAnswerIdx.acceptedAnswers">
                                                                        <span class="paddingTop2">
                                                                            {{ newAns }}
                                                                            <template v-if="newAnsIdx != newAnswerIdx.acceptedAnswers.length - 1"
                                                                                >&nbsp;</template
                                                                            >
                                                                        </span>
                                                                    </template>
                                                                    <i class="fa fa-check-circle marginLeft8" aria-hidden="true" />
                                                                </div>
                                                            </template>
                                                        </template>
                                                    </span>
                                                </th>
                                            </template>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(team, idx) in teams" :key="'team-analysis-team-' + idx">
                                            <template v-if="team.members && team.members.length > 0">
                                                <template v-for="(question, question_idx) in team.questions">
                                                    <template v-if="question.attempts && question.attempts.length != 0">
                                                        <td :key="'team-analysis-question-' + question_idx" tabindex="0">
                                                            <template v-if="['openended'].includes(question.type)">
                                                                <div
                                                                    :id="team.uuid + '-' + question_idx + '-answer-wrapper'"
                                                                    class="openended-space wordBreakWord whiteSpacePreWrap"
                                                                >
                                                                    <div
                                                                        :id="team.uuid + '-' + question_idx + '-answer-content'"
                                                                        class="openended-space-content"
                                                                        v-dompurify-html="
                                                                            processOpenendedAnswer(question, question.attempts[question.attempts.length - 1])
                                                                        "
                                                                    />
                                                                </div>
                                                                <template v-if="question.attempts[question.attempts.length - 1]">
                                                                    <div
                                                                        class="marginTop10 blue pointer"
                                                                        @click="openOpenendedInfoModal(question.attempts[question.attempts.length - 1])"
                                                                    >
                                                                        View More
                                                                    </div>
                                                                </template>
                                                            </template>
                                                            <template v-else-if="['vsa'].includes(question.type)">
                                                                <div
                                                                    class="statusTag width250px heightAuto wordBreakWord"
                                                                    :class="{
                                                                        tagCorrect: question.percent == 100 && testObj.others.isApplicationGraded,
                                                                        tagIncorrect:
                                                                            question.percent == 0 && !question.isCorrect && testObj.others.isApplicationGraded,
                                                                    }"
                                                                >
                                                                    {{ question.attempts[question.attempts.length - 1] }}
                                                                </div>
                                                            </template>
                                                            <div v-else-if="['sequence', 'matching'].includes(question.type)" class="inlineFlex fs-24px">
                                                                <p class="visible-hidden">
                                                                    Answer is
                                                                    <template v-if="question.isCorrect"> correct! </template>
                                                                    <template v-else> incorrect! </template>
                                                                </p>
                                                                <i
                                                                    class="fa marginRight8"
                                                                    :class="{
                                                                        'fa-check-circle successColor': question.isCorrect,
                                                                        'fa-times-circle dangerColor': !question.isCorrect,
                                                                    }"
                                                                ></i>
                                                                <button
                                                                    v-tooltip="'View ' + team.team + ' answer for question ' + (question_idx + 1)"
                                                                    class="btn btn-default marginLeft0"
                                                                    :aria-label="'View ' + team.team + ' answer for question ' + (question_idx + 1)"
                                                                    @click.prevent="
                                                                        openQuestionInfoModal(
                                                                            questions[question_idx],
                                                                            question,
                                                                            team.team,
                                                                            testObj.others.applicationType
                                                                        )
                                                                    "
                                                                >
                                                                    <i class="fas fa-eye" aria-hidden="true" />
                                                                </button>
                                                            </div>
                                                            <div
                                                                v-else
                                                                class="statusTag whiteSpaceNowrap"
                                                                :class="{
                                                                    tagCorrect:
                                                                        (question.percent == 100 ||
                                                                            (question.isCorrect && !testObj.settings.confidenceBasedTesting)) &&
                                                                        testObj.others.isApplicationGraded,
                                                                    tagNewCorrect:
                                                                        optionCorrect(question) &&
                                                                        testObj.others.isApplicationGraded &&
                                                                        !testObj.settings.confidenceBasedTesting,
                                                                    tagPrimary:
                                                                        question.percent >= 50 && question.percent < 100 && testObj.others.isApplicationGraded,
                                                                    tagWarning:
                                                                        question.percent < 50 && question.percent > 0 && testObj.others.isApplicationGraded,
                                                                    tagIncorrect:
                                                                        question.percent == 0 && !question.isCorrect && testObj.others.isApplicationGraded,
                                                                }"
                                                            >
                                                                <template v-if="question.type == 'mcqs'">
                                                                    {{ question.attempts[question.attempts.length - 1] }}
                                                                </template>
                                                                <template v-else>
                                                                    {{ sortArray(question.attempts[question.attempts.length - 1]) }}
                                                                </template>
                                                            </div>
                                                        </td>
                                                    </template>
                                                    <template v-else>
                                                        <td :key="'team-analysis-question-2-' + question_idx" />
                                                    </template>
                                                </template>
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>
                            </scroll-box-shadow>
                        </div>

                        <div class="scrollable-table-wrapper flex">
                            <div class="new-left-table-scrollbar-application-team-analysis" @scroll="handleLeftScroll">
                                <div style="height: 0.1px" />
                            </div>
                            <div class="new-right-table-scrollbar-application-team-analysis" @scroll="handleRightScroll">
                                <div style="height: 0.1px" />
                            </div>
                        </div>
                    </div>

                    <div class="hidden-md hidden-lg table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        Team
                                        <template v-if="sort === 'team'">
                                            <button v-if="order === 'asc'" aria-label="Sort Ascending" class="padding0" @click.prevent="toggle('team')">
                                                <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                            </button>
                                            <button v-else aria-label="Sort Descending" class="padding0" @click.prevent="toggle('team')">
                                                <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                            </button>
                                        </template>
                                        <button v-else aria-label="Sort" class="padding0" @click.prevent="toggle('team')">
                                            <i class="fa fa-sort" aria-hidden="true" />
                                        </button>
                                    </th>
                                    <th>
                                        Team Reporter
                                        <template v-if="sort === 'reporter'">
                                            <button v-if="order === 'asc'" aria-label="Sort Ascending" class="padding0" @click.prevent="toggle('reporter')">
                                                <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                            </button>
                                            <button v-else aria-label="Sort Descending" class="padding0" @click.prevent="toggle('reporter')">
                                                <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                            </button>
                                        </template>
                                        <button v-else aria-label="Sort" class="padding0" @click.prevent="toggle('reporter')">
                                            <i class="fa fa-sort" aria-hidden="true" />
                                        </button>
                                    </th>
                                    <th>
                                        Status
                                        <template v-if="sort === 'status'">
                                            <button v-if="order === 'asc'" aria-label="Sort Ascending" class="padding0" @click.prevent="toggle('status')">
                                                <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                            </button>
                                            <button v-else aria-label="Sort Descending" class="padding0" @click.prevent="toggle('status')">
                                                <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                            </button>
                                        </template>
                                        <button v-else aria-label="Sort" class="padding0" @click.prevent="toggle('status')">
                                            <i class="fa fa-sort" aria-hidden="true" />
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(team, idx) in teams" :key="'team-analysis-rank-' + idx">
                                    <tr
                                        :class="{
                                            first: team.rank == 1,
                                            second: team.rank == 2,
                                            third: team.rank == 3,
                                        }"
                                    />
                                    <tr>
                                        <td>{{ team.team }}</td>
                                        <td>{{ team.reporter }}</td>
                                        <td>{{ team.status }}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <vcl-table v-else />
            </template>
        </kr-panel>

        <div id="teamAnalysisQuestionInfoModal" class="modal default-modal" tabindex="-1" role="dialog" aria-labelledby="teamAnalysisQuestionInfoModal-title">
            <div class="modal-dialog" :class="{ 'widthAuto maxWidth1000px': ['mcqs', 'mcqm'].includes(previewQuestion.type) }">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="teamAnalysisQuestionInfoModal-title" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true" />View
                            <template v-if="currentStudentName"> {{ currentStudentName }} Answer </template>
                            <template v-else> Question </template>
                        </h2>
                    </div>
                    <div class="modal-body">
                        <template v-if="['mcqs', 'mcqm', 'openended', 'vsa'].includes(previewQuestion.type) || !newQnsTypesViewStudentAnswers">
                            <label class="control-label"> Question {{ getQuestionNo(previewQuestion.group - 1, previewQuestion.order - 1) }} </label>
                            <question-display :test-obj="testObj" :question="previewQuestion" />
                        </template>

                        <div v-if="newQnsTypesViewStudentAnswers" class="marginBottom20 collapsingQuestion">
                            <button
                                class="btn"
                                :data-target="'#viewQuestion-' + getQuestionNo(previewQuestion.group - 1, previewQuestion.order - 1) + '-changeCorrectAnswer'"
                                data-toggle="collapse"
                                aria-expanded="false"
                                :aria-controls="'viewQuestion-' + getQuestionNo(previewQuestion.group - 1, previewQuestion.order - 1) + '-changeCorrectAnswer'"
                            >
                                Question
                                {{ getQuestionNo(previewQuestion.group - 1, previewQuestion.order - 1) }}
                            </button>

                            <div
                                :id="'viewQuestion-' + getQuestionNo(previewQuestion.group - 1, previewQuestion.order - 1) + '-changeCorrectAnswer'"
                                class="collapse padding10"
                            >
                                <div class="question">
                                    <question-display :test-obj="testObj" :question="previewQuestion" />
                                </div>
                            </div>
                        </div>

                        <div v-if="['mcqs', 'mcqm'].includes(previewQuestion.type)" class="mcqStatisticsBarChart marginTop20">
                            <div class="panel-header">
                                <div>Answer Choices</div>
                            </div>
                            <div>
                                <table class="barChart">
                                    <tbody class="body">
                                        <template v-for="(option, option_idx) in previewQuestion.optionKeys">
                                            <tr>
                                                <div class="percentage" :style="'bottom:' + (option.percent.toFixed(0) / 100) * 80 + '%'">
                                                    {{ round(option.percent) }}%
                                                </div>
                                                <td
                                                    class="bars"
                                                    :class="{
                                                        correct: option.isCorrect,
                                                        wrong: !option.isCorrect,
                                                    }"
                                                    :style="'height:' + (option.percent.toFixed(0) / 100) * 80 + '%;'"
                                                />
                                                <td class="record">
                                                    <div>Choice {{ option.key }}</div>
                                                    <template v-for="(model, model_idx) in option.answersPerOption">
                                                        <template v-if="model.team">
                                                            <div
                                                                v-tooltip="model.team.name"
                                                                class="btn btnDiscussionTeams marginTop10 cursor-norm"
                                                                tabindex="0"
                                                            >
                                                                <i class="fa fa-user marginRight8" aria-hidden="true" />
                                                                <label>{{ model.team.name }}</label>
                                                            </div>
                                                        </template>
                                                    </template>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <fieldset v-if="newQnsTypesViewStudentAnswers && previewQuestion.type == 'sequence'">
                            <legend>{{ currentStudentName }} Sequence Order</legend>
                            <template v-for="(option, option_idx) in previewQuestion.options">
                                <div class="sequencing-div-instructor" :class="{ displayGrid: !isMobileView }">
                                    <div :class="{ 'grid-order-2': !isMobileView }">
                                        <p :id="'sequence-instructor-' + (option_idx + 1)">
                                            {{ previewQuestion.options[option_idx].content }}
                                        </p>
                                    </div>

                                    <div :class="{ 'grid-order-1': !isMobileView, marginTop20: isMobileView }">
                                        <label
                                            v-if="currentStudentName"
                                            :for="'sequence-instructor-' + (option_idx + 1)"
                                            :class="{
                                                correct:
                                                    findIndexKey(
                                                        previewQuestion.options[option_idx].key,
                                                        studentAnswer.attempts[studentAnswer.attempts.length - 1]
                                                    ) == previewQuestion.options[option_idx].order,
                                                incorrect:
                                                    findIndexKey(
                                                        previewQuestion.options[option_idx].key,
                                                        studentAnswer.attempts[studentAnswer.attempts.length - 1]
                                                    ) != previewQuestion.options[option_idx].order,
                                            }"
                                        >
                                            <span> Order </span>
                                            <br v-if="!isMobileView" />
                                            {{
                                                findIndexKey(previewQuestion.options[option_idx].key, studentAnswer.attempts[studentAnswer.attempts.length - 1])
                                            }}
                                        </label>
                                        <label v-else :for="'sequence-instructor-' + (option_idx + 1)" class="correct">
                                            <span> Order </span>
                                            <br v-if="!isMobileView" />
                                            {{ previewQuestion.options[option_idx].content }}
                                        </label>
                                    </div>
                                </div>
                            </template>
                        </fieldset>

                        <fieldset v-if="newQnsTypesViewStudentAnswers && previewQuestion.type == 'matching'">
                            <legend>
                                Matching
                                <template v-if="previewQuestion.optionKeys.matchingType == 'one-to-one'"> (one-to-one) </template>
                                <template v-else> (one-to-many) </template>
                            </legend>

                            <template v-for="(match, matchIdx) in previewQuestion.options.matches">
                                <div class="sequencing-div-instructor">
                                    <div>
                                        <label class="control-label capitalize"> {{ previewQuestion.options.labels.prompt }} {{ matchIdx + 1 }} </label>

                                        <p>
                                            {{ match.prompt.content }}
                                        </p>
                                    </div>

                                    <dl class="marginTop20 marginBottom0">
                                        <dt class="control-label capitalize">
                                            {{ previewQuestion.options.labels.answer }} for {{ previewQuestion.options.labels.prompt }} {{ matchIdx + 1 }}
                                        </dt>
                                        <dd
                                            v-for="(answer, answerIdx) in studentAnswer.attempts[studentAnswer.attempts.length - 1][matchIdx].answer"
                                            class="flexOnly categorize-option"
                                            :class="{
                                                'categorize-correct': findStatus(
                                                    previewQuestion.options.matches,
                                                    studentAnswer.attempts[studentAnswer.attempts.length - 1][matchIdx].prompt,
                                                    answer
                                                ),
                                                'categorize-incorrect': !findStatus(
                                                    previewQuestion.options.matches,
                                                    studentAnswer.attempts[studentAnswer.attempts.length - 1][matchIdx].prompt,
                                                    answer
                                                ),
                                            }"
                                        >
                                            <i
                                                class="fa marginRight8 paddingTop2"
                                                :class="{
                                                    'fa-check-circle': findStatus(
                                                        previewQuestion.options.matches,
                                                        studentAnswer.attempts[studentAnswer.attempts.length - 1][matchIdx].prompt,
                                                        answer
                                                    ),
                                                    'fa-times-circle': !findStatus(
                                                        previewQuestion.options.matches,
                                                        studentAnswer.attempts[studentAnswer.attempts.length - 1][matchIdx].prompt,
                                                        answer
                                                    ),
                                                }"
                                                aria-hidden="true"
                                            />
                                            <p :for="'matching-one-to-one-instructor-answer-' + (matchIdx + 1) + '-' + (answerIdx + 1)">
                                                {{ findValue(previewQuestion.options.matches, answer) }}
                                            </p>
                                        </dd>
                                    </dl>
                                </div>
                            </template>
                        </fieldset>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="teamAnalysisOpenendedInfoModal"
            class="modal"
            role="dialog"
            style="z-index: 50001 !important"
            aria-labelledby="teamAnalysisOpenendedInfoModal-title"
        >
            <div class="modal-dialog maxWidth80 widthAuto">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="teamAnalysisOpenendedInfoModal-title" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true" />View Answer
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 wordBreakWord whiteSpacePreWrap imgMax100" v-dompurify-html="openended" />
                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="teamAnalysisClearAnswersModal"
            class="modal danger-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="clearAnswersModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="clearAnswersModal-title" class="modal-title">Clear Answers</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                        <h2 class="margin0 marginTop40">Clear Answers For</h2>
                        <h2 class="fw-normal">
                            {{ teamToClear.team }}
                        </h2>
                        <br />
                        <p>You are about to clear all</p>
                        <p>answers that were submitted</p>
                        <p>
                            by <b>{{ teamToClear.team }}</b> for this activity.
                        </p>

                        <br />

                        <p>Do you want to proceed?</p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="clear()">Yes, Clear</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { VclTable } from 'vue-content-loading';
import { testMixins } from '../../../../../../../../mixins/test';

export default {
    mixins: [testMixins],
    props: ['testObj'],
    data() {
        return {
            component_done_loading: false,
            questions: [],
            previewQuestion: {},
            teamAnalysis: null,
            teams: [],
            order: 'asc',
            sort: 'rank',
            debouncedUpdate: null,
            openended: '',
            lastUpdateTimestamp: '',
            initialized: false,
            teamToClear: {},
            studentAnswer: {},
            currentStudentName: '',
        };
    },
    created() {
        var that = this;
        this.debouncedUpdate = _.debounce(
            function (data) {
                this.fetchTest().then(function (response) {
                    if (!that.lastUpdateTimestamp) {
                        for (var i = 0; i < response.data.data.teamAnalysis.teams.length; i++) {
                            response.data.data.teamAnalysis.teams[i].rank = i + 1;
                        }
                    }
                    that.lastUpdateTimestamp = moment.utc(response.headers['date'], 'ddd, DD MMM YYYY HH:mm:ss [GMT]', true).format('YYYY-MM-DD HH:mm:ss');

                    if (that.teamAnalysis == null) {
                        that.teamAnalysis = response.data.data.teamAnalysis;
                    } else {
                        that.teamAnalysis.teams = that.mergeArrayOfObjectsWithKey(that.teamAnalysis.teams, response.data.data.teamAnalysis.teams, 'id');
                        /*Vue.set(
                            that.teamAnalysis,
                            'teams',
                            that.mergeArrayOfObjectsWithKey(that.teamAnalysis.teams, response.data.data.teamAnalysis.teams, 'id')
                        );*/
                        //that.teamAnalysis = _.cloneDeep(response.data.data.teamAnalysis);
                    }
                    that.init();
                    if (!that.component_done_loading) {
                        that.component_done_loading = true;
                        that.$nextTick(function () {
                            $('#teamAnalysisQuestionInfoModal .modal-dialog').draggable({
                                handle: '.modal-header',
                            });
                            that.$forceUpdate();
                        });
                    }
                });
            },
            1000,
            { maxWait: 3000 }
        );
        this.debouncedUpdate();
        this.processEchoListeners();
    },
    mounted() {
        var that = this;

        this.$root.$on('fontResized', () => {
            that.alignTable();
        });
    },
    updated() {
        this.alignTable();
    },
    methods: {
        processEchoListeners() {
            var that = this;

            let h1 = (e) => {
                if (e.testUuid != that.testObj.uuid) {
                    return false;
                }
                that.debouncedUpdate();
            };
            let c1 = window.Echo.private(`test.${this.testObj.uuid}.teacher`).listen('TeamAnswerSaved', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TeamAnswerSaved',
                handler: h1,
            });

            let h2 = (e) => {
                if (e.testUuid != that.testObj.uuid) {
                    return false;
                }
                that.debouncedUpdate();
            };
            let c2 = window.Echo.private(`course.${this.testObj.course.uuid}.teacher`).listen('StudentTestStatusUpdated', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'StudentTestStatusUpdated',
                handler: h2,
            });
        },
        processOpenendedAnswer(question, answer) {
            let a = answer;
            if (question.type == 'vsa') {
                if (question.percent == 100 && this.testObj.others.isApplicationGraded) {
                    a = '<i class="fa fa-check-circle successColor" aria-hidden="true"></i> ' + a;
                } else if (question.percent == 0 && this.testObj.others.isApplicationGraded) {
                    a = '<i class="fa fa-times-circle dangerColor" aria-hidden="true"></i> ' + a;
                }
            }
            return a;
        },
        freezeUnfreezeTeamLeader(team_idx) {
            var that = this;
            let isFreeze = false;
            let teamsNotAllowedToUpdateTeamLeader = _.cloneDeep(this.testObj.teamsNotAllowedToUpdateTeamLeader);
            if (!this.testObj.teamsNotAllowedToUpdateTeamLeader.includes(this.teamAnalysis.teams[team_idx].uuid)) {
                isFreeze = true;
                teamsNotAllowedToUpdateTeamLeader.push(this.teamAnalysis.teams[team_idx].uuid);
            } else {
                teamsNotAllowedToUpdateTeamLeader.pop(this.teamAnalysis.teams[team_idx].uuid);
            }
            axios
                .post(`tests/${this.testObj.uuid}/allow-update-team-leader`, {
                    teamsNotAllowedToUpdateTeamLeader: teamsNotAllowedToUpdateTeamLeader,
                })
                .then(function (response) {
                    that.testObj.teamsNotAllowedToUpdateTeamLeader = teamsNotAllowedToUpdateTeamLeader;
                    that.testObj.teamsNotAllowedToUpdateTeamLeader.splice();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Successfully ' + (isFreeze ? 'frozen' : 'unfrozen') + ' team',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'danger',
                        title: 'Error',
                        text: 'Something went wrong. Please try again later',
                    });
                });
        },
        changeTeamLeader(team_idx, student_idx) {
            var that = this;
            axios
                .post(`/tests/${this.testObj.uuid}/team-leader`, {
                    userPlacementTestUuid: this.teamAnalysis.teams[team_idx].members[student_idx].studentUuid,
                })
                .then(function (response) {
                    for (var i = 0; i < that.teamAnalysis.teams[team_idx].members.length; i++) {
                        if (i != student_idx) {
                            that.teamAnalysis.teams[team_idx].members[i].isReporter = false;
                        } else {
                            that.teamAnalysis.teams[team_idx].members[i].isReporter = true;
                        }
                    }

                    that.teamAnalysis.teams.splice();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Team leader sucessfully changed',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'danger',
                        title: 'Error',
                        text: 'Something went wrong. Please try again later',
                    });
                });
        },
        recomputeRank() {
            if (this.testObj.others.isApplicationGraded && this.initialized) {
                this.teamAnalysis.teams = _.sortBy(this.teamAnalysis.teams, function (o) {
                    let parsed = parseFloat(o.finishedQuestionPercentCorrect);
                    if (Number.isNaN(parsed)) {
                        parsed = 0;
                    }
                    return parsed;
                }).reverse();
            }
            for (var i = 0; i < this.teamAnalysis.teams.length; i++) {
                if (this.testObj.others.isApplicationGraded || this.teamAnalysis.teams[i].rank == null) {
                    this.teamAnalysis.teams[i].rank = i + 1;
                    //Vue.set(this.teamAnalysis.teams[i], 'rank', i + 1);
                }
            }
            this.initialized = true;
        },
        sortArray(array) {
            return _.sortBy(array);
        },
        fetchTest() {
            return axios.get(
                '/tests/' + this.$route.params.id + '/dashboard/students' + (this.lastUpdateTimestamp != '' ? `?timestamp=${this.lastUpdateTimestamp}` : ''),
                { _internal: { hasLoadingBar: false } }
            );
        },
        init() {
            var that = this;
            if (_.isEmpty(this.teamAnalysis)) {
                this.teamAnalysis = { teams: [] };
            }
            if (_.isEmpty(this.questions)) {
                this.questions = _.flatten(this.testObj.questions);
            }
            this.teams = _.clone(this.teamAnalysis.teams);
            for (var i = 0; i < this.teams.length; i++) {
                if (this.teams[i].finishedQuestionPercentCorrect == null) {
                    this.teams[i].finishedQuestionPercentCorrect = 0;
                }
            }
            _.forOwn(that.testObj.attendance.groupedStudents, function (value, key) {
                let idx = _.findIndex(that.teamAnalysis.teams, function (o) {
                    return value.uuid == o.uuid;
                });
                if (idx != -1) {
                    that.teamAnalysis.teams[idx].members = value.members;
                }
            });
            this.sortList();

            var that = this;
            Vue.nextTick(function () {
                that.alignTable();
            });
        },
        sortList() {
            let sort = this.sort;
            if (sort != 'team') {
                this.teams = _.orderBy(this.teams, [sort], [this.order]);
            } else {
                this.natSort(this.teams, 'team');
                if (this.order == 'desc') {
                    _.reverse(this.teams);
                }
            }

            var that = this;
            Vue.nextTick(function () {
                that.alignTable();
            });
        },
        toggle(column = '') {
            if (this.sort == column) {
                if (this.order == 'asc') {
                    this.order = 'desc';
                } else {
                    this.order = 'asc';
                }
            } else {
                this.order = 'asc';
                this.sort = column;
            }
            this.sortList();
        },
        openQuestionInfoModal(question, thisQns, name, type) {
            this.previewQuestion = question;

            if (type != '') {
                this.studentAnswer = thisQns;
                this.currentStudentName = name;
            }

            $('#teamAnalysisQuestionInfoModal').modal('show');
        },
        openOpenendedInfoModal(str) {
            this.openended = str;
            $('#teamAnalysisOpenendedInfoModal').modal('show');
        },
        alignTable() {
            var $widthsLeft = [];
            var countLeft = -1;
            $('.new-left-table-application-team-analysis thead tr th').each(function () {
                $widthsLeft.push($(this).width() + 31);
                countLeft++;
            });

            let iLeft = 0;
            if ($widthsLeft.length != 0) {
                $('.new-left-table-application-team-analysis tbody tr td').each(function () {
                    if (countLeft != iLeft) {
                        $(this).css('width', $widthsLeft[iLeft]);
                        iLeft++;
                    } else {
                        $(this).css('width', $widthsLeft[iLeft] - 2);
                        iLeft++;
                    }
                });
            }

            // this is a group
            var $widthsRight = [];
            var countRight = -1;
            $('.new-right-table-application-team-analysis thead tr th').each(function () {
                $widthsRight.push($(this).width() + 31);
                countRight++;
            });

            var $rightDivWidths = [];
            $('.new-right-table-application-team-analysis tbody tr td').each(function () {
                $rightDivWidths.push($(this).width() + 31);
            });
            var $accumulatedWidths = [];

            for (var m = 0; m < $widthsRight.length; m++) {
                if ($widthsRight[m] >= $rightDivWidths[m]) {
                    $accumulatedWidths.push($widthsRight[m]);
                } else {
                    $accumulatedWidths.push($rightDivWidths[m]);
                }
            }

            let i = 0;
            if ($accumulatedWidths.length != 0) {
                $('.new-right-table-application-team-analysis tbody tr td').each(function () {
                    $(this).css('min-width', $accumulatedWidths[i++]);
                });
            }

            let x = 0;
            if ($accumulatedWidths.length != 0) {
                $('.new-right-table-application-team-analysis thead tr th').each(function () {
                    $(this).css('min-width', $accumulatedWidths[x++]);
                });
            }
            // ^

            // this is a group
            var $heights = [];
            var counts = -1;
            $('.new-left-table-application-team-analysis tbody tr').each(function () {
                $heights.push($(this).height());
                counts++;
            });

            var $rightDivheights = [];
            $('.new-right-table-application-team-analysis tbody tr').each(function () {
                $rightDivheights.push($(this).height());
            });

            var $accumulatedHeight = [];

            for (var k = 0; k < $heights.length; k++) {
                if ($heights[k] > $rightDivheights[k]) {
                    $accumulatedHeight.push($heights[k]);
                } else {
                    if ($rightDivheights[k] == 81) {
                        $accumulatedHeight.push($rightDivheights[k] + 29);
                    } else {
                        $accumulatedHeight.push($rightDivheights[k]);
                    }
                }
            }

            let j = 0;
            if ($accumulatedHeight.length != 0) {
                $('.new-left-table-application-team-analysis tbody tr').each(function () {
                    $(this).css('height', $accumulatedHeight[j++]);
                });
            }

            let y = 0;
            if ($accumulatedHeight.length != 0) {
                $('.new-right-table-application-team-analysis tbody tr').each(function () {
                    $(this).css('height', $accumulatedHeight[y++]);
                });
            }
            // ^

            // this group for header height
            var $LeftTheadHeight = $('.new-left-table-application-team-analysis thead tr').height();
            var $RightTheadHeight = $('.new-right-table-application-team-analysis thead tr').height();

            if ($LeftTheadHeight > $RightTheadHeight) {
                $('.new-right-table-application-team-analysis thead tr').css('height', $LeftTheadHeight);
            } else {
                $('.new-left-table-application-team-analysis thead tr').css('height', $RightTheadHeight);
            }

            // ^

            $('.new-right-table-application-team-analysis div').scroll(function () {
                $('.new-left-table-application-team-analysis').scrollTop($(this).scrollTop());
            });

            $('.new-left-table-application-team-analysis').scroll(function () {
                $('.new-right-table-application-team-analysis div').scrollTop($(this).scrollTop());
            });

            var widthOfTheLeftTable = $('.new-left-table-application-team-analysis table').width();
            $('.new-left-table-scrollbar-application-team-analysis div').width(widthOfTheLeftTable + 'px');

            var widthOfTheRightTable = $('.new-right-table-application-team-analysis table').width();
            $('.new-right-table-scrollbar-application-team-analysis div').width(widthOfTheRightTable + 'px');
        },
        truncate(str, characters = 100) {
            return _.truncate(str, {
                length: characters,
                omission: '',
            });
        },
        clearAnswers(team = {}) {
            this.teamToClear = team;
            $('#teamAnalysisClearAnswersModal').modal('show');
        },
        clear() {
            var that = this;
            let data = {};
            data.teamUuid = this.teamToClear.uuid;
            axios
                .post(`tests/${this.testObj.uuid}/reset-answers`, data)
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Answers have been cleared',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'danger',
                        title: 'Error',
                        text: 'Something went wrong, please try again later',
                    });
                });
        },
        handleLeftScroll(event) {
            $('.new-left-table-application-team-analysis').scrollLeft(event.target.scrollLeft);
            $('.new-left-table-scrollbar-application-team-analysis').scrollLeft(event.target.scrollLeft);
        },
        handleRightScroll(event) {
            $('.new-right-table-application-team-analysis div').scrollLeft(event.target.scrollLeft);
            $('.new-right-table-scrollbar-application-team-analysis').scrollLeft(event.target.scrollLeft);
        },
        handleRightScrollEvent(scrollLeft) {
            $('.new-right-table-scrollbar-application-team-analysis').scrollLeft(scrollLeft);
        },
        optionCorrect(question) {
            if (['ended', 'completed'].includes(this.testObj.status) && question.type == 'mcqs') {
                for (var i = 0; i < this.testObj.acceptedNewAnswers.length; i++) {
                    if (this.testObj.acceptedNewAnswers[i].activityQuestionId == question.id) {
                        return this.testObj.acceptedNewAnswers[i].acceptedAnswers.some((value) => question.attempts.includes(value));
                    }
                }
            }
        },
        findIndexKey(optionsKey, answers) {
            let idx = _.findIndex(answers, function (o) {
                return o == optionsKey;
            });

            return idx + 1;
        },
        findValue(matchesArray, answerKey) {
            for (var i = 0; i < matchesArray.length; i++) {
                for (var j = 0; j < matchesArray[i].answer.length; j++) {
                    if (matchesArray[i].answer[j].key == answerKey) {
                        return matchesArray[i].answer[j].content;
                    }
                }
            }
        },
        findStatus(matchesArray, promptKey, ansKey) {
            let idx = _.findIndex(matchesArray, function (o) {
                return o.prompt.key == promptKey;
            });

            let arr = [];
            matchesArray[idx].answer.forEach((key) => {
                arr.push(key.key);
            });

            return arr.includes(ansKey);
        },
    },
    components: {
        'question-display': require(`./../../../../../../questions/partials/question-display.vue`).default,
        VclTable,
    },
    computed: {
        canClearAnswers() {
            if (['scheduled', 'not started'].includes(this.testObj.status)) {
                return false;
            } else {
                return true;
            }
        },
        newQnsTypesViewStudentAnswers() {
            if (['sequence', 'matching'].includes(this.previewQuestion.type) && this.studentAnswer != undefined) {
                return true;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
i.fa-trophy {
    display: inline-block;
    border-radius: 60px;
    padding: 0.5em;
    color: #fff;
    font-size: 8px;
}

.studentAnalysis .first {
    background-color: #fdfbf4;
}

.first i.fa-trophy {
    box-shadow: 0px 0px 2px #dab025;
    background: #facd36;
    border: 1px solid #dab025;
}

.studentAnalysis .second {
    background-color: #fbfbfb;
}

.second i.fa-trophy {
    box-shadow: 0px 0px 2px #a3a3a3;
    background: #dadada;
    border: 1px solid #a3a3a3;
}

.studentAnalysis .third {
    background-color: #fdf8f3;
}

.third i.fa-trophy {
    box-shadow: 0px 0px 2px #9c4317;
    background: #c6937a;
    border: 1px solid #9c4317;
}

.fixedHeight69px {
    height: 69px;
}

.openended-space {
    width: 250px;
    height: 50px;
    overflow: hidden;
}

.tablewDiv .table {
    margin-bottom: 0;
    position: relative;
}

.tablewDiv .tableDiv-app {
    overflow-x: auto;
    height: max-content;
}
.tablewDiv .tableDiv-app .table {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
}

.tablewDiv .tableDiv-app1 .table {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 2px 0 3px 0 rgba(174, 174, 174, 0.5);
}

.tableDiv-app1 {
    min-width: 618px;
    width: 618px;
}

.tableDiv-app {
    width: calc(100% - 618px);
}

.tableDiv-app1 .table > thead > tr > th,
.tableDiv-app1 .table > thead > tr > td,
.tableDiv-app1 .table > tbody > tr > th,
.tableDiv-app1 .table > tbody > tr > td,
.tableDiv-app1 .table > tfoot > tr > th,
.tableDiv-app1 .table > tfoot > tr > th,
.tableDiv-app .table > thead > tr > th,
.tableDiv-app .table > thead > tr > td,
.tableDiv-app .table > tbody > tr > th,
.tableDiv-app .table > tbody > tr > td,
.tableDiv-app .table > tfoot > tr > th,
.tableDiv-app .table > tfoot > tr > th {
    padding: 15px;
}

thead tr.sticky th {
    position: sticky;
    top: 0;
    z-index: 30;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
}

table {
    border-top: 0;
}

#header-fixed,
#teamAnalysisApp-clone-left-fixed {
    position: relative;
    top: 0px;
    display: none;
    background-color: white;
    z-index: 31;
    overflow-x: auto;
    border: none;
}

#teamAnalysisApp-clone-left-fixed {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-left: 0;
}

#teamAnalysisApp-clone-left {
    border-top-left-radius: 0;
    border-left: 0;
    border-bottom: 0;
    box-shadow: none;
}

#teamAnalysisApp-clone-right {
    border-right: 0;
    border-bottom: 0;
    box-shadow: none;
}

#teamAnalysisAppOverall {
    border: 1px solid #ddd;
    border-radius: 3px;
}

#teamAnalysisApp-tableOG::-webkit-scrollbar {
    width: 0px;
    height: 0;
    background: transparent;
}

.width112px {
    width: 112px;
}

.nameColumn {
    word-wrap: break-word;
    max-width: 112px;
}

/* new table */

.new-table-container-analysis-team {
    table {
        border: 0;

        thead {
            position: sticky;
            top: 52.84px;

            th {
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
            }

            &:first-child {
                & > tr:first-child th {
                    border-top: inherit;
                }
            }
        }

        tbody {
            tr:first-child {
                td {
                    border-top: 0;
                }
            }
        }
    }

    .sticky-top-header {
        position: sticky;
        top: 0;
        left: 0;
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    }

    .new-left-table-application-team-analysis {
        min-width: 630px;
        width: 630px;
        max-height: 500px;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .new-right-table-application-team-analysis {
        width: calc(100% - 630px);
        max-height: 500px;
        scrollbar-width: none;

        .sticky-top-header {
            left: 0;
        }
    }

    .new-left-table-scrollbar-application-team-analysis {
        width: 630px;
    }

    .new-right-table-scrollbar-application-team-analysis {
        width: calc(100% - 630px);
    }

    .scrollable-table-wrapper {
        border-radius: 3px;
        overflow: hidden;

        &:first-child,
        &:last-child {
            .new-left-table-scrollbar-application-team-analysis,
            .new-right-table-scrollbar-application-team-analysis {
                border: 0;
            }
        }
        & > div {
            border: 1px solid #ddd;
            overflow: auto;

            &:not(:first-child) {
                border-left: 0;
            }
        }
    }
}

select {
    width: 80px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select {
        padding-right: 0;
    }
}
</style>
