<template>
    <div :id="props.question.uuid + '-question'" class="evaluation-panel-content">
        <div>
            <div class="flexSpaceBetween">
                <h4 class="fs-18px">
                    <b>
                        <template v-if="['ratingV2', 'openendedV2'].includes(props.question.type)">
                            Question {{ currentStemIdx + 1 }} of {{ stemKeys.length }}
                        </template>
                        <template v-else> Question 1 </template>
                    </b>
                </h4>

                <nav class="newPagination" aria-label="Pagination">
                    <ul class="inlineFlex">
                        <li>
                            <template v-if="canGoToPreviousStem || canGoToPreviousCriteria ? undefined : true">
                                <a href="#" class="disabled" v-tooltip="'Previous Question Disabled'" aria-label="Previous Question Disabled">
                                    <i class="fas fa-arrow-left-long" aria-hidden="true" />
                                </a>
                            </template>
                            <template v-else>
                                <a
                                    href="#"
                                    class="link borderRadius8"
                                    v-tooltip="'Previous Question'"
                                    aria-label="'Previous Question'"
                                    @click.prevent="goToPrevious"
                                >
                                    <i class="fas fa-arrow-left-long" aria-hidden="true" />
                                </a>
                            </template>
                        </li>

                        <li>
                            <template v-if="canGoToNextStem || canGoToNextCriteria ? undefined : true">
                                <a href="#" class="disabled" v-tooltip="'Next Question Disabled'" aria-label="Next Question Disabled">
                                    <i class="fas fa-arrow-right-long" aria-hidden="true" />
                                </a>
                            </template>
                            <template v-else>
                                <a href="#" class="link borderRadius8" v-tooltip="'Next Question'" aria-label="Next Question" @click.prevent="goToNext">
                                    <i class="fas fa-arrow-right-long" aria-hidden="true" />
                                </a>
                            </template>
                        </li>
                    </ul>
                </nav>
            </div>

            <div class="tableStatusTag">
                <p>
                    <span class="visible-hidden">Question type :&nbsp;</span>
                    <b>
                        <template v-if="props.question.type == 'ratingV2'"> Rating </template>
                        <template v-else-if="props.question.type == 'openendedV2'"> Open-ended </template>
                        <template v-else> Point Distribution </template>
                    </b>
                </p>
            </div>

            <div v-if="['ratingV2', 'openendedV2'].includes(props.question.type)">
                <h5 class="margin0 fs-18px">
                    <b>Question Stem</b>
                </h5>
                <div v-if="stem" class="flex">
                    <label class="fs-18px fw-normal" v-dompurify-html="stem.stem" />
                    <template v-if="stem.isRequired">
                        <span class="visible-hidden">Required</span>
                        <span class="important" aria-hidden="true">*</span>
                    </template>
                </div>
            </div>
            <div v-if="['pointDistribution'].includes(props.question.type)">
                <h5 class="margin0 fs-18px">
                    <b>Question Stem</b>
                </h5>
                <p v-dompurify-html="props.question.question.name" />
            </div>
        </div>
        <template v-if="props.question.type == 'ratingV2'">
            <ratingV2
                v-model="answer"
                :test-obj="testObj"
                :question="props.question"
                :current-stem-idx="currentStemIdx"
                :disabled="!canAnswer ? true : undefined"
            ></ratingV2>
        </template>
        <template v-if="props.question.type == 'openendedV2'">
            <openendedV2 v-model="answer" :test-obj="testObj" :question="question" :disabled="!canAnswer ? true : undefined"></openendedV2>
        </template>
        <template v-if="props.question.type == 'pointDistribution'">
            <pointDistribution
                ref="pointDistributionForm"
                v-model="answer"
                :test-obj="testObj"
                :question="question"
                :disabled="!canAnswer ? true : undefined"
            ></pointDistribution>
        </template>
        <div v-if="!isTestCompleted" class="padding16">
            <button class="btn btn-success" :disabled="!canSubmitAnswer || formIsBusy ? true : undefined" @click.prevent="submit">
                <i class="far fa-save marginRight8" aria-hidden="true"></i>Save Answer
            </button>
        </div>
    </div>
</template>
<script>
export default {
    compatConfig: {
        MODE: 3,
    },
};
</script>
<script setup>
import { notify } from '@kyvg/vue3-notification';
import ratingV2 from './partials/ratingV2.vue';
import openendedV2 from './partials/openendedV2.vue';
import pointDistribution from './partials/pointDistribution.vue';
import { computed, ref, watch, onBeforeMount, nextTick, onMounted, toRefs } from 'vue';
import useTest from '../../../../../../../../src/composables/studentTest/test.ts';
const props = defineProps({
    testObj: {
        type: Object,
        default: () => ({}),
    },
    testSettings: {
        type: Object,
        default: () => ({}),
    },
    question: {
        type: Object,
        default: () => ({}),
    },
    teams: {
        type: Object,
        default: () => ({}),
    },
    modelModifiers: { default: () => ({}) },
});
const { isTestCompleted } = useTest(props.testObj);
const stemKeys = ref([]);
var answer = ref(null);
var pointDistributionForm = ref(null);
var currentStemIdx = ref(0);
const formIsBusy = ref(false);
var questionGroupKeys = ref(0);
var currentQuestionGroupIdx = ref(0);

onBeforeMount(() => {
    init();
});
const init = () => {
    nextTick(() => {
        questionGroupKeys.value = Object.keys(props.testObj.questions);
        currentQuestionGroupIdx.value = props.testSettings.currentQuestionGroupIdx;
        currentStemIdx.value = props.testSettings.currentQuestionStemIdx;
        stemKeys.value = props.question.type != 'pointDistribution' ? Object.keys(props.question.questionStems) : 0;
        answer.value = getAnswer();
    });
};

const goToNext = () => {
    if (canGoToNextStem.value) {
        props.testSettings.currentQuestionStemIdx++;
    } else if (canGoToNextCriteria.value) {
        let nextQuestion = getNextQuestion();
        let nextQuestionIdx = questionGroupKeys.value.indexOf(nextQuestion.key);
        props.testSettings.currentQuestionGroupIdx = nextQuestionIdx;
        props.testSettings.currentQuestionGroupUuid = props.testObj.questions[nextQuestion.key][0].uuid;
        props.testSettings.currentStudentIdx = nextQuestion.studentIdx;
        props.testSettings.currentQuestionStemIdx = 0;
    }
    answer.value = getAnswer();
};

const goToPrevious = () => {
    if (canGoToPreviousStem.value) {
        props.testSettings.currentQuestionStemIdx--;
    } else if (canGoToPreviousCriteria.value) {
        let previousQuestion = getPreviousQuestion();
        let previousQuestionIdx = questionGroupKeys.value.indexOf(previousQuestion.key);
        props.testSettings.currentQuestionGroupIdx = previousQuestionIdx;
        props.testSettings.currentQuestionGroupUuid = props.testObj.questions[previousQuestion.key][0].uuid;
        props.testSettings.currentStudentIdx = previousQuestion.studentIdx;
        props.testSettings.currentQuestionStemIdx = 0;
        if (props.testObj.questions[previousQuestion.key][0].type != 'pointDistribution') {
            let stemLength = Object.keys(props.testObj.questions[previousQuestion.key][0].questionStems).length;
            props.testSettings.currentQuestionStemIdx = stemLength - 1;
        }
    }
    answer.value = getAnswer();
};
const submit = () => {
    if (props.question.type != 'pointDistribution' && !Array.isArray(_.get(stem.value, 'studentAnswer', false))) {
        stem.value.studentAnswer = [];
    }
    if (props.testSettings.isPreview) {
        if (props.question.type != 'pointDistribution') {
            stem.value.studentAnswer.push({
                receiverId: props.testSettings.currentStudentIdx != null ? props.testObj.team[props.testSettings.currentStudentIdx].id : null,
                answer: answer.value,
            });
            if (currentStemIdx.value < stemKeys.value.length - 1) {
                currentStemIdx.value++;
                answer.value = getAnswer();
            }
        } else {
            props.question.pointDistributions = answer.value;
        }

        notify({
            group: 'form',
            type: 'success',
            title: 'Success',
            text: 'Answer successfully saved',
        });
        return;
    }
    formIsBusy.value = true;
    let data = {
        activityQuestionUuid: props.question.uuid,
        receiverId: props.testSettings.currentStudentIdx != null ? props.testObj.team[props.testSettings.currentStudentIdx].id : null,
        stemId: stemKeys.value[currentStemIdx.value],
    };
    if (props.question.type == 'pointDistribution') {
        data.points = answer.value;
    } else {
        data.answer = answer.value;
    }
    axios
        .post(`student-tests/${props.testObj.uuid}/peer-evaluation`, data)
        .then(function (response) {
            formIsBusy.value = false;
            if (props.question.type != 'pointDistribution') {
                stem.value.studentAnswer.push({
                    receiverId: props.testSettings.currentStudentIdx != null ? props.testObj.team[props.testSettings.currentStudentIdx].id : null,
                    answer: answer.value,
                });
            } else {
                props.question.pointDistributions = _.cloneDeep(answer.value);
            }

            if (canGoToNextStem.value) {
                props.testSettings.currentQuestionStemIdx++;
                answer.value = getAnswer();
            } else if (canGoToNextCriteria.value) {
                let nextQuestion = getNextQuestion();
                let nextQuestionIdx = questionGroupKeys.value.indexOf(nextQuestion.key);
                props.testSettings.currentQuestionGroupIdx = nextQuestionIdx;
                props.testSettings.currentQuestionGroupUuid = props.testObj.questions[nextQuestion.key][0].uuid;
                props.testSettings.currentStudentIdx = nextQuestion.studentIdx;
                props.testSettings.currentQuestionStemIdx = 0;
                answer.value = getAnswer();
            }

            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Answer successfully saved',
            });
        })
        .catch(function (errors) {
            formIsBusy.value = false;
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong. Please try again later',
            });
        });
};
const getAnswer = () => {
    let studentAnswer = props.question.type != 'pointDistribution' ? _.get(stem.value, 'studentAnswer', null) : props.question.pointDistributions;
    studentAnswer = studentAnswer ? _.cloneDeep(studentAnswer) : [];
    let a = props.question.type != 'pointDistribution' ? '' : [];
    if (props.question.type != 'pointDistribution') {
        a = _.findLast(studentAnswer, function (o) {
            if (props.question.settings.answerQuestionsForEachTeammate && props.testSettings.currentStudentIdx != -1) {
                return o.receiverId == props.testObj.team[props.testSettings.currentStudentIdx].id;
            } else {
                return true;
            }
        });
        return _.get(a, 'answer', '');
    } else {
        if (studentAnswer.length > 0) {
            return studentAnswer;
        } else {
            let distributions = [];
            for (var i = 0; i < props.testObj.team.length; i++) {
                let student = props.testObj.team[i];
                distributions.push({ displayName: student.displayName, receiverId: student.id, point: 0 });
            }
            return distributions;
        }
    }
};
const getNextQuestion = () => {
    if (canGoToNextCriteria.value) {
        let currentIdx = _.findIndex(props.testSettings.questionList, function (o) {
            return o.key == questionGroupKeys.value[currentQuestionGroupIdx.value] && o.studentIdx == props.testSettings.currentStudentIdx;
        });
        return props.testSettings.questionList[currentIdx + 1];
    } else return null;
    //get currentIdx for questionList
};
const getPreviousQuestion = () => {
    if (canGoToPreviousCriteria.value) {
        let currentIdx = _.findIndex(props.testSettings.questionList, function (o) {
            return o.key == questionGroupKeys.value[currentQuestionGroupIdx.value] && o.studentIdx == props.testSettings.currentStudentIdx;
        });
        return props.testSettings.questionList[currentIdx - 1];
    } else return null;
};
const stem = computed({
    get() {
        if (props.question.type != 'pointDistribution') {
            return props.question.questionStems[stemKeys.value[currentStemIdx.value]];
        } else {
            return {};
        }
    },
});

const canAnswer = computed({
    get() {
        if (isTestCompleted.value) {
            return false;
        }
        return true;
    },
});

const canGoToNextStem = computed({
    get() {
        if (props.question.type != 'pointDistribution' && currentStemIdx.value < stemKeys.value.length - 1) {
            return true;
        }
        return false;
    },
});

const canGoToPreviousStem = computed({
    get() {
        if (props.question.type != 'pointDistribution' && currentStemIdx.value > 0) {
            return true;
        }
        return false;
    },
});
const canGoToNextCriteria = computed({
    get() {
        let currentIdx = _.findIndex(props.testSettings.questionList, function (o) {
            return o.key == questionGroupKeys.value[currentQuestionGroupIdx.value] && o.studentIdx == props.testSettings.currentStudentIdx;
        });
        if (currentIdx < props.testSettings.questionList.length - 1) {
            return true;
        }
        return false;
    },
});
const canGoToPreviousCriteria = computed({
    get() {
        let currentIdx = _.findIndex(props.testSettings.questionList, function (o) {
            return o.key == questionGroupKeys.value[currentQuestionGroupIdx.value] && o.studentIdx == props.testSettings.currentStudentIdx;
        });
        if (currentIdx > 0) {
            return true;
        }
        return false;
    },
});
/*
const canGoToNextPeer = computed({
    get() {
        if (props.question.settings.answerQuestionsForEachTeammate && props.testSettings.currentStudentIdx < props.testObj.team.length - 1) {
            return true;
        }
        return false;
    },
});
const canGoToPreviousPeer = computed({
    get() {
        if (props.question.settings.answerQuestionsForEachTeammate && props.testSettings.currentStudentIdx > 0) {
            return true;
        }
        return false;
    },
});
*/
const canSubmitAnswer = computed({
    get() {
        if (isTestCompleted.value) {
            return false;
        }
        if (props.question.type != 'pointDistribution') {
            if (props.question.type == 'ratingV2') {
                if (answer.value !== null && answer.value !== '' && answer.value !== getAnswer()) {
                    return true;
                }
            } else {
                if (answer.value !== null && answer.value !== '' && answer.value !== getAnswer() && answer.value) {
                    var length = answer.value.split(/[^\s]+/).length - 1;

                    if (length >= props.question.minimum && length <= props.question.maximum) {
                        return true;
                    }
                }
            }
        } else {
            if (pointDistributionForm.value) {
                return pointDistributionForm.value.canSubmitAnswer;
            }
        }
        return false;
    },
});

watch(props.question, async (newQuestion, oldQuestion) => {
    if (oldQuestion.uuid != newQuestion.uuid) {
        init();
    }
});

watch(props.testSettings, async (newSettings, oldSettings) => {
    // console.log(newSettings);
    init();
});
</script>
