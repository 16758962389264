<template>
    <div class="col-xs-12 right-container">
        <cl-tabs-nav :array-value="tabs" @emitCurrentTab="currentTab = $event" />

        <div class="tab-content" id="nav-tabContent">
            <template v-for="tab in tabs">
                <div
                    v-if="tab.show"
                    :key="tab.id"
                    :id="`${tab.id}-panel`"
                    class="tab-pane"
                    :class="{ 'in active': tab.id == currentTab, fade: tab.id != currentTab }"
                    role="tabpanel"
                    :aria-labelledby="`${tab.id}-tab`"
                    :hidden="currentTab != tab.id"
                    :tabindex="currentTab === tab.id ? 0 : -1"
                >
                    <div class="pageTitle">
                        <h1>{{ tab.label }}</h1>
                        <p class="col-md-6">
                            <template v-if="tab.id == 'templates'">
                                Templates are preset questions you can use to populate your activity. Click the Actions button to view or use the templates.
                            </template>
                            <template v-if="tab.id == 'questions'">
                                Questions are the foundation for tests and exercises. In this space, you will be able to create new questions for your
                                activities, import questions using simple templates and filter/view the questions you have already stored in your question bank.
                                <a href="https://help.intedashboard.com/how-do-i-add-questions-to-an-activity-using-the-question-bank-method" target="newTab">
                                    <i class="fas fa-info-circle" aria-hidden="true" />
                                    Basic Information on the InteDashboard Question Bank Feature
                                </a>
                            </template>
                        </p>
                    </div>

                    <questions-bank v-if="tab.id == 'questions'" />
                    <templates-listing v-if="tab.id == 'templates'" />
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import templatesListing from './library-templates/index.vue';
import questionsBank from './questions-bank/index.vue';

const tabs = ref([
    {
        id: 'templates',
        label: 'Templates',
        show: true,
    },
    {
        id: 'questions',
        label: 'Questions',
        show: true,
    },
]);

const currentTab = ref('');

watch(currentTab, (newVal) => {
    var tabName = tabs.value.find((t) => t.id == newVal)?.label;

    document.title = `${tabName} | Library | InteDashboard | TBL Makes Teams Work`;

    Events.fire('topbar_update', {
        title: '<i class="fa fa-list-ul"></i>&nbsp;Questions',
        breadcrumb: [
            { name: 'Home' },
            { name: 'Library', link: 'questions.index' },
            {
                name: tabName,
            },
        ],
    });
});
</script>
