<template>
    <div
        ref="modal"
        class="modal warning-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="switchCourseCollaboratorRole-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="switchCourseCollaboratorRole-title" class="modal-title">Switch Course Collaborator Role</h2>
                </div>

                <div class="modal-body textAlignCenter">
                    <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />
                    <h3 class="marginTop40 fw-normal">You are updating</h3>
                    <h3>{{ collaborator.displayName }}</h3>

                    <h3 class="fw-normal marginTop10">
                        in <b>{{ course.name }}</b>
                    </h3>

                    <h3 class="fw-normal marginTop10">
                        switch from <b>{{ collaborator.role }}</b> to
                    </h3>

                    <div class="marginTop20 flex justifyCenter">
                        <div class="col-xs-12 col-sm-8 textAlignLeft">
                            <label for="select-course-collaborator-role" class="control-label">
                                Select Course Collaborator Role<template>
                                    <span class="visible-hidden">Required</span>
                                    <span class="important" aria-hidden="true">*</span>
                                </template>
                            </label>
                            <div class="form-group-select-wrapper form-dropdown">
                                <select id="select-course-collaborator-role" v-model="selectedRole" class="form-control">
                                    <option value="" disabled selected>Course Collaborator Role</option>
                                    <option v-for="roleOption in roles" :key="roleOption" :value="roleOption">
                                        {{ roleOption.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- <p class="marginTop20">By switching Course Collaborator Role,</p>
                    <p>the user will have different permissions</p>
                    <p>from current collaborator Role</p>

                    <p
                        class="marginTop20"
                        v-dompurify-html="
                            `Continue to switch staff role to ${
                                selectedRole.name ? `<b>${selectedRole.name}</b>` : '<br/> <em>Please select a collaborator role</em>'
                            }`
                        "
                    ></p> -->
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeModal()">Cancel</button>
                    <button
                        v-tooltip="
                            selectedRole == ''
                                ? 'Please select a collaborator role'
                                : collaborator.role == selectedRole.name
                                ? 'They are already in this role'
                                : ''
                        "
                        class="btn btn-success"
                        :class="{
                            'disabled hasToolTip': selectedRole == '' || collaborator.role == selectedRole.name,
                        }"
                        :aria-disabled="selectedRole == '' || collaborator.role == selectedRole.name"
                        @click.prevent="selectedRole == '' || collaborator.role == selectedRole.name ? '' : submit()"
                    >
                        Update
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';

const emit = defineEmits(['switch-role']);
const modal = ref(null);
const course = ref({});
const collaborator = ref({});
const roles = ref([]);
const selectedRole = ref('');

const switchRole = (c = {}, col = {}) => {
    course.value = c;
    collaborator.value = col;
    fetchRoles();
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    selectedRole.value = '';
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const fetchRoles = () => {
    axios.get('course/roles').then((response) => {
        roles.value = response.data.data.filter((role) => role.name !== 'Course Owner');
    });
};

const submit = () => {
    axios
        .patch(`courses/${course.value.uuid}/collaborators/${collaborator.value.id}`, {
            roleId: selectedRole.value.id,
        })
        .then((response) => {
            closeModal();
            emit('switch-role');
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Course collaborator role updated successfully',
            });
        })
        .catch((error) => {
            selectedRole.value = '';
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong. Please try again later',
            });
        });
};

defineExpose({ switchRole });
</script>
