<template>
    <div v-if="component_done_loading" id="account-create" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">NEW ACCESS CODES</div>
                    <div class="col-xs-6 flexRight">
                        <div class="hidden-xs hidden-sm">
                            <button
                                id="top-send-invitation-btn"
                                class="btn btn-success"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Create"
                                @click="submit"
                            >
                                <i class="fa fa-paper-plane marginRight8" aria-hidden="true"></i>Create
                            </button>
                            <!--<button id="top-preview-email-btn" class="btn btn-default " :disabled="(!canSend) ? true:undefined" @click.prevent="openEmailSampleModal()">
				                Preview Email
				            </button>
				            <router-link id="top-back-btn" :to="{name:'accounts.index'}" tag="button" class="btn btn-default " exact>
				            	Back
				            </router-link>-->
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button
                                id="top-mobile-send-invitation-btn"
                                v-tooltip="{ content: 'Create' }"
                                class="btn btn-primary"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Create"
                                @click="submit"
                            >
                                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                            </button>
                            <!--<button id="top-mobile-preview-email-btn" class="btn btn-default " :disabled="(!canSend) ? true:undefined" @click.prevent="openEmailSampleModal()">
				                <i class="fa fa-eye"></i>
				            </button>
				             <router-link id="top-mobile-back-btn" :to="{name:'accounts.index'}" tag="button" class="btn btn-default " exact>
				            	<i class="fa fa-caret-left"></i>
				            </router-link>-->
                        </div>
                    </div>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <code-form
                            :form="form"
                            :account="account"
                            :set-validity-period="setValidityPeriod"
                            @update:setValidityPeriod="updateValidityPeriod"
                        ></code-form>
                        <div class="buttonGroup">
                            <button class="btn btn-success" :disabled="!canSend ? true : undefined" aria-label="Create" @click="submit">
                                <i class="fa fa-paper-plane marginRight8" aria-hidden="true"></i>Create
                            </button>
                            <router-link
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'accounts.view',
                                    params: { id: $route.params.account_id },
                                    query: { tab: 'code' },
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate">Cancel</button>
                            </router-link>
                        </div>
                    </div>
                </template>
                <!-- <template slot="footer">
			      <div>
			        <button :disabled="(!canSend) ? true:undefined" class="btn pull-right btn-success" type="submit"@click="submit"> Create </button>
			      </div>  
			    </template> -->
            </kr-panel>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    components: {
        'code-form': require(`./form-partials/partial.code.vue`).default,
    },
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            setValidityPeriod: true,
            form: new KrForm({
                fields: {
                    bookstoreName: '',
                    quantity: '',
                    prefix: '',
                    validityPeriod: 'default!:6',
                    validForRefundDate: '',
                    plan: '',
                    accountUuid: '',
                },
                props: {},
                urls: {
                    post: 'accounts/' + this.$route.params.account_id + '/access-codes/generate',
                },
            }),
            account: {},
        };
    },
    computed: {
        canSend() {
            if (this.setValidityPeriod) {
                return !!(
                    this.form.model.quantity &&
                    this.form.model.bookstoreName &&
                    this.form.model.prefix &&
                    this.form.model.validityPeriod &&
                    this.form.model.validForRefundDate &&
                    this.form.model.plan
                );
            } else {
                return !!(this.form.model.quantity && this.form.model.bookstoreName && this.form.model.prefix);
            }
        },
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-id-card"></i>&nbsp;Accounts',
            breadcrumb: [{ name: 'Home' }, { name: 'Accounts', link: 'accounts.index' }],
        });
        this.fetchAccount();
    },
    methods: {
        submit() {
            var that = this;
            this.form.post(null, { responseType: 'blob' }).then(function (response) {
                if (!response.krFormError) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `ACCESS_CODES_CREATED_` + moment().format('YYYY_MM_DD') + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    that.$router.push({
                        name: 'accounts.view',
                        params: { id: that.$route.params.account_id },
                        query: { tab: 'code' },
                    });
                }
            });
        },
        fetchAccount() {
            var that = this;
            axios.get('/accounts/' + this.$route.params.account_id).then(function (response) {
                that.account = response.data.data;
                Events.fire('topbar_update', {
                    breadcrumb: [
                        { name: 'Home' },
                        { name: 'Accounts', link: 'accounts.index' },
                        {
                            name: that.account.organisationName,
                            link: 'accounts.view',
                            params: { id: that.$route.params.account_id },
                        },
                        { name: 'New Access codes' },
                    ],
                });
                if (that.account.paymentPlans.length > 0) {
                    that.form.model.plan = that.account.paymentPlans[0].type;
                }
                that.form.model.accountUuid = that.$route.params.account_id;
                that.component_done_loading = true;
            });
        },
        updateValidityPeriod(value) {
            this.setValidityPeriod = value;
        },
    },
};
</script>
