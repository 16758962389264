<template>
    <div class="dualHeaderTable">
        <table class="table">
            <caption ref="dualHeaderTableCaption">
                <h3 class="margin0 fs-22px">
                    <template v-if="route.name == 'settings.edit'"> Manage Course Collaborator Roles and Permissions </template>
                    <template v-else> Course Collaborator Roles and Permissions </template>
                </h3>

                <small v-if="route.name == 'settings.edit'" class="fw-normal fontGrey">
                    Note: The permissions for this course's roles can be modified by your institution's Super Admin.
                </small>
                <p v-if="route.name == 'settings.edit'" class="fw-normal col-xs-12 col-md-7 blueLink">
                    Manage your course collaborators here. See who has access, their roles, and make changes as needed.
                    <a href="https://help.intedashboard.com/how-to-create-new-collaborator-role" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        Add new collaborator roles</a
                    >, and
                    <a href="https://help.intedashboard.com/how-to-edit-collaborator-role-permissions)" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        adjust a role’s access permissions
                    </a>
                    easily.
                </p>

                <br />

                <dl class="flex align-items gap10 margin0">
                    <dt>Legend:</dt>
                    <dd class="flex align-items gap10 margin0">
                        <span class="flex align-items margin0">
                            <span class="material-symbols-outlined tableCheckBackground border1pxGrey" role="img" aria-label="Yes">check</span>
                            <span>= Yes</span>
                        </span>
                        <span class="flex align-items margin0">
                            <span class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                            <span>= No</span>
                        </span>
                    </dd>
                </dl>
            </caption>

            <thead>
                <tr>
                    <th scope="col">Access Role and Permissions</th>
                    <th
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-role`"
                        :id="roleId(role.name)"
                        scope="col"
                        class="minWidth200px width200px maxWidth200px"
                        :class="{ newTableBorderLine: newRoleLabel && idx == roles.length - 1 }"
                        :style="{
                            zIndex: openDropdownId === role.id ? '2' : '1',
                        }"
                    >
                        <div v-if="newRoleLabel && idx == roles.length - 1" class="tableStatusTag tableStatusTag-light-warning marginBottom10">
                            New Role Created
                        </div>
                        <div :class="{ 'header-content': route.name == 'settings.edit' }">
                            <div v-if="route.name == 'settings.edit'" class="dropdown">
                                <button
                                    :id="`viewDropdownMenuButton-${role.id}`"
                                    v-tooltip="'More Actions'"
                                    class="btn btn-outline-default icon-only"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    @click="openDropdownId = role.id"
                                >
                                    <i class="fas fa-grip-vertical" aria-hidden="true" />
                                </button>
                                <div
                                    :id="`dropdown-${role.id}`"
                                    class="dropdown-menu dropdown-menu-new"
                                    :class="{ 'dropdown-menu-right': idx >= roles.length - 2, 'dropdown-menu-left': idx < roles.length - 2 }"
                                    :aria-labelledby="`viewDropdownMenuButton-${role.id}`"
                                >
                                    <ul role="menu" :key="role.id">
                                        <li role="menuitem">
                                            <a class="primary-state" href="#" @click.prevent="$refs['CollaboratorRoleFormComponent'].openNav(role, 'update')">
                                                <span>
                                                    <span class="material-symbols-outlined filled marginRight8" aria-hidden="true"> edit_document </span>Edit
                                                    Role Permissions
                                                </span>
                                            </a>
                                        </li>
                                        <li role="menuitem">
                                            <a
                                                class="primary-state"
                                                href="#"
                                                @click.prevent="$refs['CollaboratorRoleFormComponent'].openNav(role, 'duplicate')"
                                            >
                                                <span>
                                                    <span class="material-symbols-outlined filled marginRight8" aria-hidden="true"> file_copy </span>Duplicate
                                                    Role
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="!role.defaultRole && role.order != 6" role="menuitem">
                                            <a class="primary-state" href="#" @click.prevent="changeOrdering(role, 'up')">
                                                <span>
                                                    <span class="material-symbols-outlined filled marginRight8" aria-hidden="true"> arrow_left_alt </span>Move
                                                    Left
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="!role.defaultRole && role.order != roles.length" role="menuitem">
                                            <a class="primary-state" href="#" @click.prevent="changeOrdering(role, 'down')">
                                                <span>
                                                    <span class="material-symbols-outlined filled marginRight8" aria-hidden="true"> arrow_right_alt </span>Move
                                                    Right
                                                </span>
                                            </a>
                                        </li>
                                        <hr v-if="!role.defaultRole" />
                                        <li v-if="!role.defaultRole" role="menuitem">
                                            <a class="danger-state" href="#" @click.prevent="$refs['DeleteRoleComponent'].deleteRole(role)">
                                                <span>
                                                    <span class="material-symbols-outlined filled marginRight8" aria-hidden="true"> delete </span>Delete Role
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <span>
                                {{ role.name }}
                            </span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="header">
                    <th id="course_management" scope="row">1. Course Management</th>
                    <td :colspan="roles.length + 1" />
                </tr>
                <tr>
                    <th headers="course_management" id="change_course_settings" class="whiteSpacePreLine light-blue-bg" scope="row">
                        1.1 Edit Course Settings
                    </th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-change-course-settings`"
                        :headers="`course_management change_course_settings ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('update_course_settings'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('update_course_settings')" class="material-symbols-outlined" role="img" aria-label="Yes">
                            check
                        </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th headers="course_management" id="duplicate_course" class="whiteSpacePreLine light-blue-bg" scope="row">1.2 Duplicate Course</th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-duplicate-course`"
                        :headers="`course_management duplicate_course ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('duplicate_course'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('duplicate_course')" class="material-symbols-outlined" role="img" aria-label="Yes">check</span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th headers="course_management" id="archive_course" class="whiteSpacePreLine light-blue-bg" scope="row">1.3 Archive Course</th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-archive-course`"
                        :headers="`course_management archive_course ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('archive_course'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('archive_course')" class="material-symbols-outlined" role="img" aria-label="Yes">check</span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th headers="course_management" id="add_remove_course_collaborators" class="whiteSpacePreLine light-blue-bg" scope="row">
                        1.4 Add/Remove Course Collaborators
                        <br />
                        <br />
                        <small role="note">
                            <em class="fontGrey fw-normal"> Note : Course Admins cannot remove the Course Owner from the course </em>
                        </small>
                    </th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-add-remove-course-collaborators`"
                        :headers="`course_management add_remove_course_collaborators ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('manage_course_collaborators'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('manage_course_collaborators')" class="material-symbols-outlined" role="img" aria-label="Yes">
                            check
                        </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th headers="course_management" id="approve_collaborators" class="whiteSpacePreLine light-blue-bg" scope="row">
                        1.5 Approve/Deny Collaborator Requests
                        <br />
                        <br />
                        <small v-if="route.name == 'settings.edit'" role="note">
                            <em class="fontGrey fw-normal"> Note: Only Course Owner can do it </em>
                        </small>
                    </th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-approve_collaborators`"
                        :headers="`course_management approve_collaborators ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('approve_collaborators'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('approve_collaborators')" class="material-symbols-outlined" role="img" aria-label="Yes">
                            check
                        </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th headers="course_management" id="transfer_course_ownership" class="whiteSpacePreLine light-blue-bg" scope="row">
                        1.6 Transfer Course Ownership
                        <br />
                        <br />
                        <small v-if="route.name == 'settings.edit'" role="note">
                            <em class="fontGrey fw-normal"> Note: Super Admins can always perform this action. </em>
                        </small>
                    </th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-transfer-course-ownership`"
                        :headers="`course_management transfer_course_ownership ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('transfer_course_ownership'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('transfer_course_ownership')" class="material-symbols-outlined" role="img" aria-label="Yes">
                            check
                        </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th headers="course_management" id="leave_as_course_collaborator" class="whiteSpacePreLine light-blue-bg" scope="row">
                        1.7 Leave as Course Collaborator
                        <br />
                        <br />
                        <small role="note">
                            <em class="fontGrey fw-normal"> Course Owners must first transfer ownership before they can leave. </em>
                        </small>
                    </th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-leave-as-course-collaborator`"
                        :headers="`course_management leave_as_course_collaborator ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('leave_course'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('leave_course')" class="material-symbols-outlined" role="img" aria-label="Yes"> check </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr class="header">
                    <th id="activity_management" scope="colgroup">2. Activity Management</th>
                    <td :colspan="roles.length + 1" />
                </tr>
                <tr>
                    <th id="view_modules_and_activities" headers="activity_management" class="whiteSpacePreLine light-blue-bg" scope="row">
                        2.1 View Modules and Activities
                    </th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-view-modules-and-activities`"
                        :headers="`activity_management view_modules_and_activities ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('view_modules_and_activities'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('view_modules_and_activities')" class="material-symbols-outlined" role="img" aria-label="Yes">
                            check
                        </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th id="add_edit_duplicate_remove_modules_and_activities" headers="activity_management" class="whiteSpacePreLine light-blue-bg" scope="row">
                        2.2 Add/Edit/Duplicate/Remove modules and activities within the course
                    </th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-add-edit-duplicate-remove-modules-and-activities`"
                        :headers="`activity_management add_edit_duplicate_remove_modules_and_activities ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('manage_modules_and_activities'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('manage_modules_and_activities')" class="material-symbols-outlined" role="img" aria-label="Yes">
                            check
                        </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th id="duplicate_modes_activities_to_another_course" headers="activity_management" class="whiteSpacePreLine light-blue-bg" scope="row">
                        2.3 Duplicate modules/activities to another course
                    </th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-duplicate-modes-activities-to-another-course`"
                        :headers="`activity_management duplicate_modes_activities_to_another_course ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('duplicate_modules_and_activities_to_another_course'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span
                            v-if="role.permissions.includes('duplicate_modules_and_activities_to_another_course')"
                            class="material-symbols-outlined"
                            role="img"
                            aria-label="Yes"
                        >
                            check
                        </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th id="publish_activity_id" headers="activity_management" class="whiteSpacePreLine light-blue-bg" scope="row">2.4 Publish activities</th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-publish-activity`"
                        :headers="`activity_management publish_activity_id ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('publish_activity'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('publish_activity')" class="material-symbols-outlined" role="img" aria-label="Yes"> check </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th
                        id="start_pause_end_activities_and_grade_students_or_teams"
                        headers="activity_management"
                        class="whiteSpacePreLine light-blue-bg"
                        scope="row"
                    >
                        2.5 Start/Pause/End activities and grade students or teams
                    </th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-start-pause-end-activities-and-grade-students-or-teams`"
                        :headers="`activity_management start_pause_end_activities_and_grade_students_or_teams ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{ tableCheckBackground: role.permissions.includes('manage_test'), newTableBorderLine: newRoleLabel && idx == roles.length - 1 }"
                    >
                        <span v-if="role.permissions.includes('manage_test')" class="material-symbols-outlined" role="img" aria-label="Yes"> check </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th id="toggle_test_visibility" headers="activity_management" class="whiteSpacePreLine light-blue-bg" scope="row">
                        2.6 Toggle visibility settings for activities
                    </th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-toggle-test-visibility`"
                        :headers="`activity_management toggle_test_visibility ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('manage_test_visibility'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('manage_test_visibility')" class="material-symbols-outlined" role="img" aria-label="Yes">
                            check
                        </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th id="reset_any_activity_after_it_has_started" headers="activity_management" class="whiteSpacePreLine light-blue-bg" scope="row">
                        2.7 Reset any activity after it has started
                    </th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-reset-any-activity-after-it-has-started`"
                        :headers="`activity_management reset_any_activity_after_it_has_started ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('reset_activity'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('reset_activity')" class="material-symbols-outlined" role="img" aria-label="Yes"> check </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th id="delete_any_activity_after_it_has_started" headers="activity_management" class="whiteSpacePreLine light-blue-bg" scope="row">
                        2.8 Delete any activity after it has started
                    </th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-delete-any-activity-after-it-has-started`"
                        :headers="`activity_management delete_any_activity_after_it_has_started ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('delete_activity'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('delete_activity')" class="material-symbols-outlined" role="img" aria-label="Yes"> check </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th
                        id="change_or_accept_new_answers_after_an_activity_has_ended"
                        headers="activity_management"
                        class="whiteSpacePreLine light-blue-bg"
                        scope="row"
                    >
                        2.9 Change or accept new answers
                    </th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-change-or-accept-new-answers-after-an-activity-has-ended`"
                        :headers="`activity_management change_or_accept_new_answers_after_an_activity_has_ended ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('update_published_question'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('update_published_question')" class="material-symbols-outlined" role="img" aria-label="Yes">
                            check
                        </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr class="header">
                    <th id="student_management" scope="colgroup">3. Student Management</th>
                    <td :colspan="roles.length + 1" />
                </tr>
                <tr>
                    <th id="view_sections_teams_and_students" headers="student_management" class="whiteSpacePreLine light-blue-bg" scope="row">
                        3.1 View Sections, teams and students
                    </th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-view-sections-teams-and-students`"
                        :headers="`student_management view_sections_teams_and_students ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('view_course_roster'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('view_course_roster')" class="material-symbols-outlined" role="img" aria-label="Yes">
                            check
                        </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th
                        id="enroll_disenroll_students_and_modify_sections_and_teams"
                        headers="student_management"
                        class="whiteSpacePreLine light-blue-bg"
                        scope="row"
                    >
                        3.2 Enroll/Disenroll students and modify sections and teams
                    </th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-enroll-disenroll-students-and-modify-sections-and-teams`"
                        :headers="`student_management enroll_disenroll_students_and_modify_sections_and_teams ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('manage_course_roster'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('manage_course_roster')" class="material-symbols-outlined" role="img" aria-label="Yes">
                            check
                        </span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
                <tr>
                    <th id="export_grades" headers="student_management" class="whiteSpacePreLine light-blue-bg" scope="row">3.3 Export grades</th>
                    <td
                        v-for="(role, idx) in roles"
                        :key="`${role.id}-export-grades`"
                        :headers="`student_management export_grades ${roleId(role.name)}`"
                        class="textAlignCenter"
                        :class="{
                            tableCheckBackground: role.permissions.includes('export_grades'),
                            newTableBorderLine: newRoleLabel && idx == roles.length - 1,
                        }"
                    >
                        <span v-if="role.permissions.includes('export_grades')" class="material-symbols-outlined" role="img" aria-label="Yes">check</span>
                        <span v-else class="material-symbols-outlined fontGrey" role="img" aria-label="No">close</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <deleteRole ref="DeleteRoleComponent" @role-deleted="fetchRole" />
    <collaboratorRoleForm ref="CollaboratorRoleFormComponent" @role-updated="fetchRole" @role-duplicated="loadNewRole" />
</template>
<script setup>
import { ref, onMounted, nextTick, watch } from 'vue';
import deleteRole from '../actions/deleteRole.vue';
import collaboratorRoleForm from '../actions/collaboratorRoleForm.vue';
import { windowResize } from '../../../../composables/resize/windowResize';
import { useRoute } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';

const route = useRoute();
const { windowWidth } = windowResize();

const roles = ref({});
const newRoleLabel = ref(false);
const dualHeaderTableCaption = ref(null);

const openDropdownId = ref(null);

const fetchRole = () => {
    axios.get('course/roles').then((response) => {
        roles.value = response.data.data;
    });
};

const loadNewRole = () => {
    fetchRole();
    newRoleLabel.value = true;
};

const roleId = (name) => {
    if (!name) return '';
    return name.toLowerCase().replace(/\s+/g, '_');
};

const fetchTableMargin = () => {
    $('.dualHeaderTable').css('margin-top', $(dualHeaderTableCaption.value).height() + 10);
};

const changeOrdering = (role, type) => {
    axios
        .patch(`/course/roles/roles/${role.id}/reorder`, {
            order: type == 'up' ? role.order - 1 : role.order + 1,
        })
        .then(function (response) {
            fetchRole();

            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'The role order has been updated successfully.',
            });
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong, please try again later',
            });
        });
};

onMounted(() => {
    fetchRole();

    if (route.name == 'settings.edit') {
        nextTick(() => {
            fetchTableMargin();
        });
    } else {
        $('#view-course-collaborator-roles-matrix').on('shown.bs.collapse', async () => {
            fetchTableMargin();
        });
    }
});

watch([windowWidth], () => {
    nextTick(() => {
        fetchTableMargin();
    });
});

defineExpose({
    loadNewRole,
});
</script>
