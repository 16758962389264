<template>
    <div
        ref="modal"
        class="modal danger-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="leaveCollaborator-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="leaveCollaborator-title" class="modal-title">Leave as Collaborator</h2>
                </div>

                <div class="modal-body textAlignCenter">
                    <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />
                    <h3 class="marginTop40">Leave Course Collaborator</h3>
                    <h4>{{ course.name }}</h4>

                    <p class="marginTop20">Once you leave as collaborator</p>
                    <p>from the course, they will no longer</p>
                    <p>have access to view or manage</p>
                    <p>any content related to the course.</p>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeModal()">Cancel</button>
                    <button class="btn btn-danger" @click.prevent="submit()">Confirm, Leave</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';

const modal = ref(null);
const course = ref({});
const router = useRouter();
const leave = (c = {}) => {
    course.value = c;
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .post(`courses/${course.value.uuid}/collaborator-user/leave`)
        .then((response) => {
            router.push({ name: 'courses.index' });
            closeModal();
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'You have left the course as collaborator',
            });
        })
        .catch((error) => {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong',
            });
        });
};

defineExpose({ leave });
</script>
