<template>
    <div ref="sideNavDialog" class="sidenav-dialog">
        <div class="sidenav-content">
            <div ref="sidenavHeader" class="sidenav-header">
                <div>
                    <h2 class="marginTop0">
                        <template v-if="formMode === 'update'">Edit {{ selectedRole.name }} Permissions</template>
                        <template v-else>Duplicate {{ selectedRole.name }} Permissions</template>
                    </h2>
                    <p>
                        <template v-if="formMode === 'update'">
                            To {{ selectedRole.name }} permissions, select the permissions for each item below and click Save. New permissions will be enabled
                            for all users in the entire institution.
                        </template>
                        <template v-else> This is a copy of the {{ selectedRole.name }} role. </template>
                    </p>
                </div>
                <button class="btn close flexInherit" data-dismiss="modal" aria-label="Close Course Collaborator Role Form" @click.prevent="closeNav()">
                    <i class="fa-solid fa-xmark" aria-hidden="true" />
                </button>
            </div>

            <Form ref="form" @submit="onSubmit" :validation-schema="schema" :initial-values="initialValues" v-slot="{ values, errors, meta, setFieldValue }">
                <div ref="sidenavBody" class="sidenav-body">
                    <div class="padding20">
                        <div class="form-group" :class="{ 'has-error': errors.roleName }">
                            <label class="control-label" for="roleName">
                                Course Collaborator Role Name<span class="important" aria-label="required">*</span>
                            </label>
                            <Field name="roleName" type="text" id="roleName" v-slot="{ field }">
                                <input
                                    v-bind="field"
                                    type="text"
                                    placeholder="Type Collaborator Role Name here"
                                    class="form-control"
                                    aria-describedby="roleName_error"
                                    :readonly="formMode === 'update'"
                                />
                            </Field>
                            <p id="roleName_error" class="important invalid-feedback">
                                <i>{{ errors.roleName }}</i>
                            </p>
                        </div>

                        <fieldset class="marginTop20">
                            <legend class="control-label">Role Color</legend>
                            <span
                                class="tableStatusTag whiteSpaceNowrap dynamic-status"
                                :style="{
                                    '--main-color': initialValues.defaultRole ? initialValues.color : colorPicked,
                                }"
                                role="status"
                            >
                                <em>Example:</em> {{ initialValues.defaultRole ? initialValues.roleName : 'Role Name' }}
                            </span>

                            <p v-if="initialValues.defaultRole" class="marginTop10">
                                {{ initialValues.color }}
                            </p>

                            <div v-else class="flex">
                                <Field name="color" v-slot="{ field }">
                                    <div v-for="(color, idx) in colors" :key="`${idx}-${color}`" class="colorPicker margin10">
                                        <label class="visible-hidden" :for="'roleColor-' + color"> Role Color {{ color }} </label>
                                        <input
                                            :id="'roleColor-' + color"
                                            v-bind="field"
                                            v-model="colorPicked"
                                            v-tooltip="'Color ' + color"
                                            type="radio"
                                            name="color-picker"
                                            :style="'background:' + color"
                                            :value="color"
                                        />
                                    </div>
                                </Field>
                            </div>
                        </fieldset>
                    </div>
                    <div class="accordion">
                        <div class="accordion-item-primary borderRadius0 borderNone">
                            <h3 class="accordion-header margin0">
                                <button
                                    class="accordion-button collapsed width100 flexSpaceBetween"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#panelsStayOpen-Course-Management"
                                    aria-expanded="true"
                                    aria-controls="panelsStayOpen-Course-Management"
                                >
                                    1. Course Management

                                    <i class="fas fa-chevron-up"></i>
                                </button>
                            </h3>
                            <div id="panelsStayOpen-Course-Management" class="accordion-collapse collapse in padding20">
                                <div class="accordion-body">
                                    <fieldset>
                                        <legend>Permissions Course Management</legend>
                                        <div class="form-group">
                                            <Field name="permissions" type="checkbox" value="update_course_settings" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_update_course_settings" />
                                                    <label for="permissions_update_course_settings"> 1.1 Edit Course Settings </label>
                                                </div>
                                            </Field>

                                            <Field name="permissions" type="checkbox" value="duplicate_course" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_duplicate_course" />
                                                    <label for="permissions_duplicate_course"> 1.2 Duplicate Course </label>
                                                </div>
                                            </Field>

                                            <Field name="permissions" type="checkbox" value="archive_course" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_archive_course" />
                                                    <label for="permissions_archive_course" class="d-block"> 1.3 Archive Course </label>
                                                </div>
                                            </Field>

                                            <Field name="permissions" type="checkbox" value="manage_course_collaborators" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_manage_course_collaborators" />
                                                    <label for="permissions_manage_course_collaborators"> 1.4 Add/Remove Course Collaborators </label>
                                                </div>
                                            </Field>

                                            <Field name="permissions" type="checkbox" value="approve_collaborator" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input
                                                        type="checkbox"
                                                        v-bind="field"
                                                        id="permissions_approve_collaborator"
                                                        :disabled="!selectedRole?.id || selectedRole.id !== 607"
                                                    />
                                                    <label for="permissions_approve_collaborator" class="d-block">
                                                        1.5 Approve/Deny Collaborator Requests
                                                        <i class="fontGrey">(only for Course Owner)</i>
                                                    </label>
                                                </div>
                                            </Field>

                                            <Field name="permissions" type="checkbox" value="transfer_course_ownership" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_transfer_course_ownership" disabled />
                                                    <label for="perm_transfer_course_ownership" class="d-block">
                                                        1.6 Transfer Course Ownership
                                                        <i class="fontGrey">(only for Course Owner)</i>
                                                    </label>
                                                </div>
                                            </Field>

                                            <Field name="permissions" type="checkbox" value="leave_course" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_leave_course" :disabled="selectedRole?.id == 607" />
                                                    <label for="permissions_leave_course" class="d-block">
                                                        1.7 Leave as Course Collaborator
                                                        <i class="fontGrey">(Except Course Owner)</i>
                                                    </label>
                                                </div>
                                            </Field>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item-primary borderRadius0 borderNone">
                            <h3 class="accordion-header margin0">
                                <button
                                    class="accordion-button collapsed width100 flexSpaceBetween"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#panelsStayOpen-Activity-Management"
                                    aria-expanded="false"
                                    aria-controls="panelsStayOpen-Activity-Management"
                                >
                                    2. Activity Management

                                    <i class="fas fa-chevron-up"></i>
                                </button>
                            </h3>
                            <div id="panelsStayOpen-Activity-Management" class="accordion-collapse collapse padding20">
                                <div class="accordion-body">
                                    <fieldset>
                                        <legend>Permissions Activity Management</legend>
                                        <div class="form-group">
                                            <Field name="permissions" type="checkbox" value="view_modules_and_activities" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_view_modules_and_activities" />
                                                    <label for="permissions_view_modules_and_activities" class="d-block">
                                                        2.1 View modules and activities
                                                    </label>
                                                </div>
                                            </Field>

                                            <Field name="permissions" type="checkbox" value="manage_modules_and_activities" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_manage_modules_and_activities" />
                                                    <label for="permissions_manage_modules_and_activities" class="d-block">
                                                        2.2 Add/Edit/Duplicate/Remove modules and activities within the course
                                                    </label>
                                                </div>
                                            </Field>

                                            <Field
                                                name="permissions"
                                                type="checkbox"
                                                value="duplicate_modules_and_activities_to_another_course"
                                                v-slot="{ field, meta }"
                                            >
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_duplicate_modules_and_activities_to_another_course" />
                                                    <label for="permissions_duplicate_modules_and_activities_to_another_course" class="d-block">
                                                        2.3 Duplicate modules/activities to another course
                                                    </label>
                                                </div>
                                            </Field>

                                            <Field name="permissions" type="checkbox" value="publish_activity" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_publish_activity" />
                                                    <label for="permissions_publish_activity" class="d-block"> 2.4 Publish activities </label>
                                                </div>
                                            </Field>

                                            <Field name="permissions" type="checkbox" value="manage_test" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_manage_test" />
                                                    <label for="permissions_manage_test" class="d-block">
                                                        2.5 Start/Pause/End activities and grade students or teams
                                                    </label>
                                                </div>
                                            </Field>

                                            <Field name="permissions" type="checkbox" value="manage_test_visibility" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_manage_test_visibility" />
                                                    <label for="permissions_manage_test_visibility" class="d-block">
                                                        2.6 Toggle visibility settings for activities
                                                    </label>
                                                </div>
                                            </Field>

                                            <Field name="permissions" type="checkbox" value="reset_activity" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_reset_activity" />
                                                    <label for="permissions_reset_activity" class="d-block">
                                                        2.7 Reset any activity after it has started
                                                    </label>
                                                </div>
                                            </Field>

                                            <Field name="permissions" type="checkbox" value="delete_activity" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_delete_activity" />
                                                    <label for="permissions_delete_activity" class="d-block">
                                                        2.8 Delete any activity after it has started
                                                    </label>
                                                </div>
                                            </Field>

                                            <Field name="permissions" type="checkbox" value="update_published_question" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_update_published_question" />
                                                    <label for="permissions_update_published_question" class="d-block">
                                                        2.9 Change or accept new answers
                                                    </label>
                                                </div>
                                            </Field>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item-primary borderRadius0 borderNone">
                            <h3 class="accordion-header margin0">
                                <button
                                    class="accordion-button collapsed width100 flexSpaceBetween"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#panelsStayOpen-Student-Management"
                                    aria-expanded="false"
                                    aria-controls="panelsStayOpen-Student-Management"
                                >
                                    3. Student Management

                                    <i class="fas fa-chevron-up"></i>
                                </button>
                            </h3>
                            <div id="panelsStayOpen-Student-Management" class="accordion-collapse collapse padding20">
                                <div class="accordion-body">
                                    <fieldset>
                                        <legend>Permissions Activity Management</legend>
                                        <div class="form-group">
                                            <Field name="permissions" type="checkbox" value="view_course_roster" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_view_course_roster" />
                                                    <label for="permissions_view_course_roster" class="d-block"> 3.1 View Sections, teams and students </label>
                                                </div>
                                            </Field>

                                            <Field name="permissions" type="checkbox" value="manage_course_roster" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_manage_course_roster" />
                                                    <label for="permissions_manage_course_roster" class="d-block">
                                                        3.2 Enroll/Disenroll students and modify sections and teams
                                                    </label>
                                                </div>
                                            </Field>

                                            <Field name="permissions" type="checkbox" value="export_grades" v-slot="{ field, meta }">
                                                <div class="checkboxField">
                                                    <input type="checkbox" v-bind="field" id="permissions_export_grades" />
                                                    <label for="permissions_export_grades" class="d-block"> 3.3 Export grades </label>
                                                </div>
                                            </Field>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div ref="sidenavFooter" class="sidenav-footer">
                    <button class="btn btn-outline-dark" @click.prevent="closeNav()">Cancel</button>
                    <button type="submit" class="btn btn-success" :disabled="!meta.valid">
                        <i class="fas fa-save marginRight8" aria-hidden="true" />
                        <template v-if="formMode === 'update'">Update</template>
                        <template v-else>Duplicate</template>
                    </button>
                </div>
            </Form>
        </div>
    </div>
</template>
<script setup>
import { ref, computed, watch, nextTick } from 'vue';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import { notify } from '@kyvg/vue3-notification';

const emit = defineEmits(['role-updated', 'role-duplicated']);
const sideNavDialog = ref(null);
const sidenavHeader = ref(null);
const sidenavBody = ref(null);
const sidenavFooter = ref(null);

const form = ref(null);
const selectedRole = ref({});
const formMode = ref('create');

const colors = ref(['#337F71', '#3F4692', '#635690', '#856185', '#626B71', '#BDF3E8', '#C4C9FA', '#DCD3F9', '#FFE3FF', '#DBE1E5']);
const colorPicked = ref(null);

const openNav = (role = {}, mode = 'create') => {
    formMode.value = mode;

    if (!sideNavDialog.value) return;

    sideNavDialog.value.style.display = 'block';
    document.body.style.overflow = 'hidden';

    if (mode === 'duplicate') {
        selectedRole.value = role;

        let filteredPermissions = [...role.permissions];
        if (role.id == 607) {
            filteredPermissions = filteredPermissions.filter((perm) => !['transfer_course_ownership', 'approve_collaborators'].includes(perm));
        }

        if (role.id == 611) {
            filteredPermissions = filteredPermissions.filter((perm) => perm !== 'takeover_course_ownership');
        }

        form.value?.resetForm({
            values: {
                roleName: `${role.name} (Copy)`,
                permissions: filteredPermissions,
                color: '#337F71',
                defaultRole: false,
            },
        });
    }

    if (mode === 'update') {
        selectedRole.value = role;

        form.value?.resetForm({
            values: {
                roleName: `${role.name}`,
                permissions: role.permissions,
                color: role.color,
                defaultRole: role.defaultRole,
            },
        });
    }

    nextTick(() => {
        calculateBodyHeight();
    });
};

const closeNav = () => {
    sideNavDialog.value.style.display = 'none';
    document.body.style.overflow = 'inherit';
};

const calculateBodyHeight = () => {
    if (!sidenavHeader.value || !sidenavFooter.value) return;

    const headerHeight = sidenavHeader.value.clientHeight || 0;
    const footerHeight = sidenavFooter.value.clientHeight || 0;
    const windowHeight = window.innerHeight;

    sidenavBody.value.style.height = `${windowHeight - headerHeight - footerHeight}px`;
};

const schema = yup.object({
    roleName: yup.string().required('Role name is required'),
});

const initialValues = computed(() => {
    if (formMode.value === 'duplicate') {
        let filteredPermissions = [...selectedRole.value.permissions];

        if (selectedRole.value.id == 607) {
            filteredPermissions = filteredPermissions.filter((perm) => !['transfer_course_ownership', 'approve_collaborators'].includes(perm));
        }

        if (selectedRole.value.id == 611) {
            filteredPermissions = filteredPermissions.filter((perm) => perm !== 'takeover_course_ownership');
        }

        return {
            roleName: `${selectedRole.value.name} (Copy)`,
            permissions: filteredPermissions,
            color: '#337F71',
            defaultRole: false,
        };
    }

    return {
        roleName: selectedRole.value.name,
        permissions: selectedRole.value.permissions,
        color: selectedRole.value.color,
        defaultRole: selectedRole.value.defaultRole,
    };
});

const onSubmit = (values) => {
    if (formMode.value === 'update') {
        axios
            .patch(`course/roles/${selectedRole.value.id}`, {
                name: values.roleName,
                permissions: values.permissions,
                color: values.color,
            })
            .then((response) => {
                closeNav();

                notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'You have updated the role',
                });

                emit('role-updated');
            })
            .catch(function (errors) {
                const formErrors = {};
                formErrors.roleName = errors.response.data.errors[0];

                form.value.setErrors(formErrors);

                notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: errors.response.data.errors.name[0],
                });
            });
    } else {
        axios
            .post(`course/roles/${selectedRole.value.id}/duplicate`, {
                name: values.roleName,
                permissions: values.permissions,
                color: values.color,
            })
            .then((response) => {
                closeNav();

                notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Role duplicated successfully',
                });

                emit('role-duplicated');
            })
            .catch(function (errors) {
                schema.value = yup.object({
                    roleName: yup
                        .string()
                        .required('Role name is required')
                        .test('unique-name', errors.response.data.errors.name[0], () => false),
                });

                notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: errors.response.data.errors.name[0],
                });
            });
    }
};

watch(
    () => initialValues.value?.color,
    (newColor) => {
        if (newColor) {
            colorPicked.value = newColor;
        }
    },
    { immediate: true }
);

defineExpose({
    openNav,
    closeNav,
});
</script>
