<template>
    <div
        ref="modal"
        class="modal danger-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="transferCourseOwnership-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="transferCourseOwnership-title" class="modal-title">Transfer Course Ownership</h2>
                </div>

                <div class="modal-body textAlignCenter">
                    <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />
                    <h3 class="marginTop40 fw-normal">You are transferring ownership of</h3>
                    <h4>
                        <b>{{ course.name }}</b>
                    </h4>
                    <h4>
                        to <b>{{ collaborator.displayName }} ({{ collaborator.role }})</b>
                    </h4>

                    <p class="marginTop20">Note: {{ collaborator.displayName }} role will be updated</p>
                    <p>to <b>Course Owner</b> and the current owner</p>
                    <p>
                        <b>{{ auth.user().role == 'Super Admin' ? ownerName : 'you' }}</b> will be updated to <b>Course Admin</b>
                    </p>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeModal()">Cancel</button>
                    <button class="btn btn-danger" @click.prevent="submit()">Transfer Course Ownership</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import KrAuth from '../../../../../components/auth/auth';

const auth = new KrAuth();

const emit = defineEmits(['transfer-ownership']);
const modal = ref(null);
const course = ref({});
const collaborator = ref({});
const ownerName = ref('');

const transfer = (c = {}, col = {}, owner = '') => {
    course.value = c;
    collaborator.value = col;
    ownerName.value = owner;
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .post(`courses/${course.value.uuid}/collaborator-user/transfer-ownership`, {
            userUuid: collaborator.value.userUuid,
        })
        .then((response) => {
            closeModal();
            emit('transfer-ownership');
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Course ownership transferred successfully',
            });
        })
        .catch((error) => {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Failed to transfer course ownership',
            });
        });
};

defineExpose({ transfer });
</script>
