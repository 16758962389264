<template>
    <div>
        <kr-panel :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <h2>Team Analysis</h2>
            </template>
            <template #content>
                <div v-if="component_done_loading">
                    <div class="border1pxGrey padding15 br-3 bg-plain-white marginBottom20" :class="{ 'col-xs-6 col-xs-offset-6': !isMobileView }">
                        <h3 class="fs-18px margin0 marginBottom15">
                            <b>Legend</b>
                        </h3>
                        <div class="flex">
                            <div class="flexOnly marginRight20">
                                <div class="marginRight8">
                                    <i class="fas fa-minus successColor d-block lh-0-5" aria-hidden="true" />
                                    <i class="fas fa-minus newCorrectColor d-block lh-0-5" aria-hidden="true" />
                                </div>
                                <p>100%</p>
                            </div>
                            <div class="flexOnly marginRight20">
                                <i class="fas fa-minus primaryColor marginRight8" aria-hidden="true" />
                                <p>> 50% and 100%</p>
                            </div>
                            <div class="flexOnly marginRight20">
                                <i class="fas fa-minus warningColor marginRight8" aria-hidden="true" />
                                <p>≤ 50% and > 0%</p>
                            </div>
                            <div class="flexOnly">
                                <i class="fas fa-minus dangerColor marginRight8" aria-hidden="true" />
                                <p>0%</p>
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive new-table-container-analysis">
                        <div class="scrollable-table-wrapper flex border-0">
                            <div class="new-left-table-scrollbar-TRAT-team-analysis" @scroll="handleLeftScroll">
                                <div style="height: 0.1px" />
                            </div>
                            <div class="new-right-table-scrollbar-TRAT-team-analysis">
                                <div style="height: 0.1px" />
                            </div>
                        </div>

                        <div class="scrollable-table-wrapper flex" tabindex="0">
                            <div class="new-left-table-TRAT-team-analysis" @scroll="handleLeftScroll">
                                <caption class="visible-hidden">
                                    Team Analysis table
                                </caption>

                                <div class="sticky-top-header zIndex1001">Details</div>
                                <table class="table">
                                    <thead class="zIndex1001">
                                        <tr class="sticky">
                                            <th v-if="canClearAnswers" />
                                            <th>
                                                Team
                                                <template v-if="sort === 'team'">
                                                    <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('team')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else aria-label="desc" @click.prevent="toggle('team')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else aria-label="sort" @click.prevent="toggle('team')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                            <th class="width112px">
                                                Reporter
                                                <template v-if="sort === 'reporter'">
                                                    <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('reporter')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else aria-label="desc" @click.prevent="toggle('reporter')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else aria-label="sort" @click.prevent="toggle('reporter')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                            <th>
                                                Status
                                                <template v-if="sort === 'status'">
                                                    <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('status')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else aria-label="desc" @click.prevent="toggle('status')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else aria-label="sort" @click.prevent="toggle('status')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                            <th>
                                                % Correct
                                                <template v-if="sort === 'finishedQuestionPercentCorrect'">
                                                    <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('finishedQuestionPercentCorrect')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else aria-label="desc" @click.prevent="toggle('finishedQuestionPercentCorrect')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else aria-label="sort" @click.prevent="toggle('finishedQuestionPercentCorrect')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                            <th>
                                                Score
                                                <template v-if="sort === 'totalPoint'">
                                                    <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('totalPoint')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else aria-label="desc" @click.prevent="toggle('totalPoint')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else aria-label="sort" @click.prevent="toggle('totalPoint')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(team, idx) in teams" :key="idx">
                                            <template v-if="team.members && team.members.length > 0">
                                                <td v-if="canClearAnswers">
                                                    <div class="dropdown">
                                                        <i
                                                            class="fas fa-grip-vertical pointer"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        />

                                                        <div class="dropdown-menu dropdown-menu-new capitalize" aria-labelledby="dropdownMenuButton">
                                                            <ul>
                                                                <li>
                                                                    <a
                                                                        v-tooltip="'Clear ' + team.team + ' Answers'"
                                                                        class="danger-state"
                                                                        href="#"
                                                                        :aria-label="'Clear ' + team.team + ' Answers'"
                                                                        @click.prevent="clearAnswers(team)"
                                                                    >
                                                                        <span> <i class="fa fa-trash-o marginRight8" aria-hidden="true" />Clear Answers </span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td :aria-label="'row ' + idx + 'team name ' + team.team" tabindex="0">
                                                    {{ team.team }}
                                                </td>
                                                <td>
                                                    <div class="flexOnly align-items">
                                                        <select @change="changeTeamLeader(idx, $event.target.value)">
                                                            <template v-for="(member, member_idx) in team.members" :key="member.uuid">
                                                                <option :value="member_idx" :selected="member.isReporter">
                                                                    {{ member.displayName }}
                                                                </option>
                                                            </template>
                                                        </select>
                                                        <i
                                                            v-if="
                                                                testObj.teamsNotAllowedToUpdateTeamLeader &&
                                                                testObj.teamsNotAllowedToUpdateTeamLeader.includes(team.uuid)
                                                            "
                                                            :aria-label="'unfreeze team reporter for ' + team.team"
                                                            class="fas fa-lock red"
                                                            style="margin-left: 10px"
                                                            tabindex="0"
                                                            @click="freezeUnfreezeTeamLeader(idx)"
                                                            @keyup.enter="freezeUnfreezeTeamLeader(idx)"
                                                        />
                                                        <i
                                                            v-else
                                                            :aria-label="'freeze team team report for ' + team.team"
                                                            class="fas fa-unlock green"
                                                            style="margin-left: 10px"
                                                            tabindex="0"
                                                            @click="freezeUnfreezeTeamLeader(idx)"
                                                            @keyup.enter="freezeUnfreezeTeamLeader(idx)"
                                                        />
                                                    </div>
                                                </td>
                                                <td tabindex="0" :aria-label="'row ' + idx + 'team status ' + team.status">
                                                    {{ team.status }}
                                                </td>
                                                <td tabindex="0" :aria-label="'row ' + idx + ' ' + team.finishedOnPercentCorrect + '% Correct'">
                                                    {{ team.finishedQuestionPercentCorrect ? team.finishedQuestionPercentCorrect.toFixed(1) : '0.0' }}%
                                                </td>
                                                <td tabindex="0" :aria-label="'row ' + idx + ' score ' + team.totalPoint">
                                                    {{ team.totalPoint }}
                                                </td>
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <scroll-box-shadow
                                class="new-right-table-TRAT-team-analysis"
                                :options="{
                                    setScrollBar: true,
                                }"
                            >
                                <div class="sticky-top-header">Questions</div>
                                <table class="table">
                                    <thead>
                                        <tr class="sticky">
                                            <th v-for="(question, idx) in questions" :key="'team-analysis-' + idx" tabindex="0">
                                                <span class="buttonGroupInline">
                                                    <button
                                                        class="btn gradient btn-default"
                                                        :aria-label="'Question number ' + (idx + 1) + ' click to view the question'"
                                                        @click.prevent="openQuestionInfoModal(question)"
                                                    >
                                                        {{ idx + 1 }}.<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                                    </button>
                                                    <template v-for="(option, option_idx) in question.options">
                                                        <div
                                                            v-if="option.isCorrect"
                                                            class="statusTag tagCorrect marginLeft10 whiteSpaceNowrap"
                                                            :aria-label="'correct option ' + option.key"
                                                        >
                                                            <span class="paddingTop2">{{ option.key }}</span>
                                                            <i class="fa fa-check-circle marginLeft8" aria-hidden="true" />
                                                        </div>
                                                    </template>
                                                    <template v-for="newAnswerIdx in testObj.acceptedNewAnswers">
                                                        <template v-if="newAnswerIdx.activityQuestionId == question.id">
                                                            <div
                                                                class="statusTag tagNewCorrect marginLeft10 whiteSpaceNowrap"
                                                                :aria-label="'accepted options ' + newAns"
                                                            >
                                                                <template v-for="(newAns, newAnsIdx) in newAnswerIdx.acceptedAnswers">
                                                                    <span class="paddingTop2">
                                                                        {{ newAns }}
                                                                        <template v-if="newAnsIdx != newAnswerIdx.acceptedAnswers.length - 1">&nbsp;</template>
                                                                    </span>
                                                                </template>
                                                                <i class="fa fa-check-circle marginLeft8" aria-hidden="true" />
                                                            </div>
                                                        </template>
                                                    </template>
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(team, idx) in teams" :key="'team-' + team.id">
                                            <template v-for="(question, question_idx) in team.questions" :key="'team-' + team.id + '-question-' + question_idx">
                                                <template v-if="team.members && team.members.length > 0">
                                                    <template v-if="question.attempts && question.attempts.length != 0">
                                                        <td tabindex="0">
                                                            <div
                                                                class="statusTag whiteSpaceNowrap"
                                                                :class="{
                                                                    tagCorrect: question.percent == 100 && question.isCorrect,
                                                                    tagNewCorrect: optionCorrect(question) && question.percent == 100,
                                                                    tagPrimary:
                                                                        (question.percent >= 50 && question.percent < 100 && question.isCorrect) ||
                                                                        (question.percent >= 50 && question.percent < 100),
                                                                    tagWarning:
                                                                        (question.percent < 50 && question.percent > 0 && question.isCorrect) ||
                                                                        (question.percent < 50 && question.percent > 0),
                                                                    tagIncorrect: (question.percent == 0 && question.isCorrect) || question.percent == 0,
                                                                }"
                                                            >
                                                                <template v-for="(key, idx) in question.attempts" :key="'attempts-' + idx">
                                                                    <span class="paddingTop2">
                                                                        {{ key }}
                                                                        <template v-if="idx != question.attempts.length - 1">&nbsp;</template>
                                                                    </span>
                                                                </template>
                                                            </div>
                                                        </td>
                                                    </template>
                                                    <template v-else>
                                                        <td class="fixedHeight69px" tabindex="0" />
                                                    </template>
                                                </template>
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>
                            </scroll-box-shadow>
                        </div>

                        <div class="scrollable-table-wrapper flex border-0">
                            <div class="new-left-table-scrollbar-TRAT-team-analysis" @scroll="handleLeftScroll">
                                <div style="height: 0.1px" />
                            </div>
                            <div class="new-right-table-scrollbar-TRAT-team-analysis">
                                <div style="height: 0.1px" />
                            </div>
                        </div>
                    </div>
                </div>
                <vcl-table v-else />
            </template>
        </kr-panel>
        <div id="teamAnalysisQuestionInfoModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="teamAnalysisQuestionInfoModal-title">
            <div class="modal-dialog" :class="{ 'widthAuto maxWidth1000px': ['mcqs', 'mcqm'].includes(previewQuestion.type) }">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="teamAnalysisQuestionInfoModal-title" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true"></i>View Question
                        </h2>
                    </div>
                    <div class="modal-body">
                        <label class="control-label"> Question {{ getQuestionNo(previewQuestion.group - 1, previewQuestion.order - 1) }} </label>

                        <question-display :test-obj="testObj" :question="previewQuestion" />

                        <div v-if="['mcqs', 'mcqm'].includes(previewQuestion.type)" class="mcqStatisticsBarChart marginTop20">
                            <div class="panel-header">
                                <div>Answer Choices</div>
                            </div>
                            <div>
                                <table class="barChart">
                                    <tbody class="body">
                                        <template v-for="(option, option_idx) in previewQuestion.optionKeys">
                                            <tr>
                                                <div class="percentage" :style="'bottom:' + (option.percent.toFixed(0) / 100) * 80 + '%'">
                                                    {{ round(option.percent) }}%
                                                </div>
                                                <td
                                                    class="bars"
                                                    :class="{
                                                        correct: option.isCorrect,
                                                        wrong: !option.isCorrect,
                                                    }"
                                                    :style="'height:' + (option.percent.toFixed(0) / 100) * 80 + '%;'"
                                                />
                                                <td class="record">
                                                    <div>Choice {{ option.key }}</div>
                                                    <template v-for="(model, model_idx) in option.answersPerOption">
                                                        <template v-if="model.team">
                                                            <div
                                                                v-tooltip="model.team.name"
                                                                class="btn btnDiscussionTeams marginTop10 cursor-norm"
                                                                tabindex="0"
                                                            >
                                                                <i class="fa fa-user marginRight8" aria-hidden="true" />
                                                                <label>{{ model.team.name }}</label>
                                                            </div>
                                                        </template>
                                                    </template>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div>

        <div
            id="teamAnalysisClearAnswersModal"
            class="modal danger-modal clearAnswersModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="clearAnswersModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="clearAnswersModal-title" class="modal-title">Clear Answers</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                        <h2 class="margin0 marginTop40">Clear Answers For</h2>
                        <h2 class="fw-normal">
                            {{ teamToClear.team }}
                        </h2>
                        <br />
                        <p>You are about to clear all</p>
                        <p>answers that were submitted</p>
                        <p>
                            by <b>{{ teamToClear.team }}</b> for this activity.
                        </p>

                        <br />

                        <p>Do you want to proceed?</p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="clear()">Yes, Clear</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { VclTable } from 'vue-content-loading';
import { testMixins } from '../../../../../../../../mixins/test';

export default {
    mixins: [testMixins],
    props: ['testObj'],
    data() {
        return {
            component_done_loading: false,
            questions: [],
            previewQuestion: {},
            teamAnalysis: null,
            teams: [],
            order: 'asc',
            sort: 'rank',
            debouncedUpdate: null,
            lastUpdateTimestamp: '',
            initialized: false,
            teamToClear: {},
        };
    },
    created() {
        var that = this;
        this.debouncedUpdate = _.debounce(
            function () {
                this.fetchTest().then(function (response) {
                    if (!that.lastUpdateTimestamp) {
                        for (var i = 0; i < response.data.data.teamAnalysis.teams.length; i++) {
                            response.data.data.teamAnalysis.teams[i].rank = i + 1;
                        }
                    }
                    that.lastUpdateTimestamp = moment.utc(response.headers['date'], 'ddd, DD MMM YYYY HH:mm:ss [GMT]', true).format('YYYY-MM-DD HH:mm:ss');
                    if (that.teamAnalysis == null) {
                        that.teamAnalysis = response.data.data.teamAnalysis;
                        _.forOwn(that.testObj.attendance.groupedStudents, function (value, key) {
                            let idx = _.findIndex(that.teamAnalysis.teams, function (o) {
                                return value.uuid == o.uuid;
                            });
                            that.teamAnalysis.teams[idx].members = value.members;
                        });
                    } else {
                        that.teamAnalysis.teams = that.mergeArrayOfObjectsWithKey(that.teamAnalysis.teams, response.data.data.teamAnalysis.teams, 'id');
                        /*Vue.set(
                            that.teamAnalysis,
                            'teams',
                            that.mergeArrayOfObjectsWithKey(that.teamAnalysis.teams, response.data.data.teamAnalysis.teams, 'id')
                        );*/
                        that.initTeamFinishedQuestionPercentCorrect(response.data.data.teamAnalysis);
                    }
                    //that.recomputeRank();
                    that.testObj.teamAnalysis = that.teamAnalysis;
                    //Vue.set(that.testObj, 'teamAnalysis', that.teamAnalysis);

                    if (!that.component_done_loading) {
                        that.component_done_loading = true;
                        Vue.nextTick(function () {
                            $('#teamAnalysisQuestionInfoModal .modal-dialog').draggable({
                                handle: '.modal-header',
                            });
                        });
                    }
                    that.init();
                });
            },
            1000,
            { maxWait: 3000 }
        );
        that.debouncedUpdate();
        that.processEchoListeners();
    },
    mounted() {
        var that = this;

        this.$root.$on('fontResized', () => {
            that.alignTable();
        });
    },
    updated() {
        this.alignTable();
    },
    methods: {
        processEchoListeners() {
            var that = this;

            let h1 = (e) => {
                if (e.testUuid != that.testObj.uuid) {
                    return false;
                }
                that.debouncedUpdate();
            };
            let c1 = window.Echo.private(`test.${this.testObj.uuid}.teacher`).listen('TeamAnswerSaved', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TeamAnswerSaved',
                handler: h1,
            });

            let h2 = (e) => {
                if (e.testUuid != that.testObj.uuid) {
                    return false;
                }
                that.debouncedUpdate();
            };
            let c2 = window.Echo.private(`course.${this.testObj.course.uuid}.teacher`).listen('StudentTestStatusUpdated', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'StudentTestStatusUpdated',
                handler: h2,
            });
        },
        freezeUnfreezeTeamLeader(team_idx) {
            var that = this;
            let isFreeze = false;
            let teamsNotAllowedToUpdateTeamLeader = _.cloneDeep(this.testObj.teamsNotAllowedToUpdateTeamLeader);
            if (!this.testObj.teamsNotAllowedToUpdateTeamLeader.includes(this.teamAnalysis.teams[team_idx].uuid)) {
                isFreeze = true;
                teamsNotAllowedToUpdateTeamLeader.push(this.teamAnalysis.teams[team_idx].uuid);
            } else {
                teamsNotAllowedToUpdateTeamLeader.pop(this.teamAnalysis.teams[team_idx].uuid);
            }
            axios
                .post(`tests/${this.testObj.uuid}/allow-update-team-leader`, {
                    teamsNotAllowedToUpdateTeamLeader: teamsNotAllowedToUpdateTeamLeader,
                })
                .then(function (response) {
                    that.testObj.teamsNotAllowedToUpdateTeamLeader = teamsNotAllowedToUpdateTeamLeader;
                    that.testObj.teamsNotAllowedToUpdateTeamLeader.splice();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Successfully ' + (isFreeze ? 'frozen' : 'unfrozen') + ' team',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'danger',
                        title: 'Error',
                        text: 'Something went wrong. Please try again later',
                    });
                });
        },
        changeTeamLeader(team_idx, student_idx) {
            var that = this;
            axios
                .post(`/tests/${this.testObj.uuid}/team-leader`, {
                    userPlacementTestUuid: this.teamAnalysis.teams[team_idx].members[student_idx].studentUuid,
                })
                .then(function (response) {
                    for (var i = 0; i < that.teamAnalysis.teams[team_idx].members.length; i++) {
                        if (i != student_idx) {
                            that.teamAnalysis.teams[team_idx].members[i].isReporter = false;
                        } else {
                            that.teamAnalysis.teams[team_idx].members[i].isReporter = true;
                        }
                    }
                    that.teamAnalysis.teams.splice();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Team leader sucessfully changed',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'danger',
                        title: 'Error',
                        text: 'Something went wrong. Please try again later',
                    });
                });
        },
        recomputeRank() {
            if (this.initialized) {
                this.teamAnalysis.teams = _.sortBy(this.teamAnalysis.teams, function (o) {
                    let parsed = parseFloat(o.finishedQuestionPercentCorrect);
                    if (Number.isNaN(parsed)) {
                        parsed = 0;
                    }
                    return parsed;
                }).reverse();
            }

            for (var i = 0; i < this.teamAnalysis.teams.length; i++) {
                this.teamAnalysis.teams[i].rank = i + 1;
                //Vue.set(this.teamAnalysis.teams[i], 'rank', i + 1);
            }
            this.initialized = true;
        },
        initTeamFinishedQuestionPercentCorrect(newTeamAnalysis) {
            for (var i = 0; i < newTeamAnalysis.teams.length; i++) {
                let idx = _.findIndex(this.teamAnalysis.teams, function (o) {
                    return o.team == newTeamAnalysis.teams[i].team;
                });
                if (idx != -1) {
                    this.teamAnalysis.teamFinishedQuestionPercentCorrect[idx] = newTeamAnalysis.teamFinishedQuestionPercentCorrect[i];
                    //Vue.set(this.teamAnalysis.teamFinishedQuestionPercentCorrect, idx, newTeamAnalysis.teamFinishedQuestionPercentCorrect[i]);
                }
            }
        },
        fetchTest() {
            return axios.get(
                '/tests/' + this.$route.params.id + '/dashboard/students' + (this.lastUpdateTimestamp != '' ? `?timestamp=${this.lastUpdateTimestamp}` : ''),
                { _internal: { hasLoadingBar: false } }
            );
        },
        init() {
            if (_.isEmpty(this.teamAnalysis)) {
                this.teamAnalysis = { teams: [] };
            }
            if (_.isEmpty(this.questions)) {
                this.questions = _.flatten(this.testObj.questions);
            }
            this.teams = _.clone(this.teamAnalysis.teams);

            for (var i = 0; i < this.teams.length; i++) {
                if (this.teams[i].finishedQuestionPercentCorrect == null) {
                    this.teams[i].finishedQuestionPercentCorrect = 0;
                }
            }
            this.sortList();
            var that = this;
            Vue.nextTick(function () {
                that.alignTable();

                $('.new-right-table-TRAT-team-analysis div').on('scroll', function () {
                    $('.new-right-table-scrollbar-TRAT-team-analysis').scrollLeft($(this).scrollLeft());
                });

                $('.new-right-table-scrollbar-TRAT-team-analysis').on('scroll', function () {
                    $('.new-right-table-TRAT-team-analysis div').scrollLeft($(this).scrollLeft());
                });
            });
        },
        sortList() {
            let sort = this.sort;
            if (sort != 'team') {
                this.teams = _.orderBy(this.teams, [sort], [this.order]);
            } else {
                this.natSort(this.teams, 'team');
                if (this.order == 'desc') {
                    _.reverse(this.teams);
                }
            }

            var that = this;
            Vue.nextTick(function () {
                that.alignTable();
            });
        },
        toggle(column = '') {
            if (this.sort == column) {
                if (this.order == 'asc') {
                    this.order = 'desc';
                } else {
                    this.order = 'asc';
                }
            } else {
                this.order = 'asc';
                this.sort = column;
            }
            this.sortList();
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question;
            $('#teamAnalysisQuestionInfoModal').modal('show');
        },
        alignTable() {
            $('.new-table-container-analysis .new-left-table-TRAT-team-analysis thead tr').each(function (index) {
                var theadOneHeight = $(this).height();
                var theadTwo = $('.new-table-container-analysis .new-right-table-TRAT-team-analysis thead tr:eq(' + index + ')');

                if (!theadTwo.length) return false;

                var theadTwoHeight = theadTwo.height();

                if (theadOneHeight > theadTwoHeight) {
                    theadTwo.height(theadOneHeight);
                } else {
                    $(this).height(theadTwoHeight);
                }
            });

            $('.new-table-container-analysis .new-left-table-TRAT-team-analysis tbody tr').each(function (index) {
                var rowOneHeight = $(this).height();
                var rowTwo = $('.new-table-container-analysis .new-right-table-TRAT-team-analysis tbody tr:eq(' + index + ')');

                if (!rowTwo.length) return false;

                var rowTwoHeight = rowTwo.height();

                if (rowOneHeight > rowTwoHeight) {
                    rowTwo.height(rowOneHeight);
                } else {
                    $(this).height(rowTwoHeight);
                }
            });

            $('.new-table-container-analysis .new-right-table-TRAT-team-analysis div').scroll(function () {
                $('.new-table-container-analysis .new-left-table-TRAT-team-analysis').scrollTop($(this).scrollTop());
            });

            $('.new-table-container-analysis .new-left-table-TRAT-team-analysis').scroll(function () {
                $('.new-table-container-analysis .new-right-table-TRAT-team-analysis div').scrollTop($(this).scrollTop());
            });

            var widthOfTheLeftTable = $('.new-table-container-analysis .new-left-table-TRAT-team-analysis table').width();
            $('.new-table-container-analysis .new-left-table-scrollbar-TRAT-team-analysis div').width(widthOfTheLeftTable + 'px');

            var widthOfTheRightTable = $('.new-table-container-analysis .new-right-table-TRAT-team-analysis table').width();
            $('.new-table-container-analysis .new-right-table-scrollbar-TRAT-team-analysis div').width(widthOfTheRightTable + 'px');
        },
        clearAnswers(team) {
            this.teamToClear = team;
            $('#teamAnalysisClearAnswersModal').modal('show');
        },
        clear() {
            var that = this;
            let data = {};
            data.teamUuid = this.teamToClear.uuid;
            axios
                .post(`tests/${this.testObj.uuid}/reset-answers`, data)
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Answers have been cleared',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'danger',
                        title: 'Error',
                        text: 'Something went wrong, please try again later',
                    });
                });
        },
        handleLeftScroll(event) {
            $('.new-left-table-TRAT-team-analysis').scrollLeft(event.target.scrollLeft);
            $('.new-left-table-scrollbar-TRAT-team-analysis').scrollLeft(event.target.scrollLeft);
        },
        handleRightScroll(event) {
            $('.new-right-table-TRAT-team-analysis div').scrollLeft(event.target.scrollLeft);
            $('.new-right-table-scrollbar-TRAT-team-analysis').scrollLeft(event.target.scrollLeft);
        },
        optionCorrect(question) {
            if (['ended', 'completed'].includes(this.testObj.status) && question.type == 'mcqs') {
                for (var i = 0; i < this.testObj.acceptedNewAnswers.length; i++) {
                    if (this.testObj.acceptedNewAnswers[i].activityQuestionId == question.id) {
                        return this.testObj.acceptedNewAnswers[i].acceptedAnswers.some((value) => question.attempts.includes(value));
                    }
                }
            }
        },
    },
    components: {
        'question-display': require(`./../../../../../../questions/partials/question-display.vue`).default,
        VclTable,
    },
    computed: {
        canClearAnswers() {
            if (['scheduled', 'not started'].includes(this.testObj.status)) {
                return false;
            } else {
                return true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
i.fa-trophy {
    display: inline-block;
    border-radius: 60px;
    padding: 0.5em;
    color: #fff;
    font-size: 8px;
}

.studentAnalysis .first {
    background-color: #fdfbf4;
}

.first i.fa-trophy {
    box-shadow: 0px 0px 2px #dab025;
    background: #facd36;
    border: 1px solid #dab025;
}

.studentAnalysis .second {
    background-color: #fbfbfb;
}

.second i.fa-trophy {
    box-shadow: 0px 0px 2px #a3a3a3;
    background: #dadada;
    border: 1px solid #a3a3a3;
}

.studentAnalysis .third {
    background-color: #fdf8f3;
}

.third i.fa-trophy {
    box-shadow: 0px 0px 2px #9c4317;
    background: #c6937a;
    border: 1px solid #9c4317;
}

.fixedHeight69px {
    height: 69px;
}

.openended-space {
    width: 250px;
    height: 50px;
    overflow: hidden;
}

.tablewDiv .table {
    margin-bottom: 0;
    position: relative;
}

.tablewDiv .tableDiv-app {
    overflow-x: auto;
    height: max-content;
}
.tablewDiv .tableDiv-app .table {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
}

.tablewDiv .tableDiv-app1 .table {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 2px 0 3px 0 rgba(174, 174, 174, 0.5);
}

.tableDiv-app1 {
    min-width: 618px;
    width: 618px;
}

.tableDiv-app {
    width: calc(100% - 618px);
}

.tableDiv-app1 .table > thead > tr > th,
.tableDiv-app1 .table > thead > tr > td,
.tableDiv-app1 .table > tbody > tr > th,
.tableDiv-app1 .table > tbody > tr > td,
.tableDiv-app1 .table > tfoot > tr > th,
.tableDiv-app1 .table > tfoot > tr > th,
.tableDiv-app .table > thead > tr > th,
.tableDiv-app .table > thead > tr > td,
.tableDiv-app .table > tbody > tr > th,
.tableDiv-app .table > tbody > tr > td,
.tableDiv-app .table > tfoot > tr > th,
.tableDiv-app .table > tfoot > tr > th {
    padding: 15px;
}

thead tr.sticky th {
    position: sticky;
    top: 0;
    z-index: 30;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
}

table {
    border-top: 0;
}

#header-fixed,
#teamAnalysisTRAT-clone-left-fixed {
    position: relative;
    top: 0px;
    display: none;
    background-color: white;
    z-index: 31;
    overflow-x: auto;
    border: none;
}

#teamAnalysisTRAT-clone-left-fixed {
    border-right: 1px solid #ddd;
}

#teamAnalysisTRAT-clone-left-fixed {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-left: 0;
}

#teamAnalysisTRAT-clone-left {
    border-top-left-radius: 0;
    border-left: 0;
    border-bottom: 0;
    box-shadow: none;
}

#teamAnalysisTRAT-clone-right {
    border-right: 0;
    border-bottom: 0;
    box-shadow: none;
}

#teamAnalysisTRATOverall {
    border: 1px solid #ddd;
    border-radius: 3px;
}

#teamAnalysisTRAT-tableOG::-webkit-scrollbar {
    width: 0px;
    height: 0;
    background: transparent;
}

.width112px {
    width: 112px;
}

.nameColumn {
    word-wrap: break-word;
    max-width: 112px;
}

/* new table */

.new-table-container-analysis {
    table {
        border: 0;

        thead {
            position: sticky;
            top: 52.84px;

            th {
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
            }

            &:first-child {
                & > tr:first-child th {
                    border-top: inherit;
                }
            }
        }

        tbody {
            tr:first-child {
                td {
                    border-top: 0;
                }
            }
        }
    }

    .sticky-top-header {
        position: sticky;
        top: 0;
        left: 0;
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
        text-transform: uppercase;
    }

    .new-left-table-TRAT-team-analysis {
        min-width: 640px;
        width: 640px;
        max-height: 500px;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    .new-right-table-TRAT-team-analysis {
        width: calc(100% - 640px);
        max-height: 500px;
        scrollbar-width: none;

        .sticky-top-header {
            left: 0;
        }
    }

    .new-left-table-scrollbar-TRAT-team-analysis {
        width: 640px;
    }

    .new-right-table-scrollbar-TRAT-team-analysis {
        width: calc(100% - 640px);
    }
    /* Landscape tablets and medium desktops */
    @media (max-width: 991px) {
        .new-left-table-TRAT-team-analysis {
            min-width: 75%;
            width: 75%;
        }
        .new-right-table-TRAT-team-analysis {
            width: 25%;
        }
        .new-left-table-scrollbar-TRAT-team-analysis {
            width: 75%;
        }
        .new-right-table-scrollbar-TRAT-team-analysis {
            width: 25%;
        }
    }
    .scrollable-table-wrapper {
        border-radius: 3px;
        overflow: hidden;

        &:first-child,
        &:last-child {
            .new-left-table-scrollbar-TRAT-team-analysis,
            .new-right-table-scrollbar-TRAT-team-analysis {
                border: 0;
            }
        }

        & > div {
            border: 1px solid #ddd;
            overflow: auto;

            &:not(:first-child) {
                border-left: 0;
            }
        }
    }
}

select {
    width: 80px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select {
        padding-right: 0;
    }
}
</style>
