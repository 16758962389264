<template>
    <div ref="modal" class="modal default-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="viewLogs-title" aria-modal="true" hidden>
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>
                    <h2 id="viewLogs-title" class="modal-title">View Logs</h2>
                </div>

                <div class="modal-body">
                    <div class="alert alert-primary marginBottom20" role="alert">
                        <i class="fa-solid fa-circle-info marginRight8" aria-hidden="true"></i>
                        <span> <b>Please note: </b> Only logs from the last 30 days will be displayed. Older logs are automatically removed. </span>
                    </div>
                    <div class="logs-container" role="log" aria-label="Activity Timeline">
                        <div v-for="(log, idx) in logs" :key="idx" class="logs-item">
                            <div class="logs-time">
                                <p>
                                    {{ convertToReadableDate(log.createdAt, 'DD MMM YYYY, hh:mm a').date }} ({{
                                        convertToReadableDate(log.createdAt, 'DD MMM YYYY, hh:mm a').current_timezone
                                    }})
                                </p>
                                <small class="fontGrey">
                                    <em>{{ parseCreatedAt(log.createdAt) }}</em>
                                </small>
                            </div>
                            <div class="position-relative">
                                <div class="vertical-line"></div>
                                <div class="horizontal-line"></div>
                                <div class="logs-box">
                                    <p>
                                        {{ log.description }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeModal()">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, nextTick } from 'vue';

const modal = ref(null);
const course = ref({});
const logs = ref([]);

const view = (c = {}) => {
    course.value = c;
    fetchLogs();
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const fetchLogs = () => {
    axios.get(`courses/${course.value.uuid}/collaborators/logs`).then((response) => {
        logs.value = response.data.data;
    });
};

const parseCreatedAt = (date) => {
    if (!date) return '';

    const noteTimeUTC = moment.utc(date);

    const relativeTime = noteTimeUTC.fromNow();
    return `${relativeTime}`;
};

defineExpose({ view });
</script>
