<template>
    <div class="slider-new" :class="{ hasButtons: includeButtons && !isRangeOnly }">
        <button
            v-if="includeButtons && !isRangeOnly"
            class="btn btn-outline-dark marginRight10"
            :disabled="value == setMin || isDisabled"
            tabindex="-1"
            @click="changeValue(value--)"
        >
            <i class="material-icons" role="img" aria-label="Decrease Value"> remove </i>
        </button>

        <div ref="sliderContainer" class="slider-content" :class="{ rangeOnly: isRangeOnly }">
            <template v-if="isRangeOnly">
                <template v-if="showNumberInput">
                    <input
                        v-model="sliderMinValue"
                        class="form-control minField"
                        type="number"
                        :min="setMin"
                        :max="setMax"
                        :disabled="isDisabled"
                        @input="rangeChangeValue"
                    />

                    <input
                        v-model="sliderMaxValue"
                        class="form-control maxField"
                        type="number"
                        :min="setMin"
                        :max="setMax"
                        :disabled="isDisabled"
                        @input="rangeChangeValue"
                    />
                </template>
                <template v-else>
                    <label v-if="!showNumberInput" :for="sliderIdx + '-label-min'" class="slider-label minField">
                        {{ formatValueText(sliderMinValue) }}
                    </label>

                    <label v-if="!showNumberInput" :for="sliderIdx + '-label-max'" class="slider-label maxField">
                        {{ formatValueText(sliderMaxValue) }}
                    </label>
                </template>

                <div ref="rangeProgressColor" class="range-color" aria-hidden="true" :class="{ disabled: isDisabled }"></div>
                <input
                    :id="sliderIdx + '-label-min'"
                    :aria-labelledby="sliderIdx"
                    v-model.number="sliderMinValue"
                    type="range"
                    :min="setMin"
                    :max="setMax"
                    :aria-valuenow="sliderMinValue"
                    :aria-valuemin="setMin"
                    :aria-valuemax="setMax"
                    :aria-valuetext="'Minimum Value ' + formatValueText(sliderMinValue)"
                    :step="hasStep ? stepValue : ''"
                    :aria-step="hasStep ? stepValue : ''"
                    :disabled="isDisabled"
                    :tabIndex="showNumberInput ? -1 : ''"
                    @input="rangeChangeValue"
                />
                <input
                    :id="sliderIdx + '-label-max'"
                    :aria-labelledby="sliderIdx"
                    v-model.number="sliderMaxValue"
                    type="range"
                    :min="setMin"
                    :max="setMax"
                    :aria-valuenow="sliderMaxValue"
                    :aria-valuemin="setMin"
                    :aria-valuemax="setMax"
                    :aria-valuetext="'Maximum Value ' + formatValueText(sliderMaxValue)"
                    :step="hasStep ? stepValue : ''"
                    :aria-step="hasStep ? stepValue : ''"
                    :disabled="isDisabled"
                    :tabIndex="showNumberInput ? -1 : ''"
                    @input="rangeChangeValue"
                />
            </template>
            <template v-else>
                <input
                    v-if="showNumberInput"
                    v-model.number="value"
                    class="form-control"
                    type="number"
                    :min="setMin"
                    :max="setMax"
                    :disabled="isDisabled"
                    @input="changeValue"
                />

                <div v-if="!isFirefox" ref="sliderProgressColor" class="range-color" aria-hidden="true" :class="{ disabled: isDisabled }"></div>

                <input
                    :id="sliderIdx + '-label'"
                    :aria-labelledby="sliderIdx"
                    v-model.number="value"
                    type="range"
                    :min="setMin"
                    :max="setMax"
                    :aria-valuenow="value"
                    :aria-valuemin="setMin"
                    :aria-valuemax="setMax"
                    :aria-valuetext="value + ' ' + valueText + ' for ' + sliderName"
                    :step="hasStep ? stepValue : ''"
                    :aria-step="hasStep ? stepValue : ''"
                    :disabled="isDisabled"
                    :tabIndex="showNumberInput ? -1 : ''"
                    @input="changeValue"
                />
            </template>
        </div>

        <button
            v-if="includeButtons && !isRangeOnly"
            class="btn btn-outline-dark marginLeft10"
            :disabled="value == setMax || isDisabled || leftPoints == 0"
            tabindex="-1"
            @click="changeValue(value++)"
        >
            <i class="material-icons" role="img" aria-label="Increase Value"> add </i>
        </button>
    </div>
</template>
<script>
export default {
    compatConfig: {
        MODE: 3,
    },
};
</script>
<script setup>
import { ref, onMounted, watch, computed } from 'vue';
const props = defineProps({
    modelValue: {
        type: Number,
        default: 0,
    },
    isDisabled: Boolean,
    isValid: Boolean,
    isInvalid: Boolean,
    setMin: {
        type: Number,
        default: 0,
    },
    setMax: {
        type: Number,
        default: 100,
    },
    includeButtons: Boolean,
    hasStep: {
        type: Boolean,
        default: false,
    },
    stepValue: {
        type: Number,
        default: 1,
    },
    isRangeOnly: Boolean,
    setRangeMinValue: {
        type: Number,
        default: 0,
    },
    setRangeMaxValue: {
        type: Number,
        default: 2000,
    },
    showNumberInput: Boolean,
    leftPoints: {
        type: Number,
        default: 100000000,
    },
    sliderIdx: {
        type: String,
        default: '',
    },
    valueText: {
        type: String,
        default: '',
    },
    sliderName: {
        type: String,
        default: '',
    },
    switchValueText: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue']);
const value = computed({
    get() {
        if (props.modelValue) {
            return props.modelValue;
        } else {
            return 0;
        }
    },
    set(value) {
        emit('update:modelValue', value);
    },
});
const sliderMinValue = ref(props.setRangeMinValue);
const sliderMaxValue = ref(props.setRangeMaxValue);

const setMinValue = ref(props.setMin);
const setMaxValue = ref(props.setMax);

const sliderContainer = ref(null);

const sliderProgressColor = ref(null);
const rangeProgressColor = ref(null);

const formatValueText = (value) => {
    const parts = [props.valueText, value];
    return props.switchValueText ? parts.join(' ') : parts.reverse().join(' ');
};

const changeValue = () => {
    const val = Number(value.value) || props.setMin;
    const min = props.setMin;
    const max = props.setMax;

    if (sliderContainer.value && sliderProgressColor.value) {
        const thumbWidth = 50;
        const myInput = sliderContainer.value.querySelector("input[type='number']");

        const adjustedVal = props.leftPoints <= 0 ? val + props.leftPoints : val;

        if (props.showNumberInput && myInput) {
            const containerWidth = sliderContainer.value.clientWidth - thumbWidth;
            const offset = containerWidth / (max - min);
            const px = (adjustedVal - min) * offset - myInput.clientWidth / 2 + thumbWidth / 2;
            myInput.style.left = `${px}px`;
        }

        const percent = ((adjustedVal - min) / (max - min)) * 100;
        sliderProgressColor.value.style.width = percent >= 50 ? `calc(${percent}% - 20px)` : `${percent}%`;
    }
};

const rangeChangeValue = () => {
    if (Number(sliderMinValue.value) >= Number(sliderMaxValue.value)) {
        if (sliderMinValue.value === sliderMaxValue.value) {
            if (sliderMinValue.value !== props.setMin) {
                sliderMaxValue.value = Math.min(Number(sliderMinValue.value) + 1, props.setMax);
            } else {
                sliderMinValue.value = Math.max(Number(sliderMaxValue.value) - 1, props.setMin);
            }
        } else {
            sliderMinValue.value = Math.max(Number(sliderMaxValue.value) - 1, props.setMin);
        }
    }

    const sliderMinVal = Number(sliderMinValue.value) || props.setMin;
    const sliderMaxVal = Number(sliderMaxValue.value) || props.setMax;
    const min = props.setMin;
    const max = props.setMax;

    const thumbWidth = 50;
    const myInputMinField = sliderContainer.value.querySelector('.minField');
    const myInputMaxField = sliderContainer.value.querySelector('.maxField');

    if (myInputMinField && myInputMaxField) {
        const containerWidth = sliderContainer.value.clientWidth - thumbWidth;
        const offset = containerWidth / (max - min);

        const pxMin = (sliderMinVal - min) * offset - myInputMinField.clientWidth / 2 + thumbWidth / 2;
        myInputMinField.style.left = `${pxMin}px`;

        const pxMax = (sliderMaxVal - min) * offset - myInputMaxField.clientWidth / 2 + thumbWidth / 2;
        myInputMaxField.style.left = `${pxMax}px`;
    }

    if (rangeProgressColor.value) {
        const leftSliderY = ((sliderMinVal - min) / (max - min)) * 100;
        const rightSliderY = ((sliderMaxVal - min) / (max - min)) * 100;
        const rangeWidth = rightSliderY - leftSliderY;

        rangeProgressColor.value.style.left =
            leftSliderY == 0 ? `calc(${leftSliderY}% + 10px)` : leftSliderY >= 50 ? `calc(${leftSliderY}% - 10px)` : `${leftSliderY}%`;
        rangeProgressColor.value.style.width = rangeWidth >= 50 ? `calc(${rangeWidth}% - 20px)` : `${rangeWidth}%`;
    }
};

onMounted(() => {
    if (props.isRangeOnly) {
        rangeChangeValue();
    } else {
        changeValue();
    }
});
watch(value, async (newValue, oldValue) => {
    if (props.isRangeOnly) {
        rangeChangeValue();
    } else {
        changeValue();
    }
});
</script>
