<template>
    <div
        ref="modal"
        class="modal danger-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="deleteActivityModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="deleteActivityModal-title" class="modal-title">Delete Activity</h2>
                </div>
                <div class="modal-body textAlignCenter">
                    <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                    <h2 class="marginTop40">Delete</h2>
                    <h3 class="marginBottom20 fw-normal">
                        {{ activity.name }}
                    </h3>

                    <p>All existing data (student answers, grades,</p>
                    <p>comments, attachments etc) for this activity</p>
                    <p>will be erased.</p>

                    <p class="marginTop20">Please download the grades for this</p>
                    <p>activity first if you wish to retain a copy.</p>

                    <p class="marginTop20">To confirm, type out your InteDashboard</p>
                    <p>email in the input below and</p>
                    <p>press the <b>Delete</b> button.</p>

                    <div class="checkboxField marginTop20 marginBottom20">
                        <input :id="'deleteActivity-' + activity.uuid" v-model="agree" type="checkbox" />
                        <label :for="'deleteActivity-' + activity.uuid" class="fw-normal">I understand and accept the risk!</label>
                    </div>

                    <div class="col-xs-12 textAlignLeft">
                        <label :for="'deleteActivity-' + activity.uuid + '-email'" class="control-label"> Type your email below to continue </label>
                        <div class="form-group">
                            <input
                                :id="'deleteActivity-' + activity.uuid + '-email'"
                                v-model="resetConfirmationField"
                                class="form-control"
                                :placeholder="`Type ${auth.user().email}`"
                            />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeModal()">No, Cancel</button>
                    <button
                        v-tooltip="canReset ? '' : 'You must check on the agreement and type in your email'"
                        class="btn btn-danger"
                        :class="{ 'disabled hasToolTip': !canReset }"
                        @click.prevent="canReset ? submit() : null"
                    >
                        Yes, Delete Activity
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick, computed } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import KrAuth from '../../../../components/auth/auth';

const auth = new KrAuth();

const modal = ref(null);
const activity = ref({});
const agree = ref(false);
const resetConfirmationField = ref('');

const canReset = computed(() => {
    return agree.value && resetConfirmationField.value === auth.user().email;
});

const deleteActivity = (a = {}) => {
    activity.value = a;

    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
        resetConfirmationField.value = '';
        agree.value = false;
    });
};

const submit = () => {
    axios
        .delete(`activities/${activity.value.uuid}`)
        .then(function (response) {
            closeModal();

            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Activity has successfully been deleted',
            });

            Events.fire('modules_listing_refresh');
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Unable to delete activity at this moment, please try again later',
            });
        });
};

defineExpose({ deleteActivity });
</script>
