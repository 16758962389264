import { AbilityBuilder, createMongoAbility } from '@casl/ability';

function defineCourseAbilities(coursePermissionList) {
    const { can, rules } = new AbilityBuilder(createMongoAbility);
    for (var i = 0; i < coursePermissionList?.length; i++) {
        can('get_role', 'Course-' + coursePermissionList[i].courseUuid, { role: coursePermissionList[i].roleName });

        for (var j = 0; j < coursePermissionList[i]?.permissions?.length; j++) {
            can(coursePermissionList[i]?.permissions[j], 'Course-' + coursePermissionList[i].courseUuid);
        }
    }
    return rules;
}

function defineRolesAbilities(roles) {
    const { can, rules } = new AbilityBuilder(createMongoAbility);
    for (var i = 0; i < roles?.length; i++) {
        can('get_color', roles[i].name, { color: roles[i].color });
    }
    return rules;
}

export { defineCourseAbilities, defineRolesAbilities };
