<template>
    <div v-if="!form.isHidden(name)" class="form-group" :class="{ 'has-error': form.errors.has(start) }">
        <label v-if="hasLabel" class="control-label">
            <span v-dompurify-html="display"></span>
        </label>
        <div class="input-group date">
            <input
                v-model="dpLabel"
                :name="name"
                type="text"
                class="form-control"
                :placeholder="placeholder"
                @keydown="form.errors.clear($event.target.name)"
            />
            <div class="input-group-addon">
                <i class="fa fa-calendar" :aria-label="placeholder"></i>
            </div>
        </div>
        <span v-show="form.errors.has(start)" class="help-block">
            <strong>{{ form.errors.get(start) }}</strong>
            <strong>{{ form.errors.get(end) }}</strong>
        </span>
    </div>
</template>
<script>
import daterangepicker from 'daterangepicker';
export default {
    props: ['display', 'form', 'name', 'start', 'end', 'options'],
    data: function () {
        return {
            dpLabel: '',
            hasChanged: false,
            time: false,
            placeholder: '',
            hasLabel: true,
        };
    },
    watch: {
        'form.model': {
            deep: true,
            handler(value, oldValue) {
                if (!_.get(this.form.model, this.start) && !_.get(this.form.model, this.end)) {
                    this.dpLabel = '';
                }
                this.initDateRangePicker();
            },
        },
    },
    created() {
        if (_.get(this, 'options.time', false)) {
            this.time = true;
        }
        //placeholder
        var placeholder = _.get(this, 'options.placeholder', false);
        if (placeholder) {
            this.placeholder = placeholder;
        }
        var label = _.get(this, 'options.hasLabel', null);
        if (label != null) {
            this.hasLabel = label;
        }
    },
    mounted() {
        this.initDateRangePicker();
    },
    beforeUnmount() {
        if ($('input[name="' + this.name + '"]').data('daterangepicker')) {
            $('input[name="' + this.name + '"]')
                .data('daterangepicker')
                .remove();
        }
    },
    methods: {
        initDateRangePicker() {
            var change = function (start, end, label) {
                this.dpLabel = start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY');
                this.form.errors.clear(this.start);
                this.form.errors.clear(this.end);
                _.set(this.form.model, this.start, start.utc().format('YYYY-MM-DD') + 'T00:00:00Z');
                _.set(this.form.model, this.end, end.utc().format('YYYY-MM-DD') + 'T00:00:00Z');
            }.bind(this);
            var changeWithTime = function (start, end, label) {
                this.dpLabel = start.format('YYYY-MM-DD HH:mm:ss') + ' - ' + end.format('YYYY-MM-DD HH:mm:ss');
                this.form.errors.clear(this.start);
                this.form.errors.clear(this.end);
                _.set(this.form.model, this.start, start.utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z');
                _.set(this.form.model, this.end, end.utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z');
            }.bind(this);

            if (!this.dpLabel && _.get(this.form.model, this.start) && _.get(this.form.model, this.end)) {
                var start = _.get(this.form.model, this.start);
                var end = _.get(this.form.model, this.end);
                if (this.time == false) {
                    start = this.convertToReadableDate(start, 'YYYY-MM-DD').date;
                    end = this.convertToReadableDate(end, 'YYYY-MM-DD').date;
                    this.dpLabel = start + ' - ' + end;
                    $('input[name="' + this.name + '"]').daterangepicker(
                        {
                            startDate: start,
                            endDate: end,
                            autoUpdateInput: false,
                            locale: {
                                format: 'YYYY-MM-DD',
                            },
                        },
                        function (start, end, label) {
                            change(start, end, label);
                        }
                    );
                } else {
                    start = this.convertToReadableDate(start, 'YYYY-MM-DD HH:mm:ss').date;
                    end = this.convertToReadableDate(end, 'YYYY-MM-DD HH:mm:ss').date;
                    this.dpLabel = start + ' - ' + end;
                    $('input[name="' + this.name + '"]').daterangepicker(
                        {
                            startDate: start,
                            endDate: end,
                            timePicker: true,
                            timePickerIncrement: 5,
                            autoUpdateInput: false,
                            locale: {
                                format: 'YYYY-MM-DD HH:mm:ss',
                            },
                        },
                        function (start, end, label) {
                            changeWithTime(start, end, label);
                        }
                    );
                }
            } else {
                if (this.time == false) {
                    $('input[name="' + this.name + '"]').daterangepicker(
                        {
                            autoUpdateInput: false,
                            locale: {
                                format: 'YYYY-MM-DD',
                            },
                        },
                        function (start, end, label) {
                            change(start, end, label);
                        }
                    );
                } else {
                    $('input[name="' + this.name + '"]').daterangepicker(
                        {
                            autoUpdateInput: false,
                            timePicker: true,
                            timePickerIncrement: 5,
                            locale: {
                                format: 'YYYY-MM-DD HH:mm:ss',
                            },
                        },
                        function (start, end, label) {
                            changeWithTime(start, end, label);
                        }
                    );
                }
            }
        },
    },
};
</script>
<style scoped></style>
