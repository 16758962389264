<template>
    <div
        v-if="component_done_loading"
        :id="question.uuid + '-question'"
        class="panel"
        :class="{
            'night-mode':
                question.type == 'openended' &&
                ['ongoing', 'ended', 'released'].includes(question.eGallery.status) &&
                $route.name == 'activities.tests.questions',
        }"
    >
        <div class="hidden-xs hidden-sm panel-heading flexSpaceBetween">
            <div class="flex align-items">
                <div class="flexOnly align-items">
                    <div class="marginRight8">
                        <h3 v-if="!showRealtimeToggle" class="fs-18px margin0">Question {{ getQuestionNo(question_group_idx, question_idx) }}</h3>
                        <h2 v-else class="fs-18px margin0">Question {{ getQuestionNo(question_group_idx, question_idx) }}</h2>
                    </div>
                    <div v-if="question.remarks && !showRealtimeToggle">
                        <button class="btn btn-default" @click.prevent="openFacultyNotesModal(question)">
                            <i class="fa-regular fa-note-sticky marginRight8" aria-hidden="true"></i>Question Notes
                        </button>
                    </div>
                </div>
                <question-pagination :test-obj="testObj" />
                <template v-if="question.type == 'openended' && showRealtimeToggle">
                    <div
                        v-if="[null, 'cancelled', 'preparing'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: !canStartEgallery
                                ? 'Please select at least two teams’ answers to enable e-Gallery Walk'
                                : '' || hasWritePrivilege(testObj.course) == -1
                                ? readOnlyLabel
                                : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            v-tooltip="
                                !$can('manage_test', 'Course-' + testObj?.course?.uuid)
                                    ? 'You are not allowed to manage this test'
                                    : !canStartEgallery
                                    ? 'Please select at least two teams’ answers to enable e-Gallery Walk'
                                    : question.displayAnswer == false
                                    ? 'Display answers is disabled'
                                    : ''
                            "
                            class="btn btn-primary"
                            :class="{
                                'disabled hasToolTip':
                                    !$can('manage_test', 'Course-' + testObj?.course?.uuid) || !canStartEgallery || question.displayAnswer == false,
                            }"
                            @click.prevent="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? prepare() : ''"
                        >
                            Initialize e-Gallery Walk
                        </button>
                    </div>
                    <div
                        v-if="['ongoing'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            v-tooltip="!$can('manage_test', 'Course-' + testObj?.course?.uuid) ? 'You are not allowed to manage this test' : ''"
                            class="btn btn-danger"
                            :class="{ 'disabled hasToolTip': !$can('manage_test', 'Course-' + testObj?.course?.uuid) }"
                            @click.prevent="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? openEndConfirmationModal() : ''"
                        >
                            Stop e-Gallery Walk<i class="fa fa-stop marginLeft8" aria-hidden="true" />
                        </button>
                    </div>
                    <div
                        v-if="['paused'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            v-tooltip="!$can('manage_test', 'Course-' + testObj?.course?.uuid) ? 'You are not allowed to manage this test' : ''"
                            class="btn btn-success"
                            :class="{ 'disabled hasToolTip': !$can('manage_test', 'Course-' + testObj?.course?.uuid) }"
                            @click.prevent="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? resume() : ''"
                        >
                            Resume e-Gallery Walk-Gallery Walk&nbsp;<i class="fa fa-caret-right" />
                        </button>
                    </div>
                    <div
                        v-if="['ended'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            v-tooltip="!$can('manage_test', 'Course-' + testObj?.course?.uuid) ? 'You are not allowed to manage this test' : ''"
                            class="btn btn-success"
                            :class="{ 'disabled hasToolTip': !$can('manage_test', 'Course-' + testObj?.course?.uuid) }"
                            @click.prevent="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? openReleaseConfirmationModal() : ''"
                        >
                            Release e-Gallery Walk Results<i class="fa fa-check marginLeft8" aria-hidden="true" />
                        </button>
                    </div>
                </template>
            </div>
            <div v-if="!['openended'].includes(question.type)" class="buttonGroupInline">
                <div class="switchToggle flexOnly align-items marginRight20">
                    <label :for="'showCorrectAnswer-' + question.uuid" class="control-label marginBottom0 marginTop10"> Show Correct Answer </label>
                    <label class="form-switch marginLeft8">
                        <input
                            v-tooltip="!$can('manage_test', 'Course-' + testObj?.course?.uuid) ? 'You are not allowed to manage this test' : ''"
                            :id="'showCorrectAnswer-' + question.uuid"
                            v-model="question.displayAnswer"
                            type="checkbox"
                            :class="{
                                'disabled hasToolTip': !$can('manage_test', 'Course-' + testObj?.course?.uuid),
                            }"
                            :aria-disabled="!$can('manage_test', 'Course-' + testObj?.course?.uuid)"
                            @change="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? toggleAnswers() : $event.preventDefault()"
                            @click="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? toggleAnswers() : $event.preventDefault()"
                            @keydown.space="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? toggleAnswers() : $event.preventDefault()"
                        />
                        <span role="switch" aria-checked="true" class="slider round" aria-label="Show Correct Answer" />
                    </label>
                </div>
                <div class="inlineFlex align-items">
                    <label :for="'showAnswerChoicesStatistic-' + question.uuid" class="control-label marginBottom0 marginTop10">
                        <template v-if="testObj.others.applicationType == 'individual'"> Show Students Answers </template>
                        <template v-else>Show Teams' Answers</template>
                    </label>
                    <label class="form-switch marginLeft8">
                        <input
                            v-tooltip="!$can('manage_test', 'Course-' + testObj?.course?.uuid) ? 'You are not allowed to manage this test' : ''"
                            :id="'showAnswerChoicesStatistic-' + question.uuid"
                            v-model="question.displayAnswerStatistics"
                            type="checkbox"
                            :class="{
                                'disabled hasToolTip': !$can('manage_test', 'Course-' + testObj?.course?.uuid),
                            }"
                            :aria-disabled="!$can('manage_test', 'Course-' + testObj?.course?.uuid)"
                            @change="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? toggleStatistics() : $event.preventDefault()"
                            @click="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? toggleStatistics() : $event.preventDefault()"
                            @keydown.space="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? toggleStatistics() : $event.preventDefault()"
                        />
                        <span role="switch" aria-checked="true" class="slider round" aria-label="Show Students Answers" />
                    </label>
                </div>
            </div>
            <div v-else-if="question.type == 'openended'" class="flexOnly">
                <template v-if="eGallerySettings.timeLimit && ['ongoing'].includes(question.eGallery.status)">
                    <div class="timer_Div darkGreen flex align-items justifyCenter">
                        <div>
                            <label class="timeText textAlignCenter"> Minutes </label>
                            <div class="time flex justifyCenter">
                                <div class="number-div">
                                    {{ time.minutes.charAt(0) }}
                                </div>
                                <div class="number-div">
                                    {{ time.minutes.charAt(1) }}
                                </div>
                            </div>
                        </div>

                        <div class="colon">:</div>

                        <div>
                            <label class="timeText textAlignCenter"> Seconds </label>
                            <div class="time flex justifyCenter">
                                <div class="number-div">
                                    {{ time.seconds.charAt(0) }}
                                </div>
                                <div class="number-div">
                                    {{ time.seconds.charAt(1) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="showRealtimeToggle">
                    <div v-if="['ongoing', 'ended', 'released'].includes(question.eGallery.status)" class="cr-dropdowns marginLeft20">
                        <button
                            v-tooltip="!$can('manage_test', 'Course-' + testObj?.course?.uuid) ? 'You are not allowed to manage this test' : ''"
                            :id="`dLabel-${question.uuid}`"
                            data-toggle="dropdown"
                            class="btn btn-primary"
                            :class="{ 'disabled hasToolTip': !$can('manage_test', 'Course-' + testObj?.course?.uuid) }"
                            data-target="#"
                            @click.prevent="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? closeAllSubMenus() : ''"
                        >
                            Actions
                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                        </button>
                        <ul class="dropdown-menu multiLevel" role="menu" aria-labelledby="dropdownMenu">
                            <li v-if="['ongoing'].includes(question.eGallery.status)" aria-label="Pause e-Gallery Walk">
                                <a href="#" @click.prevent="pause()">
                                    <span> <i class="fa fa-pause marginRight8" aria-hidden="true" />Pause e-Gallery Walk </span>
                                </a>
                            </li>
                            <li v-if="['ended', 'released', 'ongoing'].includes(question.eGallery.status)">
                                <a href="#" @click.prevent="openResetConfirmationModal()">
                                    <span> <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reset e-Gallery Walk </span>
                                </a>
                            </li>
                            <li v-if="['ended'].includes(question.eGallery.status)">
                                <a href="#" @click.prevent="openExtendModal()">
                                    <span> <i class="fa fa-clock marginRight8" aria-hidden="true" />Extend e-Gallery Walk </span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div
                        v-if="
                            [null, 'preparing', 'cancelled', 'ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status) && showRealtimeToggle
                        "
                        class="cr-dropdowns marginLeft20"
                        style="font-size: 30px"
                    >
                        <button
                            v-tooltip="!$can('manage_test', 'Course-' + testObj?.course?.uuid) ? 'You are not allowed to manage this test' : ''"
                            :id="`dLabel-${question.uuid}`"
                            data-toggle="dropdown"
                            class="btn btn-primary"
                            :class="{ 'disabled hasToolTip': !$can('manage_test', 'Course-' + testObj?.course?.uuid) }"
                            data-target="#"
                            @click.prevent="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? closeAllSubMenus() : ''"
                        >
                            View
                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                        </button>
                        <ul class="dropdown-menu multiLevel" role="menu" aria-labelledby="dropdownMenu">
                            <li v-if="showRealtimeToggle">
                                <div class="checkbox flex" data-index="0" role="link">
                                    <input
                                        :id="`reveal-ans-toggle-${question.uuid}`"
                                        v-model="question.displayAnswer"
                                        type="checkbox"
                                        @change="toggleAnswers(question)"
                                        @keyup.enter="question.displayAnswer = !question.displayAnswer"
                                    />
                                    <label :for="`reveal-ans-toggle-${question.uuid}`">
                                        <i class="fa" :class="{ 'fa-check': question.displayAnswer }" aria-hidden="true" />
                                        <span class="marginLeft8"
                                            >Show
                                            <template v-if="testObj.others.applicationType == 'individual'">Students’</template>
                                            <template v-else>Teams’</template>
                                            Answers
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li v-if="question.type == 'openended'">
                                <div class="checkbox flex">
                                    <input
                                        :id="`show-keywords-toggle-${question.uuid}`"
                                        v-model="question.displayKeywords"
                                        type="checkbox"
                                        @change="toggleKeywords()"
                                        @keyup.enter="question.displayKeywords = !question.displayKeywords"
                                    />
                                    <label :for="`show-keywords-toggle-${question.uuid}`">
                                        <i class="fa" :class="{ 'fa-check': question.displayKeywords }" aria-hidden="true" />
                                        <span class="marginLeft8">Show Keywords</span>
                                    </label>
                                </div>
                            </li>
                            <li class="dropdown-submenu">
                                <a href="#" class="width100 flexSpaceBetween" @click.prevent="toggleSubMenu($event)">
                                    Display Column
                                    <i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
                                </a>
                                <ul class="dropdown-menu" @keyup.esc="closeAllMenu()">
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`one-column-toggle-${question.uuid}`"
                                                v-model="selectedColumn"
                                                type="checkbox"
                                                name="columnSelection"
                                                @change="updateSelectedColumn(1)"
                                                @keyup.enter="updateSelectedColumn(1)"
                                            />
                                            <label :for="`one-column-toggle-${question.uuid}`" class="width100 flexSpaceBetween">
                                                <span>
                                                    <i class="fa" :class="{ 'fa-check': selectedColumn == 1 }" aria-hidden="true" />
                                                    <span class="marginLeft8">1 Column</span>
                                                </span>

                                                <i class="fas fa-columns" aria-hidden="true" />
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`two-column-toggle-${question.uuid}`"
                                                v-model="selectedColumn"
                                                type="checkbox"
                                                name="columnSelection"
                                                @change="updateSelectedColumn(2)"
                                                @keyup.enter="updateSelectedColumn(2)"
                                            />
                                            <label :for="`two-column-toggle-${question.uuid}`" class="width100 flexSpaceBetween">
                                                <span>
                                                    <i class="fa" :class="{ 'fa-check': selectedColumn == 2 }" aria-hidden="true" />
                                                    <span class="marginLeft8">2 Columns</span>
                                                </span>

                                                <i class="fas fa-columns" aria-hidden="true" />
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`three-column-toggle-${question.uuid}`"
                                                v-model="selectedColumn"
                                                type="checkbox"
                                                name="columnSelection"
                                                @change="updateSelectedColumn(3)"
                                                @keyup.enter="updateSelectedColumn(3)"
                                            />
                                            <label :for="`three-column-toggle-${question.uuid}`" class="width100 flexSpaceBetween">
                                                <span>
                                                    <i class="fa" :class="{ 'fa-check': selectedColumn == 3 }" aria-hidden="true" />
                                                    <span class="marginLeft8">3 Columns</span>
                                                </span>

                                                <i class="fas fa-columns" aria-hidden="true" />
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`four-column-toggle-${question.uuid}`"
                                                v-model="selectedColumn"
                                                type="checkbox"
                                                name="columnSelection"
                                                @change="updateSelectedColumn(4)"
                                                @keyup.enter="updateSelectedColumn(4)"
                                            />
                                            <label :for="`four-column-toggle-${question.uuid}`" class="width100 flexSpaceBetween">
                                                <span>
                                                    <i class="fa" :class="{ 'fa-check': selectedColumn == 4 }" aria-hidden="true" />
                                                    <span class="marginLeft8">4 Columns</span>
                                                </span>

                                                <i class="fas fa-columns" aria-hidden="true" />
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`eight-column-toggle-${question.uuid}`"
                                                v-model="selectedColumn"
                                                type="checkbox"
                                                name="columnSelection"
                                                @change="updateSelectedColumn(8)"
                                                @keyup.enter="updateSelectedColumn(8)"
                                            />
                                            <label :for="`eight-column-toggle-${question.uuid}`" class="width100 flexSpaceBetween">
                                                <span>
                                                    <i class="fa" :class="{ 'fa-check': selectedColumn == 8 }" aria-hidden="true" />
                                                    <span class="marginLeft8">8 Columns</span>
                                                </span>

                                                <i class="fas fa-columns" aria-hidden="true" />
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <hr class="margin0" />
                            <li class="dropdown-submenu">
                                <a href="#" class="width100 flexSpaceBetween" @click.prevent="toggleSubMenu($event)">
                                    Choose answers to display
                                    <i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
                                </a>
                                <ul class="dropdown-menu" @keyup.esc="closeAllMenu()">
                                    <li>
                                        <button @click="openDisplayAnswers">From a modal <i class="fas fa-columns marginLeft8" aria-hidden="true" /></button>
                                    </li>
                                </ul>
                            </li>
                            <hr v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)" class="margin0" />
                            <li v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)">
                                <div class="checkbox flex">
                                    <input
                                        :id="`show-percentage-toggle-${question.uuid}`"
                                        v-model="question.displayVotes"
                                        type="checkbox"
                                        aria-label="Vote Percentage"
                                        @change="toggleVotes()"
                                        @keyup.enter="question.displayVotes = !question.displayVotes"
                                    />
                                    <label :for="`show-percentage-toggle-${question.uuid}`">
                                        <i class="fa" :class="{ 'fa-check': question.displayVotes }" aria-hidden="true" />

                                        <span class="marginLeft8">Vote Percentage</span>
                                    </label>
                                </div>
                            </li>
                            <li v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)">
                                <div class="checkbox flex">
                                    <input
                                        :id="`show-comments-toggle-${question.uuid}`"
                                        v-model="question.displayComments"
                                        type="checkbox"
                                        aria-label="Comments"
                                        @change="toggleComments()"
                                        @keyup.enter="question.displayComments = !question.displayComments"
                                    />
                                    <label :for="`show-comments-toggle-${question.uuid}`">
                                        <i class="fa" :class="{ 'fa-check': question.displayComments }" aria-hidden="true" />
                                        <span class="marginLeft8">Comments</span>
                                    </label>
                                </div>
                            </li>
                            <li v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)" class="dropdown-submenu">
                                <a href="#" class="width100 flexSpaceBetween" @click.prevent="toggleSubMenu($event)">
                                    Display Order
                                    <i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
                                </a>
                                <ul class="dropdown-menu" @keyup.esc="closeAllMenu()">
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`answers-from-a-modal-teams-${question.uuid}`"
                                                v-model="reorder.sort"
                                                type="checkbox"
                                                true-value="teams"
                                                false-value=""
                                                @change="reorderAnswers"
                                                @keyup.enter="reorder.sort = 'teams'"
                                            />
                                            <label :for="`answers-from-a-modal-teams-${question.uuid}`" class="width100 flexSpaceBetween">
                                                <div>
                                                    <i class="fa" :class="{ 'fa-check': reorder.sort == 'teams' }" aria-hidden="true" />
                                                    <span class="marginLeft8">Teams</span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`answers-from-a-modal-votes-${question.uuid}`"
                                                v-model="reorder.sort"
                                                type="checkbox"
                                                true-value="votes"
                                                false-value=""
                                                @change="reorderAnswers"
                                                @keyup.enter="reorder.sort = 'votes'"
                                            />
                                            <label :for="`answers-from-a-modal-votes-${question.uuid}`" class="width100 flexSpaceBetween">
                                                <div>
                                                    <i class="fa" :class="{ 'fa-check': reorder.sort == 'votes' }" aria-hidden="true" />
                                                    <span class="marginLeft8">Votes</span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`answers-from-a-modal-goodAnswer-${question.uuid}`"
                                                v-model="reorder.sort"
                                                type="checkbox"
                                                true-value="goodAnswer"
                                                false-value=""
                                                aria-label="good answer"
                                                @change="reorderAnswers"
                                                @keyup.enter="reorder.sort = 'goodAnswer'"
                                            />
                                            <label :for="`answers-from-a-modal-goodAnswer-${question.uuid}`" class="width100 flexSpaceBetween">
                                                <div>
                                                    <i class="fa" :class="{ 'fa-check': reorder.sort == 'goodAnswer' }" aria-hidden="true" />
                                                    <span class="marginLeft8">Good Answer</span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </template>
            </div>
        </div>

        <div class="hidden-md hidden-lg panel-heading">
            <div class="flex align-items">
                Question
                <question-pagination :test-obj="testObj" />
            </div>
            <div v-if="question.type != 'openended'" class="marginTop20">
                <div class="switchToggle flexOnly align-items marginRight20">
                    <label :for="'showCorrectAnswer-' + question.uuid" class="control-label marginBottom0 marginTop10"> Show Correct Answer </label>
                    <label class="form-switch marginLeft8">
                        <input
                            v-tooltip="!$can('manage_test', 'Course-' + testObj?.course?.uuid) ? 'You are not allowed to manage this test' : ''"
                            :id="'showCorrectAnswer-' + question.uuid"
                            v-model="question.displayAnswer"
                            type="checkbox"
                            :class="{
                                'disabled hasToolTip': !$can('manage_test', 'Course-' + testObj?.course?.uuid),
                            }"
                            :aria-disabled="!$can('manage_test', 'Course-' + testObj?.course?.uuid)"
                            @change="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? toggleAnswers() : $event.preventDefault()"
                            @click="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? toggleAnswers() : $event.preventDefault()"
                            @keydown.space="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? toggleAnswers() : $event.preventDefault()"
                        />
                        <span role="switch" aria-checked="true" class="slider round" aria-label="Show Correct Answer" />
                    </label>
                </div>

                <div class="inlineFlex align-items marginTop10">
                    <label :for="'showAnswerChoicesStatistic-' + question.uuid" class="control-label marginBottom0 marginTop10">
                        <template v-if="testObj.others.applicationType == 'individual'"> Show Students Answers </template>
                        <template v-else>Show Teams' Answers</template>
                    </label>
                    <label class="form-switch marginLeft8">
                        <input
                            v-tooltip="!$can('manage_test', 'Course-' + testObj?.course?.uuid) ? 'You are not allowed to manage this test' : ''"
                            :id="'showAnswerChoicesStatistic-' + question.uuid"
                            v-model="question.displayAnswerStatistics"
                            type="checkbox"
                            :class="{
                                'disabled hasToolTip': !$can('manage_test', 'Course-' + testObj?.course?.uuid),
                            }"
                            :aria-disabled="!$can('manage_test', 'Course-' + testObj?.course?.uuid)"
                            @change="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? toggleStatistics() : $event.preventDefault()"
                            @click="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? toggleStatistics() : $event.preventDefault()"
                            @keydown.space="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? toggleStatistics() : $event.preventDefault()"
                        />
                        <span role="switch" aria-checked="true" class="slider round" aria-label="Show Answer Choices Statistic" />
                    </label>
                </div>
            </div>

            <div v-if="question.type == 'openended' && showRealtimeToggle" class="marginTop20">
                <div>
                    <div
                        v-if="[null, 'cancelled', 'preparing'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: !canStartEgallery
                                ? 'Please select at least two teams’ answers to enable e-Gallery Walk'
                                : '' || hasWritePrivilege(testObj.course) == -1
                                ? readOnlyLabel
                                : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            v-tooltip="
                                !$can('manage_test', 'Course-' + testObj?.course?.uuid)
                                    ? 'You are not allowed to manage this test'
                                    : '' || !canStartEgallery
                                    ? 'Please select at least two teams’ answers to enable e-Gallery Walk'
                                    : '' || question.displayAnswer == false
                                    ? 'Display answers is disabled'
                                    : ''
                            "
                            class="btn btn-primary"
                            :class="{
                                'disabled hasToolTip':
                                    !$can('manage_test', 'Course-' + testObj?.course?.uuid) || !canStartEgallery || question.displayAnswer == false,
                            }"
                            @click.prevent="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? prepare() : ''"
                        >
                            Initialize e-Gallery Walk
                        </button>
                    </div>

                    <div
                        v-if="['ongoing'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            v-tooltip="!$can('manage_test', 'Course-' + testObj?.course?.uuid) ? 'You are not allowed to manage this test' : ''"
                            class="btn btn-danger"
                            :class="{ 'disabled hasToolTip': !$can('manage_test', 'Course-' + testObj?.course?.uuid) }"
                            @click.prevent="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? openEndConfirmationModal() : $event.preventDefault()"
                        >
                            Stop e-Gallery Walk<i class="fa fa-stop marginLeft8" aria-hidden="true" />
                        </button>
                    </div>
                    <div
                        v-if="['paused'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            v-tooltip="!$can('manage_test', 'Course-' + testObj?.course?.uuid) ? 'You are not allowed to manage this test' : ''"
                            class="btn btn-success"
                            :class="{ 'disabled hasToolTip': !$can('manage_test', 'Course-' + testObj?.course?.uuid) }"
                            @click.prevent="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? resume() : ''"
                        >
                            Resume e-Gallery Walk-Gallery Walk<i class="fa fa-caret-right marginLeft8" aria-hidden="true" />
                        </button>
                    </div>
                    <div
                        v-if="['ended'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            v-tooltip="!$can('manage_test', 'Course-' + testObj?.course?.uuid) ? 'You are not allowed to manage this test' : ''"
                            class="btn btn-success"
                            :class="{ 'disabled hasToolTip': !$can('manage_test', 'Course-' + testObj?.course?.uuid) }"
                            @click.prevent="$can('manage_test', 'Course-' + testObj?.course?.uuid) ? openReleaseConfirmationModal() : ''"
                        >
                            Release e-Gallery Walk Results<i class="fa fa-check marginLeft8" aria-hidden="true" />
                        </button>
                    </div>
                </div>

                <div class="marginTop20">
                    <div v-if="['ongoing', 'ended', 'released'].includes(question.eGallery.status)" class="cr-dropdowns">
                        <button
                            v-tooltip="!$can('manage_test', 'Course-' + testObj?.course?.uuid) ? 'You are not allowed to manage this test' : ''"
                            :id="`dLabel-action-${question.uuid}`"
                            role="button"
                            data-toggle="dropdown"
                            class="btn btn-primary"
                            :class="{ 'disabled hasToolTip': !$can('manage_test', 'Course-' + testObj?.course?.uuid) }"
                            data-target="#"
                            href="#"
                        >
                            Actions
                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                        </button>
                        <ul class="dropdown-menu multiLevel" role="menu" aria-labelledby="dropdownMenu">
                            <li v-if="['ongoing'].includes(question.eGallery.status)" aria-label="Pause e-Gallery Walk">
                                <div class="checkbox flex" @click="pause()">
                                    <label>
                                        <i class="fa fa-pause" aria-hidden="true" />
                                        <span class="marginLeft8">Pause e-Gallery Walk</span>
                                    </label>
                                </div>
                            </li>
                            <li v-if="['ended', 'released', 'ongoing'].includes(question.eGallery.status)">
                                <div class="checkbox flex" @click="openResetConfirmationModal()">
                                    <label class="selection-dropdown-checked">
                                        <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reset e-Gallery Walk
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div
                        v-if="
                            [null, 'preparing', 'cancelled', 'ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status) && showRealtimeToggle
                        "
                        class="cr-dropdowns marginLeft20"
                        style="font-size: 30px"
                    >
                        <button
                            v-if="hasWritePrivilege(testObj.course) > 0"
                            data-toggle="dropdown"
                            class="btn btn-primary"
                            data-target="#"
                            href="#"
                            aria-expanded="false"
                        >
                            View
                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                        </button>
                        <ul class="dropdown-menu multiLevel" role="menu" aria-labelledby="dropdownMenu">
                            <li v-if="showRealtimeToggle">
                                <div class="checkbox flex">
                                    <input
                                        :id="`reveal-ans-toggle-${question.uuid}`"
                                        v-model="question.displayAnswer"
                                        type="checkbox"
                                        @change="toggleAnswers(question)"
                                        @keyup.enter="question.displayAnswer = !question.displayAnswer"
                                    />
                                    <label :for="`reveal-ans-toggle-${question.uuid}`">
                                        <i class="fa" :class="{ 'fa-check': question.displayAnswer }" aria-hidden="true" />
                                        <span class="marginLeft8"
                                            >Show
                                            <template v-if="testObj.others.applicationType == 'individual'">Students’</template>
                                            <template v-else>Teams’</template>
                                            Answers
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li v-if="question.type == 'openended'">
                                <div class="checkbox flex">
                                    <input
                                        :id="`show-keywords-toggle-${question.uuid}`"
                                        v-model="question.displayKeywords"
                                        type="checkbox"
                                        @change="toggleKeywords()"
                                        @keyup.enter="question.displayKeywords = !question.displayKeywords"
                                    />
                                    <label :for="`show-keywords-toggle-${question.uuid}`">
                                        <i class="fa" :class="{ 'fa-check': question.displayKeywords }" aria-hidden="true" />
                                        <span class="marginLeft8">Show Keywords</span>
                                    </label>
                                </div>
                            </li>
                            <hr class="margin0" />
                            <li class="dropdown-submenu">
                                <a href="#" class="width100 flexSpaceBetween" @click.prevent="toggleSubMenu($event)">
                                    Choose answers to display
                                    <i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
                                </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <button @click="openDisplayAnswers">From a modal <i class="fas fa-columns marginLeft8" aria-hidden="true" /></button>
                                    </li>
                                </ul>
                            </li>
                            <hr v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)" class="margin0" />
                            <li v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)">
                                <div class="checkbox flex">
                                    <input
                                        :id="`show-percentage-toggle-${question.uuid}`"
                                        v-model="question.displayVotes"
                                        type="checkbox"
                                        @change="toggleVotes()"
                                        @keyup.enter="question.displayVotes = !question.displayVotes"
                                    />
                                    <label :for="`show-percentage-toggle-${question.uuid}`">
                                        <i class="fa" :class="{ 'fa-check': question.displayVotes }" aria-hidden="true" />
                                        <span> Vote Percentage </span>
                                    </label>
                                </div>
                            </li>
                            <li v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)">
                                <div class="checkbox flex">
                                    <input
                                        :id="`show-comments-toggle-${question.uuid}`"
                                        v-model="question.displayComments"
                                        type="checkbox"
                                        @change="toggleComments()"
                                        @keyup.enter="question.displayComments = !question.displayComments"
                                    />
                                    <label :for="`show-comments-toggle-${question.uuid}`">
                                        <i class="fa" :class="{ 'fa-check': question.displayComments }" aria-hidden="true" />
                                        <span class="marginLeft8">Comments</span>
                                    </label>
                                </div>
                            </li>
                            <li v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)" class="dropdown-submenu">
                                <a href="#" class="width100 flexSpaceBetween" @click.prevent="toggleSubMenu($event)">
                                    Display Order
                                    <i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
                                </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`answers-from-a-modal-teams-${question.uuid}`"
                                                v-model="reorder.sort"
                                                type="checkbox"
                                                true-value="teams"
                                                false-value=""
                                                @change="reorderAnswers"
                                                @keyup.enter="reorder.sort = 'teams'"
                                            />
                                            <label :for="`answers-from-a-modal-teams-${question.uuid}`" class="width100 flexSpaceBetween">
                                                <div>
                                                    <i class="fa" :class="{ 'fa-check': reorder.sort == 'teams' }" aria-hidden="true" />
                                                    <span class="marginLeft8">Teams</span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`answers-from-a-modal-votes-${question.uuid}`"
                                                v-model="reorder.sort"
                                                type="checkbox"
                                                true-value="votes"
                                                false-value=""
                                                @change="reorderAnswers"
                                                @keyup.enter="reorder.sort = 'votes'"
                                            />
                                            <label :for="`answers-from-a-modal-votes-${question.uuid}`" class="width100 flexSpaceBetween">
                                                <div>
                                                    <i class="fa" :class="{ 'fa-check': reorder.sort == 'votes' }" aria-hidden="true" />
                                                    <span class="marginLeft8">Votes</span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`answers-from-a-modal-goodAnswer-${question.uuid}`"
                                                v-model="reorder.sort"
                                                type="checkbox"
                                                true-value="goodAnswer"
                                                false-value=""
                                                aria-label="good answer"
                                                @change="reorderAnswers"
                                                @keyup.enter="reorder.sort = 'goodAnswer'"
                                            />
                                            <label :for="`answers-from-a-modal-goodAnswer-${question.uuid}`" class="width100 flexSpaceBetween">
                                                <div>
                                                    <i class="fa" :class="{ 'fa-check': reorder.sort == 'goodAnswer' }" aria-hidden="true" />
                                                    <span class="marginLeft8">Good Answer</span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel-body eGallery-panel">
            <div v-if="question.type == 'openended' && !['ended'].includes(question.eGallery.status)" class="marginBottom20 question-div-bar">
                <template v-if="question.type == 'openended' && ['paused', 'ongoing', 'released'].includes(question.eGallery.status)">
                    <div class="marginTop10 flex gap10">
                        <label
                            v-if="question.whoHaveNotAnswered.length != 0"
                            class="marginTop8 control-label"
                            :class="{
                                whiteText:
                                    question.type == 'openended' &&
                                    ['ongoing', 'ended', 'released'].includes(question.eGallery.status) &&
                                    $route.name == 'activities.tests.questions',
                            }"
                        >
                            {{ question.whoHaveNotAnswered.length }}

                            <template v-if="question.whoHaveNotAnswered.length <= 1">
                                <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                <template v-else> Student </template>
                                Has
                            </template>
                            <template v-else>
                                <template v-if="testObj.others.applicationType == 'team'"> Teams </template>
                                <template v-else> Students </template>
                                Have
                            </template>
                            Not Voted
                        </label>

                        <div class="flex gap10">
                            <template v-for="(model, model_idx) in question.whoHaveNotAnswered">
                                <div class="dropdown">
                                    <button
                                        v-tooltip="testObj.others.applicationType == 'team' ? model.name : model.fullname"
                                        class="btn btn-outline-default flexInherit margin0"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <template v-if="testObj.others.applicationType == 'team'">
                                            {{ truncate(model.name) }}
                                        </template>
                                        <template v-else>
                                            {{ truncate(model.fullname) }}
                                        </template>
                                    </button>
                                    <div class="dropdown-menu dropdown-disucssion padding10 fontBlack">
                                        <div v-if="testObj.others.applicationType == 'team'" class="previewModelTeam">
                                            <div class="flexOnly marginBottom10 align-items">
                                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                <label class="control-label fs-14px margin0"> Team Members </label>
                                            </div>
                                            <div class="maxHeight100px">
                                                <ol class="margin0">
                                                    <li v-for="(student, idx) in model.members">
                                                        {{ student.displayName }}
                                                    </li>
                                                </ol>
                                            </div>

                                            <button class="btn btn-link marginLeft0 marginTop10 padding0" @click="openModalMembersInfo(model)">
                                                View More
                                            </button>
                                        </div>
                                        <template v-else>
                                            <div class="flexOnly marginBottom10 align-items">
                                                <i class="fas fa-user marginRight8 greyText" aria-hidden="true" />
                                                <label class="control-label fs-14px margin0"> Student Fullname </label>
                                            </div>
                                            <p>
                                                {{ model.fullname }}
                                            </p>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div v-if="![, 'scheduled', 'not started', 'completed', 'ended'].includes(testObj.status)" class="question-bar">
                        <template v-if="percentNotAnswered >= 10">
                            <div class="barProgress" :style="'width:' + percentNotAnswered + '%'">{{ percentNotAnswered }}%</div>
                        </template>
                        <template v-else>
                            <div class="col-xs-12 whiteText">{{ percentNotAnswered }}%</div>
                        </template>
                    </div>

                    <div class="marginTop10 flexOnly">
                        <template v-if="testObj.others.applicationType == 'team'">
                            <div
                                v-if="question.presentTeamsWithNoAnswer.length != 0 && ['ongoing', 'paused', 'ended', 'completed'].includes(testObj.status)"
                                class="marginTop8 control-label marginRight10"
                            >
                                {{ question.presentTeamsWithNoAnswer.length }}
                                <template v-if="question.presentTeamsWithNoAnswer.length <= 1"> Team Have Not Answered </template>
                                <template v-else> Teams Have Not Answered </template>
                            </div>

                            <div v-if="['ongoing', 'paused', 'ended', 'completed'].includes(testObj.status)" class="flex gap10">
                                <template v-for="(model, model_idx) in question.presentTeamsWithNoAnswer">
                                    <div class="dropdown">
                                        <button
                                            id="popOver"
                                            class="btn gradient btn-default marginBottom10"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :aria-label="model.name"
                                        >
                                            {{ truncate(model.name) }}
                                        </button>
                                        <div class="dropdown-menu dropdown-disucssion padding10 fontBlack" aria-labelledby="popOver">
                                            <div v-if="testObj.others.applicationType == 'team'" class="previewModelTeam">
                                                <div class="flexOnly marginBottom5 align-items">
                                                    <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                    <span class="lineHeight08 fontBold">Team Members</span>
                                                </div>
                                                <div class="maxHeight100px">
                                                    <ul class="marginBottom0">
                                                        <li v-for="(student, idx) in model.members">
                                                            {{ student.displayName }}
                                                        </li>
                                                    </ul>
                                                </div>

                                                <button class="btn btn-link marginLeft0 marginTop10 padding0" @click="openModalMembersInfo(model)">
                                                    View More
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>

                        <template v-else>
                            <div
                                v-if="question.presentStudentsWithNoAnswer.length != 0 && ['ongoing', 'paused', 'ended', 'completed'].includes(testObj.status)"
                                class="marginTop8 control-label marginRight10"
                            >
                                {{ question.presentStudentsWithNoAnswer.length }}
                                <template v-if="question.presentStudentsWithNoAnswer.length <= 1"> Student Have Not Answered </template>
                                <template v-else> Students Have Not Answered </template>
                            </div>

                            <div v-if="['ongoing', 'paused', 'ended', 'completed'].includes(testObj.status)" class="flex gap10">
                                <template v-for="(model, model_idx) in question.presentStudentsWithNoAnswer">
                                    <div class="dropdown">
                                        <button
                                            id="popOver"
                                            class="btn gradient btn-default marginBottom10"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :aria-label="model.fullname"
                                        >
                                            {{ truncate(model.fullname) }}
                                        </button>
                                        <div class="dropdown-menu dropdown-disucssion padding10 fontBlack" aria-labelledby="popOver">
                                            <dic>
                                                <ul>
                                                    <li>
                                                        {{ model.fullname }}
                                                    </li>
                                                </ul>
                                            </dic>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </template>
            </div>

            <div style="background: inherit; border: none">
                <template v-if="question.type == 'mcqs' || question.type == 'mcqm'">
                    <mcq
                        :test-obj="testObj"
                        :question="question"
                        :options="options"
                        :show-realtime-toggle="showRealtimeToggle"
                        :activities-status="activitiesStatus"
                    />
                </template>
                <template v-if="question.type == 'openended'">
                    <openended
                        :test-obj="testObj"
                        :question="question"
                        :show-realtime-toggle="showRealtimeToggle"
                        :selected-column="selectedColumn"
                        @save-grades="saveGrades"
                    />
                </template>
                <template v-if="question.type == 'vsa'">
                    <vsa :test-obj="testObj" :question="question" :show-realtime-toggle="showRealtimeToggle" />
                </template>
                <template v-if="question.type == 'sequence'">
                    <sequence :test-obj="testObj" :question="question" :show-realtime-toggle="showRealtimeToggle" />
                </template>
                <template v-if="question.type == 'matching' && question.options.matchingType == 'one-to-one'">
                    <matching :test-obj="testObj" :question="question" :show-realtime-toggle="showRealtimeToggle" />
                </template>
                <template v-if="question.type == 'matching' && question.options.matchingType == 'one-to-many'">
                    <categorize :test-obj="testObj" :question="question" :show-realtime-toggle="showRealtimeToggle" />
                </template>
            </div>
        </div>

        <div
            v-if="question.type == 'openended'"
            :id="'eGalleryProcessStartModal' + question.uuid"
            class="modal dark-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryProcessStartModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryProcessStartModal' + question.uuid + '-title'" class="modal-title">Initalize e-Gallery Walk</h2>
                    </div>
                    <div class="modal-body">
                        <p class="marginBottom10">
                            <i>This is hidden from the Teams’/Students’ view.</i>
                        </p>

                        <div class="bg-white padding10 br-3">
                            <fieldset class="marginBottom20">
                                <legend class="control-label">
                                    Comment Requirements
                                    <span class="important" aria-hidden="true">*</span>
                                    <span class="visible-hidden">Required</span>
                                </legend>

                                <template
                                    v-for="item in [
                                        {
                                            description: 'No requirement',
                                            value: 0,
                                        },
                                        {
                                            description: 'Teams must provide a comment for answers they voted for only',
                                            value: 1,
                                        },
                                        {
                                            description: 'Teams must provide a comment for all answers.',
                                            value: 3,
                                        },
                                    ]"
                                    :key="'eGallery_' + item.value"
                                >
                                    <div
                                        class="radioField flex paddingLeft15"
                                        :class="{
                                            marginTop10: item.value != 0,
                                        }"
                                    >
                                        <input
                                            :id="question.uuid + '-commentRadio' + item.value"
                                            v-model="eGallerySettings.commentRadio"
                                            type="radio"
                                            :value="item.value"
                                            :aria-label="item.description"
                                            @change="toggleComment"
                                        />
                                        <label class="marginLeft10" :for="question.uuid + '-commentRadio' + item.value">
                                            {{ item.description }}
                                        </label>
                                    </div>
                                </template>
                            </fieldset>

                            <fieldset>
                                <legend class="control-label">Duration Requirements</legend>

                                <div class="checkboxField paddingLeft15">
                                    <input
                                        :id="question.uuid + '-timeLimit'"
                                        v-model="eGallerySettings.timeLimit"
                                        type="checkbox"
                                        aria-label="Set a time-limit for e-Gallery walk"
                                    />
                                    <label class="marginLeft10" :for="question.uuid + '-timeLimit'"> Set a time-limit for e-Gallery walk </label>
                                </div>

                                <div class="marginTop10 paddingLeft15">
                                    <label id="time-limit-label" class="control-label">Time Limit in Minutes</label>
                                    <div class="customNumberInput timeLimit" role="group" aria-labelledby="time-limit-label">
                                        <input
                                            v-tooltip="{
                                                content: eGallerySettings.timeLimit ? '' : 'Please enable set a time limit first',
                                            }"
                                            v-model="eGallerySettings.timeLimitInMinutes"
                                            type="number"
                                            class="form-control"
                                            @change="!eGallerySettings.timeLimit ? '' : updatedMins"
                                            min="0"
                                            max="60"
                                            :readonly="!eGallerySettings.timeLimit"
                                            aria-label="Time limit in minutes"
                                            :aria-readonly="!eGallerySettings.timeLimit"
                                            :aria-describedby="!eGallerySettings.timeLimit ? 'time-limit-help' : undefined"
                                        />

                                        <div class="quantity-nav" role="group" aria-label="Adjust time limit">
                                            <button
                                                v-tooltip="{
                                                    content: eGallerySettings.timeLimit ? '' : 'Please enable set a time limit first',
                                                }"
                                                type="button"
                                                class="quantity-button"
                                                aria-label="Increase time limit"
                                                :disabled="eGallerySettings.timeLimitInMinutes >= 60 || !eGallerySettings.timeLimit"
                                                @click.prevent="
                                                    eGallerySettings.timeLimitInMinutes < 60 && eGallerySettings.timeLimit
                                                        ? eGallerySettings.timeLimitInMinutes++
                                                        : ''
                                                "
                                            >
                                                <i class="fas fa-plus" aria-hidden="true" />
                                            </button>
                                            <button
                                                v-tooltip="{
                                                    content: eGallerySettings.timeLimit ? '' : 'Please enable set a time limit first',
                                                }"
                                                type="button"
                                                class="quantity-button"
                                                :disabled="eGallerySettings.timeLimitInMinutes <= 1 || !eGallerySettings.timeLimit"
                                                aria-label="Decrease time limit"
                                                @click.prevent="
                                                    eGallerySettings.timeLimitInMinutes > 0 && eGallerySettings.timeLimit
                                                        ? eGallerySettings.timeLimitInMinutes--
                                                        : ''
                                                "
                                            >
                                                <i class="fas fa-minus" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" @click.prevent="start()">Start e-Gallery Walk</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="question.type == 'openended'"
            :id="'eGalleryProcessExtendModal' + question.uuid"
            class="modal dark-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryProcessExtendModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryProcessExtendModal' + question.uuid + '-title'" class="modal-title">Extend e-Gallery Walk</h2>
                    </div>
                    <div class="modal-body">
                        <p class="marginBottom10">
                            <i>This is hidden from the Teams’/Students’ view.</i>
                        </p>

                        <div class="bg-white padding10 br-3">
                            <fieldset>
                                <legend class="control-label">Duration Requirements</legend>

                                <div class="checkboxField paddingLeft15">
                                    <input
                                        :id="question.uuid + '-timeLimit'"
                                        v-model="eGallerySettings.timeLimit"
                                        type="checkbox"
                                        aria-label="Set a time-limit for e-Gallery walk"
                                    />
                                    <label class="marginLeft10" :for="question.uuid + '-timeLimit'"> Set a time-limit for e-Gallery walk </label>
                                </div>

                                <div class="marginTop10 paddingLeft15">
                                    <label id="time-limit-label" class="control-label">Time Limit in Minutes</label>
                                    <div class="customNumberInput timeLimit" role="group" aria-labelledby="time-limit-label">
                                        <input
                                            v-tooltip="{
                                                content: eGallerySettings.timeLimit ? '' : 'Please enable set a time limit first',
                                            }"
                                            v-model="eGallerySettings.timeLimitInMinutes"
                                            type="number"
                                            class="form-control"
                                            @change="!eGallerySettings.timeLimit ? '' : updatedMins"
                                            min="0"
                                            max="60"
                                            :readonly="!eGallerySettings.timeLimit"
                                            aria-label="Time limit in minutes"
                                            :aria-readonly="!eGallerySettings.timeLimit"
                                            :aria-describedby="!eGallerySettings.timeLimit ? 'time-limit-help' : undefined"
                                        />

                                        <div class="quantity-nav" role="group" aria-label="Adjust time limit">
                                            <button
                                                v-tooltip="{
                                                    content: eGallerySettings.timeLimit ? '' : 'Please enable set a time limit first',
                                                }"
                                                type="button"
                                                class="quantity-button"
                                                aria-label="Increase time limit"
                                                :disabled="eGallerySettings.timeLimitInMinutes >= 60 || !eGallerySettings.timeLimit"
                                                @click.prevent="
                                                    eGallerySettings.timeLimitInMinutes < 60 && eGallerySettings.timeLimit
                                                        ? eGallerySettings.timeLimitInMinutes++
                                                        : ''
                                                "
                                            >
                                                <i class="fas fa-plus" aria-hidden="true" />
                                            </button>
                                            <button
                                                v-tooltip="{
                                                    content: eGallerySettings.timeLimit ? '' : 'Please enable set a time limit first',
                                                }"
                                                type="button"
                                                class="quantity-button"
                                                :disabled="eGallerySettings.timeLimitInMinutes <= 1 || !eGallerySettings.timeLimit"
                                                aria-label="Decrease time limit"
                                                @click.prevent="
                                                    eGallerySettings.timeLimitInMinutes > 0 && eGallerySettings.timeLimit
                                                        ? eGallerySettings.timeLimitInMinutes--
                                                        : ''
                                                "
                                            >
                                                <i class="fas fa-minus" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                    <button
                        v-tooltip="{
                            content: eGallerySettings.timeLimit ? '' : 'Please enable set a time limit first',
                            trigger: 'hover focus',
                        }"
                        class="btn btn-success"
                        :class="{ hasToolTip: !eGallerySettings.timeLimit }"
                        data-dismiss="modal"
                        @click.prevent="!eGallerySettings.timeLimit ? '' : extend()"
                        :aria-disabled="!eGallerySettings.timeLimit"
                    >
                        Extend e-Gallery Walk
                    </button>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryResetModal' + question.uuid"
            class="modal dark-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryResetModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryResetModal' + question.uuid + '-title'" class="modal-title">Reset e-Gallery</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <h2 class="margin0">Reset</h2>

                        <h3 class="marginTop20">Please Take Note!</h3>
                        <h4>e-Gallery Walk will be reset.</h4>
                        <h4>All students votes will be cleared.</h4>

                        <p class="marginTop20">
                            <b>Do you still want to continue?</b>
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" aria-label="No, Cancel" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="cancel()">Yes, Reset</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'membersModal' + question.uuid"
            class="modal default-modal"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'membersModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'membersModal' + question.uuid + '-title'" class="modal-title">View {{ previewModel.team.name }}</h2>
                    </div>
                    <div class="modal-body">
                        <fieldset>
                            <legend class="flexOnly align-items"><i class="fas fa-users marginRight8" aria-hidden="true" /> Team Members</legend>

                            <ol class="margin0">
                                <template v-for="(student, idx) in previewModel.team.members">
                                    <li class="marginBottom10 fs-18px">{{ student.displayName }}</li>
                                </template>
                            </ol>
                        </fieldset>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryStartModal' + question.uuid"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryStartModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryStartModal' + question.uuid + '-title'" class="modal-title">Start e-Gallery Walk</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 textAlignCenter">
                            <div>You have selected the following team’s answers for the e-Gallery.</div>
                            <div v-for="(answer, answer_idx) in question.answers" class="with-top-gaps">
                                <template v-if="question.answers[answer_idx].isSelected == true">
                                    <button
                                        class="btn btn-default cursor-norm"
                                        :aria-label="
                                            testObj.others.applicationType == 'team'
                                                ? question.answers[answer_idx].team.name
                                                : question.answers[answer_idx].student.fullname
                                        "
                                    >
                                        <template v-if="testObj.others.applicationType == 'team'">
                                            {{ question.answers[answer_idx].team.name }}
                                        </template>
                                        <template v-else>
                                            {{ question.answers[answer_idx].student.fullname }}
                                        </template>
                                    </button>
                                </template>
                            </div>
                        </div>
                        <div class="flex marginTop30">
                            <button id="modal-dismiss-btn" class="btn btn-default" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            <button id="modal-logout-btn" class="btn btn-success" data-dismiss="modal" aria-label="Start" @click.prevent="start()">
                                Start
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryEndModal' + question.uuid"
            class="modal dark-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryEndModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryEndModal' + question.uuid + '-title'" class="modal-title">Stop e-Gallery Walk</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <h2 class="margin0">End e-Gallery Walk</h2>

                        <p class="marginTop20">You are about to stop the e-Gallery Walk</p>

                        <p>Teams/Students will no longer be able to vote and add comments.</p>

                        <p class="marginTop20">
                            <b>Do you want to continue end e-Gallery Walk?</b>
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" aria-label="No, Cancel" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="end()">Yes, Stop e-Gallery Walk</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryReleaseModal' + question.uuid"
            class="modal dark-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryReleaseModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryReleaseModal' + question.uuid + '-title'" class="modal-title">Release e-Gallery Walk Results</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <h2 class="margin0">Release Result</h2>

                        <p class="marginTop20">You are about to release the results of the e-Gallery walk</p>

                        <p>Teams/Students will be able to see the vote results and comments from other teams and students</p>

                        <p class="marginTop20">
                            <b>Do you want to continue releasing e-Gallery Walk results?</b>
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" aria-label="No, Cancel" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" @click.prevent="release()">Yes, Release e-Gallery Walk Results</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'displayAnswers-' + question.uuid"
            class="modal default-modal"
            :class="{
                'dark-modal':
                    question.type == 'openended' &&
                    ['ongoing', 'ended', 'released'].includes(question.eGallery.status) &&
                    $route.name == 'activities.tests.questions',
            }"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'displayAnswers-' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'displayAnswers-' + question.uuid + '-title'" class="modal-title">Select for e-Gallery Walk</h2>
                    </div>

                    <div class="modal-body">
                        <fieldset>
                            <legend
                                :class="{
                                    whiteText:
                                        question.type == 'openended' &&
                                        ['ongoing', 'ended', 'released'].includes(question.eGallery.status) &&
                                        $route.name == 'activities.tests.questions',
                                }"
                            >
                                Students Answers
                            </legend>

                            <template v-for="(answer, answer_idx) in question.answers">
                                <div
                                    class="checkboxField"
                                    :class="{ marginTop20: answer_idx != 0, 'dark-mode': ['ongoing', 'ended', 'released'].includes(question.eGallery.status) }"
                                >
                                    <input
                                        :id="'displayAnswers-' + question.uuid + '-' + answer_idx"
                                        :checked="question.answers[answer_idx].isSelected === true || question.answers[answer_idx].isSelected === 1"
                                        type="checkbox"
                                        @change="question.answers[answer_idx].isSelected = $event.target.checked"
                                    />

                                    <label :for="'displayAnswers-' + question.uuid + '-' + answer_idx" class="d-block">
                                        <b>
                                            <template v-if="testObj.others.applicationType == 'team'">
                                                {{ answer.team.name }}
                                            </template>
                                            <template v-else>
                                                {{ answer.student.fullname }}
                                            </template>
                                            Answer
                                        </b>

                                        <br />

                                        <span class="wordBreakWord whiteSpacePreWrap" v-dompurify-html="truncate(answer.answer, 400)" />
                                    </label>
                                </div>
                            </template>
                        </fieldset>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" @click.prevent="saveDisplayAnswers()">Show Answers</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="question.uuid + '-enlarge-image-modal'"
            class="modal modal-manual"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'enlargeImageModal-' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'enlargeImageModal-' + question.uuid + '-title'" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true" />Enlarged Image
                        </h2>
                    </div>
                    <div class="modal-body padding0">
                        <img :key="srcValue" :src="srcValue" width="100%" alt="" role="presentation" />
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryCancelModal' + question.uuid"
            class="modal dark-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryCancelModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryCancelModal' + question.uuid + '-title'" class="modal-title">Cancel e-Gallery Walk</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <h2 class="margin0">Cancel</h2>

                        <h3 class="marginTop20">Please Take Note!</h3>
                        <h4>Teams/Students will no longer</h4>
                        <h4>be able to vote and add comments.</h4>

                        <p class="marginTop20">
                            <b>Do you still want to continue?</b>
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" aria-label="No, Cancel" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="cancel()">Yes, Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'facultyNotesModal-' + facultyNoteQuestion.uuid"
            class="modal default-modal facultyNotesModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="facultyNotesModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="facultyNotesModal-title" class="modal-title">
                            Question {{ getQuestionNo(facultyNoteQuestion.group - 1, facultyNoteQuestion.order - 1) }} Notes
                        </h2>
                    </div>

                    <div class="modal-body">
                        <p class="whiteSpaceBreakSpacesText">
                            {{ facultyNoteQuestion.remarks }}
                        </p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ChangeQuestionCopyWritingComponent ref="changeQuestionCopyWritingComponent" />
</template>

<script>
import { testMixins } from './../../../../../../../../../../../../mixins/test';
import KrAuth from '../../../../../../../../../../../../components/auth/auth';
import ChangeQuestionCopyWritingComponent from '../../../../../../../actions/changeQuestionCopyWriting';

export default {
    components: {
        ChangeQuestionCopyWritingComponent,
    },
    mixins: [testMixins],
    props: ['testObj', 'question', 'question_group_idx', 'question_idx', 'showRealtimeToggle', 'activitiesStatus'],
    data() {
        let auth = new KrAuth();
        return {
            component_done_loading: false,
            options: {
                showCorrectAnswers: this.showRealtimeToggle != null ? !this.showRealtimeToggle : false,
            },
            eGallerySettings: {
                step: 0,
                answerSelectionForVoting: 'all',
                votingMethod: 'best',
                teamsMustCommentForVotedAnswersOnly: false,
                teamsMustCommentForAllAnswers: false,
                timeLimit: false,
                timeLimitInMinutes: 30,
                commentRadio: 0, //1 = voted answer, 3 = all answer
            },
            tempQuestion: {},
            fromDashboard: true,
            selectedColumn: 1,
            initialColumn: 1,
            previewModel: { type: 'team', student: {}, team: {} },
            auth: auth,
            readOnlyLabel: 'You have read-only access',
            reorder: { sort: 'goodAnswer', order: 'asc' },
            isReordered: false,
            timer: null,
            time: { minutes: '00', seconds: '00' },
            min: 1,
            srcValue: '',
            facultyNoteQuestion: {},
        };
    },
    computed: {
        canStartEgallery() {
            if (this.question.type == 'openended') {
                let count = 0;
                for (var i = 0; i < this.question.answers.length; i++) {
                    if (this.question.answers[i].isSelected) {
                        count++;
                    }
                }
                if (count > 1) {
                    // if (
                    //   (this.question.eGallery.votingMethod == "rank" &&
                    //     this.question.eGallery.rankingValue) ||
                    //   this.question.eGallery.votingMethod == "best"
                    // ) {
                    //   return true;
                    // }
                    return true;
                }
            }
            return false;
        },
        percentNotAnswered() {
            if (this.testObj.others.applicationType != 'team') {
                let present = this.question.countPresentStudents;
                if (present == 0) {
                    return 0;
                }
                return Math.round((this.question.presentStudentsWithNoAnswer.length / present) * 100);
            } else {
                let present = this.question.countPresentTeams;
                if (present == 0) {
                    return 0;
                }
                return Math.round((this.question.presentTeamsWithNoAnswer.length / present) * 100);
            }
            /*
        if(this.question.type=='mcqs'||this.question.type=='mcqm'){

        }else{
          let present  = this.question.answers.length;
          if(present == 0) {
            return null;
          }
          let unanswered = 0;
          for (var i = 0;i<this.question.answers.length;i++){
            if(!this.question.answers[i].answer){
              unanswered++;
            }
          }
          return Math.round((unanswered / present)*100);
        }*/
        },
        percentNotVoted() {
            let present = this.question.answers.length;
            if (present == 0) {
                return 0;
            }

            let notVoted = this.question.whoHaveNotAnswered.length;
            return Math.round((notVoted / present) * 100);
        },
    },
    watch: {
        question: {
            handler: function () {
                if (this.testObj.type == 'application' && this.question.type == 'openended') {
                    this.question.eGallery.votingMethod = this.question.eGallery.votingMethod ? this.question.eGallery.votingMethod : 'best';
                    this.question.eGallery.rankingValue = this.question.eGallery.rankingValue ? this.question.eGallery.rankingValue : 1;
                    for (var i = 0; i < this.question.answers.length; i++) {
                        if (this.question.answers[i].isGoodAnswer == null) {
                            this.question.answers[i].isGoodAnswer = false;
                            /*Vue.set(
                                this.question.answers[i],
                                '.isGoodAnswer',
                                false
                            );*/
                        }
                        if (this.question.answers[i].isSelected == null) {
                            this.question.answers[i].isSelected = true;
                            /*Vue.set(
                                this.question.answers[i],
                                '.isSelected',
                                true
                            );*/
                        }
                        if (this.question.answers[i]._gradeSaved === undefined) {
                            this.question.answers[i]._gradeSaved = false;
                            /*Vue.set(
                                this.question.answers[i],
                                '_gradeSaved',
                                false
                            );*/
                        }
                        if (this.question.answers[i]._narrativeSaved === undefined) {
                            this.question.answers[i]._narrativeSaved = false;
                            /*Vue.set(
                                this.question.answers[i],
                                '_narrativeSaved',
                                false
                            );*/
                        }
                    }
                    if (!this.isReordered) {
                        this.reorderAnswers();
                    }
                    this.isReordered = !this.isReordered;
                }

                if (
                    this.question.type == 'openended' &&
                    ['ongoing'].includes(this.question.eGallery.status) &&
                    this.question.eGalleryWalkSettings.timeLimitInMinutes
                ) {
                    this.initTimer();
                }
            },
            deep: true,
        },
    },
    created() {
        if (this.testObj.type == 'application' && this.question.type == 'openended') {
            this.question.eGallery.votingMethod = this.question.eGallery.votingMethod ? this.question.eGallery.votingMethod : 'best';
        }
        this.component_done_loading = true;
        var that = this;
        if (this.question.type == 'openended' && this.$route.name != 'activities.tests.dashboard') {
            Vue.nextTick(function () {
                that.reorderAnswers();
            });
            Events.listen('e-gallery-reorder-answers', function () {
                that.reorderAnswers();
            });
            /*Events.listen('toggle',function(){
           if(that.question.eGallery.status=='released'){
              that.question.eGallery.status = 'preparing';
           }else{
              that.question.eGallery.status = 'released';
           }
          });*/
        }
        Vue.nextTick(function () {
            $('#' + that.question.uuid + '-enlarge-image-modal').draggable();
        });
        // document.addEventListener("beforeunload", this.end());
    },
    mounted() {
        window.addEventListener('resize', this.handleColumnResize);
        this.handleColumnResize;
        window.addEventListener('click', (event) => {
            const isImg = event.target.nodeName === 'IMG';
            if (!isImg) {
                return;
            }

            var questionUUID = event.target.closest('.panel').getAttribute('id').slice(0, 36);
            this.openEnlargeImageModal(event.target.src, questionUUID);
        });

        if (['ongoing'].includes(_.get(this.question, 'eGallery.status'))) {
            this.eGallerySettings.timeLimit = this.question.eGalleryWalkSettings.hasTimeLimit;
        }
    },
    methods: {
        toggleComment() {
            if (this.eGallerySettings.commentRadio == 0) {
                this.eGallerySettings.teamsMustCommentForVotedAnswersOnly = false;
                this.eGallerySettings.teamsMustCommentForAllAnswers = false;
            } else if (this.eGallerySettings.commentRadio == 1) {
                this.eGallerySettings.teamsMustCommentForVotedAnswersOnly = true;
                this.eGallerySettings.teamsMustCommentForAllAnswers = false;
            } else if (this.eGallerySettings.commentRadio == 3) {
                this.eGallerySettings.teamsMustCommentForVotedAnswersOnly = false;
                this.eGallerySettings.teamsMustCommentForAllAnswers = true;
            }
        },
        toggleKeywords() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/display-keywords', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question keywords are now' + (that.question.displayKeywords ? ' shown' : ' hidden.'),
                    });
                })
                .catch(function (errors) {
                    that.question.displayKeywords = !that.question.displayKeywords;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to toggle question answers at this moment, please try again later',
                    });
                });
        },
        toggleComments() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/display-comments', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question comments are now' + (that.question.displayComments ? ' shown' : ' hidden.'),
                    });
                })
                .catch(function (errors) {
                    that.question.displayComments = !that.question.displayComments;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to toggle question comments at this moment, please try again later',
                    });
                });
        },
        toggleVotes() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/display-votes', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question keywords are now' + (that.question.displayVotes ? ' shown' : ' hidden.'),
                    });
                })
                .catch(function (errors) {
                    that.question.displayVotes = !that.question.displayVotes;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to toggle question votes at this moment, please try again later',
                    });
                });
        },
        reorderAnswers() {
            if (this.question.type != 'openended' || !['preparing', 'paused', 'ongoing', 'ended', 'released'].includes(this.question.eGallery.status)) {
                return;
            }
            for (var i = 0; i < this.question.answers.length; i++) {
                this.question.answers[i]._name = this.question.answers[i].team ? this.question.answers[i].team.name : this.question.answers[i].student.fullname;
                this.question.answers[i]._voteCount = this.question.answers[i].votes.length;
            }

            this.natSort(this.question.answers, '_name');

            for (var i = 0; i < this.question.answers.length; i++) {
                this.question.answers[i]._natSortIndex = i;
            }

            for (var i = 0; i < this.question.answers.length; i++) {
                if (this.question.answers[i].isGoodAnswer == 1) {
                    this.question.answers[i]._goodAnswer = this.question.answers[i].team
                        ? this.question.answers[i].team.name
                        : this.question.answers[i].student.fullname;
                }
            }

            if (this.reorder.sort == 'teams') {
                return;
            } else if (this.reorder.sort == 'votes') {
                this.question.answers = _.orderBy(this.question.answers, ['_voteCount', '_natSortIndex'], ['desc', 'asc']);
            } else if (this.reorder.sort == 'goodAnswer') {
                this.question.answers = _.orderBy(this.question.answers, ['_goodAnswer', '_natSortIndex'], ['asc']);
            } else {
                this.question.answers = _.orderBy(
                    this.question.answers,
                    [
                        function (object) {
                            return new Date(object.dateCompleted);
                        },
                    ],
                    ['desc']
                );
            }
        },
        saveDisplayAnswers() {
            var that = this;
            let data = {
                activityQuestionUuid: this.question.uuid,
                answers: [],
            };
            for (var i = 0; i < this.question.answers.length; i++) {
                let answer = this.question.answers[i];
                if (!answer.uuid) {
                    continue;
                }
                data.answers.push({
                    uuid: answer.uuid,
                    isSelected: answer.isSelected ? true : false,
                });
            }
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/select-answers', data)
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Display answers have been saved',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to save display answers at this moment, please try again later',
                    });
                });
        },
        openDisplayAnswers() {
            $('#displayAnswers-' + this.question.uuid).modal('show');
        },
        openReleaseConfirmationModal() {
            //this.openStartPasswordModal();
            //return;
            $('#eGalleryReleaseModal' + this.question.uuid).modal('show');
        },
        openEndConfirmationModal() {
            //this.openStartPasswordModal();
            //return;
            $('#eGalleryEndModal' + this.question.uuid).modal('show');
        },
        openStartConfirmationModal() {
            //this.openStartPasswordModal();
            //return;
            $('#eGalleryStartModal' + this.question.uuid).modal('show');
        },
        openCancelConfirmationModal() {
            //this.openStartPasswordModal();
            //return;
            $('#eGalleryCancelModal' + this.question.uuid).modal('show');
        },
        openExtendModal() {
            $('#eGalleryProcessExtendModal' + this.question.uuid).modal('show');
        },
        initializeEGalleryWalkData() {
            this.question.eGallery.votingMethod = this.eGallerySettings.votingMethod;
            for (var i = 0; i < this.question.answers.length; i++) {
                if (this.eGallerySettings.answerSelectionForVoting == 'all') {
                    this.question.answers[i].isSelected = true;
                    //Vue.set(this.question.answers[i], 'isSelected', true);
                }
                if (this.eGallerySettings.answerSelectionForVoting == 'good') {
                    if (this.question.answers[i].isGoodAnswer) {
                        this.question.answers[i].isSelected = true;
                        //Vue.set(this.question.answers[i], 'isSelected', true);
                    } else {
                        this.question.answers[i].isSelected = false;
                        //Vue.set(this.question.answers[i], 'isSelected', false);
                    }
                }
            }
            this.eGallerySettings.step++;
        },
        openResetConfirmationModal() {
            //this.openStartPasswordModal();
            //return;
            $('#eGalleryResetModal' + this.question.uuid).modal('show');
        },
        initWalk() {
            let data = {};
            data.activityQuestionUuid = this.question.uuid;
            data.votingMethod = this.question.eGallery.votingMethod;
            if (data.votingMethod == 'rank') {
                data.rankingValue = this.question.eGallery.rankingValue;
            }

            for (var i = 0; i < this.question.answers.length; i++) {
                let answer = this.question.answers[i];
                if (!answer.uuid) {
                    continue;
                }
                if (this.question.eGallery.votingMethod == 'best') {
                    answer.votes = [];
                    answer.comments = [];
                } else {
                    answer.ranks = [];
                    answer.comments = [];
                    for (var i = 0; i < this.question.eGallery.rankingValue; i++) {
                        answer.ranks.push([]);
                    }
                }
            }
            data.answers = [];
            for (var i = 0; i < this.question.answers.length; i++) {
                let answer = this.question.answers[i];
                if (!answer.uuid) {
                    continue;
                }
                data.answers.push({
                    uuid: answer.uuid,
                    isSelected: answer.isSelected ? true : false,
                    isGoodAnswer: answer.isGoodAnswer ? true : false,
                });
            }
            data.eGalleryWalkSettings = {
                teamsMustCommentForVotedAnswersOnly: this.eGallerySettings.teamsMustCommentForVotedAnswersOnly,
                teamsMustCommentForAllAnswers: this.eGallerySettings.teamsMustCommentForAllAnswers,
                timeLimitInMinutes: this.eGallerySettings.timeLimit ? this.eGallerySettings.timeLimitInMinutes : null,
                hasTimeLimit: this.eGallerySettings.timeLimit,
            };
            return data;
        },
        toggleAnswers(question) {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/display-answer', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question answers are now' + (that.question.displayAnswer ? ' shown' : ' hidden.'),
                    });
                })
                .catch(function (errors) {
                    that.question.displayAnswer = !that.question.displayAnswer;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to show question answers at this moment, please try again later',
                    });
                });
        },
        toggleStatistics() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/display-answer-statistics', { activityQuestionUuid: this.question.uuid })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question statistics are now' + (that.question.displayAnswerStatistics ? ' shown' : ' hidden.'),
                    });
                })
                .catch(function (errors) {
                    that.question.displayAnswerStatistics = !that.question.displayAnswerStatistics;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to show question statistics at this moment, please try again later',
                    });
                });
        },
        saveGrades() {
            var that = this;
            let grades = {
                activityQuestionUuid: this.question.uuid,
                answers: [],
            };
            for (var i = 0; i < this.question.answers.length; i++) {
                let grade = this.question.answers[i].applicationGrade != null ? this.question.answers[i].applicationGrade : 0;
                grades.answers.push({
                    uuid: this.question.answers[i].uuid,
                    grade: grade,
                });
            }
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/save', grades)
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Grades have been saved',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to save grades at this moment, please try again later',
                    });
                });
        },
        prepare() {
            var that = this;
            var questionUuid = this.question.uuid;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/prepare', {
                    activityQuestionUuid: questionUuid,
                })
                .then(function (response) {
                    that.question.eGallery.status = 'preparing';
                    //that.eGallerySettings.step = 1;
                    $('#eGalleryProcessStartModal' + questionUuid).modal('show');
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk is now being prepared',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to prepare eGallery walk',
                    });
                });
        },
        start() {
            //this.eGallerySettings.step = 0;
            var that = this;
            let data = {};
            data = this.initWalk();
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/start', data)
                .then(function (response) {
                    that.question.eGallery.status = 'ongoing';
                    that.question.eGalleryWalkSettings = {
                        startTime: response.data.data.startTime,
                        timeLimitInMinutes: response.data.data.timeLimitInMinutes,
                    };
                    /*that.$set(that.question, 'eGalleryWalkSettings', {
                        startTime: response.data.data.startTime,
                        timeLimitInMinutes: response.data.data.timeLimitInMinutes,
                    });*/
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk has been started',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to start eGallery walk at this moment, please try again later',
                    });
                });
        },
        pause() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/pause', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.question.eGallery.status = 'paused';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk has been paused',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to pause eGallery walk at this moment, please try again later',
                    });
                });
        },
        resume() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/resume', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.question.eGallery.status = 'ongoing';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk has been resumed',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to resume eGallery walk at this moment, please try again later',
                    });
                });
        },
        restart() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/restart', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.question.eGallery.status = 'ongoing';
                    //clear
                    that.initWalk();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk has been restarted',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to restart eGallery walk at this moment, please try again later',
                    });
                });
        },
        end() {
            this.stopTimer();

            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/end', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.question.eGallery.status = 'ended';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk has been ended',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to end eGallery walk at this moment, please try again later',
                    });
                });
        },
        extend() {
            this.stopTimer();

            var that = this;
            let data = this.initWalk();
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/extend', data)
                .then(function (response) {
                    that.question.eGallery.status = 'ongoing';
                    that.question.eGalleryWalkSettings = {
                        startTime: response.data.data.startTime,
                        timeLimitInMinutes: response.data.data.timeLimitInMinutes,
                    };
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk has been extended',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to extend eGallery walk at this moment, please try again later',
                    });
                });
        },
        cancel() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/cancel', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.question.eGallery.status = 'cancelled';
                    for (var i = 0; i < that.question.answers.length; i++) {
                        that.question.answers[i].isSelected = true;
                    }
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk has been canceled',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to cancel eGallery walk at this moment, please try again later',
                    });
                });
        },
        release() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/release', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.question.eGallery.status = 'released';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk has been released',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to release eGallery walk at this moment, please try again later',
                    });
                });
        },
        openModalMembersInfo(model, type = 'team') {
            var that = this;
            if (type == 'team') {
                this.previewModel.type = 'team';
                this.previewModel.team = model;
            }

            $('#membersModal' + this.question.uuid).modal('show');

            $('#membersModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewModel = { type: 'team', student: {}, team: {} };
            });
        },
        truncate(str, characters = 10) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
        stopTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
        },
        initTimer() {
            var that = this;
            this.stopTimer();

            var startTime = this.convertToReadableDate(_.get(this.question.eGalleryWalkSettings, 'startTime', '')).dateObj;

            var endTime = startTime.add(this.question.eGalleryWalkSettings.timeLimitInMinutes, 'minutes');
            this.timer = setInterval(function (startTime) {
                var now = startTime;
                var distance = moment.duration(endTime.diff(now));
                if (distance > 0) {
                    that.time.minutes = ('0' + (distance.minutes() + distance.hours() * 60)).slice(-2);
                    that.time.seconds = ('0' + distance.seconds()).slice(-2);
                } else {
                    // that.time.minutes = "00";
                    // that.time.seconds = "00";
                    that.end();
                }
            }, 1000);
        },
        updatedMins() {
            if (this.eGallerySettings.timeLimitInMinutes < this.min) {
                this.eGallerySettings.timeLimitInMinutes = this.min;
            }
        },
        openEnlargeImageModal(src, id) {
            if (id == this.question.uuid) {
                $('#' + this.question.uuid + '-enlarge-image-modal').modal('show');

                this.srcValue = src;

                $('#' + this.question.uuid + '-enlarge-image-modal').resizable({
                    handles: 'ne, se, sw, nw',
                });
            }
        },
        openFacultyNotesModal(question) {
            this.facultyNoteQuestion = question;

            this.$nextTick(() => {
                $('#facultyNotesModal-' + this.facultyNoteQuestion.uuid).modal('show');
            });
        },
        toggleSubMenu(event) {
            event.preventDefault();
            event.stopPropagation();
            this.closeAllSubMenus();
            const nextElement = event.target.nextElementSibling;
            if (nextElement && nextElement.classList) {
                nextElement.classList.toggle('show');
            }
        },
        closeAllMenu(event) {
            $('.cr-dropdowns').removeClass('open');
            this.closeAllSubMenus();
        },
        toggleDropdown(event) {
            event.preventDefault();
            event.stopPropagation();
            this.closeAllSubMenus();
            const menu = event.target.closest('.dropdown').querySelector('.dropdown-menu');
            if (menu) menu.classList.toggle('show');
        },
        closeAllSubMenus() {
            const subMenus = document.querySelectorAll('.dropdown-submenu .dropdown-menu');
            subMenus.forEach((subMenu) => subMenu.classList.remove('show'));
        },
        updateSelectedColumn(number) {
            this.selectedColumn = number;
            this.initialColumn = number;
        },
        handleColumnResize() {
            if (window.innerWidth < 992) {
                this.selectedColumn = 1;
            } else if (window.innerWidth < 1200) {
                if (this.initialColumn > 2) {
                    this.selectedColumn = 2;
                }
            } else if (window.innerWidth < 1300) {
                if (this.initialColumn > 3) {
                    this.selectedColumn = 3;
                }
            } else if (window.innerWidth < 1400) {
                if (this.initialColumn > 4) {
                    this.selectedColumn = 4;
                }
            } else {
                this.selectedColumn = this.initialColumn;
            }
        },
    },
    components: {
        mcq: require(`./partials/mcq.vue`).default,
        openended: require(`./partials/openended.vue`).default,
        vsa: require(`./partials/vsa.vue`).default,
        sequence: require(`./partials/sequence.vue`).default,
        matching: require(`./partials/matching.vue`).default,
        categorize: require(`./partials/categorize.vue`).default,
        'question-pagination': require(`./../../question-pagination.vue`).default,
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleColumnResize);
        window.removeEventListener('click', (event) => {
            const isImg = event.target.nodeName === 'IMG';
            if (!isImg) {
                return;
            }

            var questionUUID = event.target.closest('.panel').getAttribute('id').slice(0, 36);
            this.openEnlargeImageModal(event.target.src, questionUUID);
        });
        Events.off('e-gallery-reorder-answers');
        this.stopTimer();
    },
};
</script>
<style lang="scss" scoped>
.timer_Div {
    .time .number-div {
        background: #fff;
        padding: 6px;
        border-radius: 3px;
        font-size: 28px;
        font-weight: bold;
        line-height: 0.8;
    }

    .time .number-div:last-child {
        margin-left: 5px;
    }

    .colon {
        color: #fff;
        padding: 0 10px;
    }

    .timeText {
        font-size: 1rem;
        margin-bottom: 5px;
    }
}

.cr-dropdowns {
    position: relative;
    display: inline-flex;
    align-items: center;

    .dropdown-menu {
        padding: 0;
        background: #495060;
        border: none;
        left: inherit;
        right: 0;
        text-transform: none;

        &.padding10 {
            padding: 10px;
        }
    }

    .dropdown-submenu {
        position: relative;

        & > .dropdown-menu {
            top: 0;
            right: 100%;
            border-radius: 6px;
            min-width: 172px;
        }

        &:hover {
            & > .dropdown-menu {
                display: block;
            }

            & > a:after {
                border-left-color: #fff;
            }
        }

        & > a {
            display: flex;
            align-items: center;
        }

        & > div > label {
            min-width: 180px;
            justify-content: space-between;
            padding-right: 10px;
        }
    }

    .dropdown-submenu.pull-left {
        float: none;
    }

    .dropdown-submenu.pull-left > .dropdown-menu {
        left: -100%;
        margin-left: 10px;
        -webkit-border-radius: 6px 0 6px 6px;
        -moz-border-radius: 6px 0 6px 6px;
        border-radius: 6px 0 6px 6px;
    }

    .checkbox {
        margin: inherit;

        &:focus-within {
            color: #222;
            background: #dabd6e;
            outline: 4px solid #1941a6;
            outline-offset: 3px;
            box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
            border-radius: 8px;
        }

        input[type='checkbox'] {
            margin: 0;
            position: inherit;

            & ~ label {
                i {
                    width: 14px;
                    text-align: center;
                }

                div:first-child {
                    width: calc(100% - 16px);
                }

                div:last-child {
                    width: 16px;
                }
            }

            &:focus-visible {
                outline: none;
            }
        }

        label {
            min-height: inherit;
            padding: 10px;
            margin-left: 0;
            white-space: nowrap;
            font-size: 1rem;
            line-height: 1.4;
        }
    }

    li {
        color: #fff;

        div:hover {
            background: #dabd6e;
            color: #222;
        }

        a {
            padding: 10px;
            font-size: 1rem;
            color: inherit;

            &:focus {
                background: none;
            }

            &:hover,
            &:focus-visible {
                background: #dabd6e;
                color: #222;
            }
        }

        button {
            width: 100%;
            display: flex;
            justify-content: space-between;
            color: inherit;
            font-weight: normal;
            border-radius: 0;

            &:hover,
            &:focus-visible {
                background: #dabd6e;
                color: #222;
            }
        }
    }
}

@media (max-width: 992px) {
    .cr-dropdowns {
        &.marginLeft20 {
            margin-left: 0;
        }

        .dropdown-menu {
            left: 0;
            width: fit-content;
        }
    }
}

.night-mode {
    .darkGreen {
        .timeText {
            color: #45b86f;
        }
    }
}
</style>
