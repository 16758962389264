import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { defineCourseAbilities, defineRolesAbilities } from './modules/course.js';
function updateAbility(user) {
    const { can, rules } = new AbilityBuilder(createMongoAbility);
    if (user.role === 'Superuser') {
        can(['create', 'read', 'update'], ['Superuser', 'Account']);
        if (!user.isDeletable) {
            can('delete', 'Superuser');
        }
    } else if (user.role === 'Super Admin') {
        can(['read'], ['Course']);
    } else if (user.role == 'Teacher') {
        can(['read'], ['Course']);
    }
    return rules;
}
export { updateAbility, defineCourseAbilities, defineRolesAbilities };
