<template>
    <div>
        <kr-panel :with-footer="false">
            <template #title>
                <div class="col-xs-12 flex flexSpaceBetween align-items">
                    <div class="col-xs-12 col-sm-6">
                        <h2>
                            <template v-if="mode == 'view'"> Course Info </template>
                            <template v-else> Edit Course </template>
                        </h2>
                    </div>
                    <div class="flexRight textAlignRight">
                        <template v-if="mode == 'view'">
                            <button
                                v-tooltip="{
                                    content: $can('update_course_settings', 'Course-' + course.uuid) ? '' : 'You do not have permission to edit this course',
                                }"
                                class="btn btn-default primaryHover"
                                :class="{ 'disabled hasToolTip': !$can('update_course_settings', 'Course-' + course.uuid) }"
                                @click.prevent="$can('update_course_settings', 'Course-' + course.uuid) ? (mode = 'edit') : ''"
                            >
                                <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                            </button>
                        </template>
                        <template v-else>
                            <button class="btn btn-default" exact @click.prevent="mode = 'view'">Cancel</button>
                            <button class="btn btn-success" @click.prevent="submitForm">Update</button>
                        </template>
                        <div v-if="mode == 'view'" class="viewDropdown dropdown marginLeft20">
                            <button id="viewDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="More Actions">
                                More Actions
                                <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                            </button>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                <ul>
                                    <li>
                                        <a
                                            v-tooltip="{
                                                content: $can('duplicate_course', 'Course-' + course.uuid)
                                                    ? ''
                                                    : 'You do not have permission to duplicate this course',
                                            }"
                                            class="primary-state"
                                            :class="{
                                                'disabled hasToolTip': !$can('duplicate_course', 'Course-' + course.uuid),
                                            }"
                                            href="#"
                                            @click.prevent="
                                                $can('duplicate_course', 'Course-' + course.uuid) ? $refs['DuplicateCourseComponent'].duplicate(course) : ''
                                            "
                                        >
                                            <span> <i class="fa fa-files-o marginRight8" aria-hidden="true" />Duplicate Course </span>
                                        </a>
                                    </li>

                                    <hr />

                                    <li v-if="!course.isArchived">
                                        <a
                                            v-tooltip="{
                                                content: $can('archive_course', 'Course-' + course.uuid)
                                                    ? ''
                                                    : 'You do not have permission to archive this course',
                                            }"
                                            class="danger-state"
                                            :class="{ 'disabled hasToolTip': !$can('archive_course', 'Course-' + course.uuid) }"
                                            href="#"
                                            @click.prevent="
                                                $can('archive_course', 'Course-' + course.uuid) ? $refs['ArchiveModalComponent'].archive(course) : ''
                                            "
                                        >
                                            <span> <i class="fa fa-archive marginRight8" aria-hidden="true" />Archive Course </span>
                                        </a>
                                    </li>

                                    <li v-else>
                                        <a class="primary-state" href="#" @click.prevent="$refs['ReinstateModalComponent'].reinstate(course)">
                                            <span> <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reinstate </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #content>
                <div v-if="mode == 'view'" class="col-xs-12 col-md-8">
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Course Name </label>
                            <p class="form-control-static">
                                {{ course.name }}
                            </p>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Course Code </label>
                            <p class="form-control-static">
                                {{ course.code ? course.code : '-' }}
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 flex marginBottom20">
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Course Starts From </label>
                            <div class="flexOnly">
                                <i class="fa fa-calendar marginRight8" aria-hidden="true" />
                                <span v-dompurify-html="parseReadableDate(course.startDate)" />
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Course Ends On </label>
                            <div class="flexOnly">
                                <i class="fa fa-calendar marginRight8" aria-hidden="true" />
                                <span v-dompurify-html="parseReadableDate(course.endDate)" />
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 flex marginBottom20">
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Created On </label>
                            <div class="flexOnly">
                                <i class="fa fa-calendar marginRight8" aria-hidden="true" />
                                <span v-dompurify-html="parseReadableDate(course.dateCreated, true)" />
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Created By </label>
                            <p class="form-control-static"><i class="fa fa-user-o marginRight8" aria-hidden="true" />{{ course.createBy }}</p>
                        </div>
                    </div>
                    <div class="col-xs-12 flex marginBottom20">
                        <div class="col-xs-12">
                            <label class="control-label"> Course Overview </label>
                            <div v-if="course.description" class="whiteSpacePreLine" v-dompurify-html="course.description" />
                            <div v-else>-</div>
                        </div>
                    </div>
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12">
                            <label class="control-label"> Course Thumbnail </label>
                            <div v-if="course.coverImage">
                                <div class="actual-image marginTop20" :style="'background-image:url(' + course.coverImage + ');'" />
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-xs-12 col-md-6">
                    <courseForm :form="form" @updatedDate="dateChanged = true" @newStartDateValue="getStartDate" @newEndDateValue="getEndDate" />

                    <div class="marginTop20 gap10" :class="{ buttonGroup: !isBelow768px, flex: isBelow768px }">
                        <button class="btn btn-default margin0" exact @click.prevent="mode = 'view'">Cancel</button>
                        <button
                            class="btn btn-success"
                            @click.prevent="dateChanged ? $refs['DateChangedModalComponent'].applyToModules(course, newStartDate, newEndDate) : submitForm()"
                        >
                            Update
                        </button>
                    </div>
                </div>
            </template>
        </kr-panel>

        <duplicateCourseComponent ref="DuplicateCourseComponent" />
        <archiveModalComponent ref="ArchiveModalComponent" />
        <reinstateModalComponent ref="ReinstateModalComponent" />
        <dateChangedModalComponent ref="DateChangedModalComponent" @apply-date-to-modules="submitForm" />
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';

import KrForm from '../../../../components/forms/form';

import courseForm from './partial.course.vue';
import duplicateCourseComponent from '../actions/duplicateCourse.vue';
import archiveModalComponent from '../actions/archiveModal.vue';
import reinstateModalComponent from '../actions/reinstateModal.vue';
import dateChangedModalComponent from '../actions/dateChanged.vue';

const props = defineProps({
    course: {
        type: Object,
        required: true,
    },
});

const dateChanged = ref(false);

const emit = defineEmits(['course-updated']);

const mode = ref('view');
const form = new KrForm({
    fields: {
        name: '',
        code: '',
        description: 'type!:array',
        startDate: '',
        endDate: '',
        coverImage: '',
        updateModuleDates: 'type!:boolean|default!:false',
    },
});

const newStartDate = ref('');
const newEndDate = ref('');

const getStartDate = (newDate) => {
    newStartDate.value = newDate;
};

const getEndDate = (newDate) => {
    newEndDate.value = newDate;
};

const submitForm = ($event) => {
    form.model.updateModuleDates = $event;

    form.patch(`courses/${props.course.uuid}`).then((response) => {
        mode.value = 'view';
        emit('course-updated', response.data.data);
    });
};

onMounted(() => {
    form.setModel(props.course);
    form.model.description = {
        isHTML: false,
        description: props.course.description || '',
    };
});
</script>
