<template>
    <div class="tabs-sticky marginBottom20" ref="tabsSticky">
        <div class="tabs-wrapper">
            <div role="tablist" ref="tabListRef" @keydown="handleKeyNavigation">
                <button
                    v-for="tab in filteredTabs"
                    :key="tab.id"
                    class="nav-link"
                    :class="{ active: currentTab == tab.id }"
                    :id="`${tab.id}-tab`"
                    data-toggle="tab"
                    :data-target="`#${tab.id}-panel`"
                    type="button"
                    role="tab"
                    :tabindex="currentTab === tab.id ? 0 : -1"
                    :aria-controls="`${tab.id}-panel`"
                    :aria-selected="currentTab == tab.id"
                    @click.prevent="currentTab = tab.id"
                >
                    {{ tab.label }}
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

const emit = defineEmits(['emitCurrentTab', 'filterTabs']);

const props = defineProps({
    arrayValue: {
        type: Array,
        required: true,
    },
});

const tabListRef = ref(null);
const currentTab = ref('');
const tabsSticky = ref(null);

const filteredTabs = computed(() => {
    emit(
        'filterTabs',
        props.arrayValue.filter((tab) => tab.show)
    );
    return props.arrayValue.filter((tab) => tab.show);
});

const tabStickyTop = () => {
    var topOffset = 0;

    if ($(window).width() < 992) {
        if (isNaN($('.fixed-banner-container').outerHeight())) {
            topOffset = $('.top_nav.mobile').outerHeight();
        } else {
            topOffset = $('.fixed-banner-container').outerHeight() + $('.top_nav.mobile').outerHeight();
        }
    } else {
        if (isNaN($('.fixed-banner-container').outerHeight())) {
            topOffset = $('.top_nav.desktop').outerHeight();
        } else {
            topOffset = $('.fixed-banner-container').outerHeight() + $('.top_nav.desktop').outerHeight();
        }
    }

    tabsSticky.value.style.top = `${topOffset}px`;
};

const handleKeyNavigation = (e) => {
    if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
        const tabButtons = tabListRef.value.querySelectorAll('[role="tab"]');
        const tabCount = tabButtons.length;

        const currentFocusIndex = Array.from(tabButtons).findIndex((tab) => tab === document.activeElement);

        const direction = e.key === 'ArrowRight' ? 1 : -1;
        const newIndex = (currentFocusIndex + direction + tabCount) % tabCount;

        // currentTab.value = filteredTabs.value[newIndex].id;

        tabButtons[newIndex].focus();
        e.preventDefault();
    }
};

watch(currentTab, () => {
    router.replace({ query: { ...route.query, tab: currentTab.value } }).then(() => {
        tabStickyTop();
        emit('emitCurrentTab', currentTab.value);
    });
});

onMounted(() => {
    currentTab.value = route.query.tab || filteredTabs.value[0].id;
});
</script>
