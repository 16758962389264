<template>
    <div>
        <div v-if="mode == 'topbar'" class="assume-leadership-div" role="banner" aria-label="Team Reporter Banner">
            <template v-if="!isTeamLeader">
                <div class="flex align-items">
                    <div class="inlineFlex align-items marginRight8">
                        <i class="fas fa-user-circle marginRight8" aria-hidden="true" />
                        <p class="fontBold fontSize16 paddingTop5">
                            {{ getStudentName(teamLeader) }} is the reporter for
                            {{ testObj.myProgression.team.name }}
                        </p>
                    </div>
                    <button
                        v-if="
                            !testObj.teamsNotAllowedToUpdateTeamLeader ||
                            (testObj.teamsNotAllowedToUpdateTeamLeader && !testObj.teamsNotAllowedToUpdateTeamLeader.includes(testObj.myProgression.team.uuid))
                        "
                        class="btn btn-outline-default margin0 flexInherit"
                        @click.prevent="changeTeamLeader(myOwnCredentials)"
                    >
                        <i class="fa fa-users marginRight8" aria-hidden="true" />Take over as reporter
                    </button>
                    <p v-else tabindex="0">(Teacher has disabled changing team reporter)</p>
                </div>
            </template>

            <template v-else>
                <div :class="{ 'flex align-items': !isMobileView }">
                    <div class="inlineFlex align-items marginRight8">
                        <i class="fas fa-user-circle marginRight8" aria-hidden="true" />
                        <p class="fontBold fontSize16 paddingTop5">
                            You are the team reporter for
                            {{ testObj.myProgression.team.name }}
                        </p>
                    </div>
                    <button
                        v-if="
                            !testObj.teamsNotAllowedToUpdateTeamLeader ||
                            (testObj.teamsNotAllowedToUpdateTeamLeader && !testObj.teamsNotAllowedToUpdateTeamLeader.includes(testObj.myProgression.team.uuid))
                        "
                        class="btn btn-outline-default margin0 flexInherit"
                        @click.prevent="viewTeam()"
                    >
                        <i class="fa fa-users marginRight8" aria-hidden="true" />Reporter
                        <template v-if="!testObj.allowTeamReporterToMarkAttendance"> Settings </template>
                        <template v-if="testObj.allowTeamReporterToMarkAttendance"> and Attendance Settings </template>
                    </button>
                    <p v-else>(Teacher has disabled changing team reporter)</p>
                </div>
            </template>
        </div>
        <div
            :id="'assign_reporter_' + id"
            class="modal assign_reporter default-modal"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'assignReporterModal' + id + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'assignReporterModal' + id + '-title'" class="modal-title">
                            Reporter
                            <template
                                v-if="
                                    !testObj.allowTeamReporterToMarkAttendance ||
                                    ['scheduled', 'not started', 'ended', 'completed'].includes(testObj.status) ||
                                    testObj.myProgression.userTestStatus == 'finished'
                                "
                            >
                                Settings
                            </template>
                            <template v-else> and Attendance Settings </template>
                        </h2>
                    </div>
                    <div class="modal-body">
                        <p>
                            <template
                                v-if="
                                    !testObj.allowTeamReporterToMarkAttendance ||
                                    ['scheduled', 'not started', 'ended', 'completed'].includes(testObj.status) ||
                                    testObj.myProgression.userTestStatus == 'finished'
                                "
                            >
                                Assign others as team reporter.
                                <br />
                                Team attendance can only be taken if teacher allows team reporter to take attendance after activity has started
                            </template>
                            <template v-else> Assign others as team reporter or take team attendance. </template>
                        </p>

                        <div class="table-responsive">
                            <table class="width100 border0">
                                <thead>
                                    <th class="border0" scope="col">Members Name</th>
                                    <th
                                        v-if="
                                            !testObj.allowTeamReporterToMarkAttendance ||
                                            ['scheduled', 'not started', 'ended', 'completed'].includes(testObj.status) ||
                                            testObj.myProgression.userTestStatus == 'finished'
                                        "
                                        class="border0 textAlignCenter"
                                        scope="col"
                                    >
                                        Attendance
                                    </th>
                                    <th v-else class="border0 textAlignCenter" scope="col">Mark Attendance</th>
                                    <th class="border0 textAlignCenter" scope="col">Assign Reporter</th>
                                </thead>
                                <tbody>
                                    <template v-for="(student, student_idx) in testObj.myProgression.team.students">
                                        <tr>
                                            <td class="border0">
                                                <div class="flexOnly align-items">
                                                    <div class="status-avatar">
                                                        <div
                                                            class="status-avatar-content"
                                                            :class="{
                                                                present: student.isPresent,
                                                                absent: !student.isPresent,
                                                            }"
                                                        >
                                                            <template v-if="student.profileURL">
                                                                <div class="actual-image" />
                                                            </template>
                                                            <template v-else>
                                                                {{ getStudentInitials(student) }}
                                                            </template>
                                                        </div>
                                                        <div
                                                            class="position-absolute"
                                                            :class="{
                                                                present: student.isPresent,
                                                                absent: !student.isPresent,
                                                            }"
                                                        >
                                                            <i class="fas fa-circle" aria-hidden="true" />
                                                        </div>
                                                    </div>
                                                    <div class="fontBold">
                                                        {{ getStudentName(student) }}
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="textAlignCenter border0">
                                                <template v-if="student.uuid != testObj.myProgression.team.teamLeaderUuid">
                                                    <template
                                                        v-if="
                                                            testObj.allowTeamReporterToMarkAttendance &&
                                                            !(
                                                                ['scheduled', 'not started', 'ended', 'completed'].includes(testObj.status) ||
                                                                testObj.myProgression.userTestStatus == 'finished'
                                                            )
                                                        "
                                                    >
                                                        <div class="toggle-token">
                                                            <template v-if="student.isPresent">
                                                                <input
                                                                    :id="'attendance-' + student_idx"
                                                                    type="checkbox"
                                                                    :checked="student.isPresent"
                                                                    @click.prevent="markStudentAbsent(student)"
                                                                />
                                                                <label :for="'attendance-' + student_idx"> Mark as Absent </label>
                                                            </template>
                                                            <template v-else>
                                                                <input
                                                                    :id="'attendance-' + student_idx"
                                                                    type="checkbox"
                                                                    :checked="student.isPresent"
                                                                    @click.prevent="markStudentPresent(student)"
                                                                />
                                                                <label :for="'attendance-' + student_idx"> Mark as Present </label>
                                                            </template>
                                                        </div>
                                                    </template>

                                                    <template v-else>
                                                        <template v-if="student.isPresent">
                                                            <div class="attendanceTag-green inlineFlex align-items">
                                                                <i class="fas fa-user-check marginRight8" aria-hidden="true" />
                                                                <p>Present</p>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <div class="attendanceTag-gray inlineFlex align-items">
                                                                <i class="fas fa-user-times marginRight8" aria-hidden="true" />
                                                                <p>Absent</p>
                                                            </div>
                                                        </template>
                                                    </template>
                                                </template>
                                            </td>
                                            <td class="textAlignCenter border0">
                                                <template v-if="student.uuid != testObj.myProgression.team.teamLeaderUuid">
                                                    <button
                                                        :disabled="!canAssignOtherTeammates ? true : undefined"
                                                        class="btn btn-success"
                                                        :aria-label="'Assign ' + getStudentName(student) + ' as team reporter'"
                                                        @click="changeTeamLeader(student)"
                                                    >
                                                        Assign
                                                    </button>
                                                </template>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal"><i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back</button>
                    </div>
                </div>
            </div>
        </div>
        <div
            :id="'you_have_been_assigned_team_leader_modal_' + id"
            class="modal default-modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'assignedAsTeamLeaderModal' + id + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'assignedAsTeamLeaderModal' + id + '-title'" class="modal-title">Notice</h2>
                    </div>
                    <div class="modal-body">
                        <p>You have been assigned as new reporter.</p>
                        <button class="col-xs-12 btn btn-success marginTop30" data-dismiss="modal" aria-label="Ok">Ok</button>
                    </div>
                </div>
            </div>
            <div
                :id="'teacher_has_frozen_team_leader_modal_' + id"
                class="modal"
                style="z-index: 50001 !important"
                tabindex="-1"
                role="dialog"
                :aria-labelledby="'frozenTeamLeaderModal' + id + '-title'"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                                <i class="fa-solid fa-xmark" aria-hidden="true" />
                            </button>

                            <h2 :id="'frozenTeamLeaderModal' + id + '-title'" class="modal-title">Teacher has frozen any changes on team reporter</h2>
                        </div>
                        <div class="modal-body">
                            <p>Teacher has frozen any changes on team reporter</p>
                            <button class="col-xs-12 btn btn-success marginTop30" data-dismiss="modal" aria-label="Ok">Ok</button>
                        </div>
                        <div class="modal-footer" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { testMixins } from './../../../../../mixins/test';
import KrAuth from '../../../../../components/auth/auth';
export default {
    mixins: [testMixins],
    props: ['options'],
    data() {
        return {
            mode: 'topbar', //topbar & stopping
            id: (+new Date()).toString(36).slice(-5),
            auth: new KrAuth(),
        };
    },
    computed: {
        teamLeader() {
            if (this.testObj.type == 'irat' || (this.testObj.type == 'application' && this.testObj.others.applicationType != 'team')) {
                return null;
            }
            var that = this;
            return _.find(this.testObj.myProgression.team.students, function (o) {
                return o.uuid == that.testObj.myProgression.team.teamLeaderUuid;
            });
        },
        canAssignOtherTeammates() {
            return true;
        },
    },
    created() {
        this.$emit('isTeamLeader', this.isTeamLeader);
        this.mode = _.get(this.options, 'mode', 'topbar');
        this.processEchoListeners();
    },
    beforeUnmount() {
        window.Echo.leave(`test.${this.testObj.testUuid}.team.${this.testObj.teamUuid}`);
    },
    methods: {
        processEchoListeners() {
            var that = this;
            if (this.testObj.type == 'trat' || (this.testObj.type == 'application' && this.testObj.others.applicationType == 'team')) {
                let h1 = (e) => {
                    if (this.testObj.myProgression.team.teamLeaderUuid != e.teamLeaderUuid) {
                        this.testObj.myProgression.team.teamLeaderUuid = e.teamLeaderUuid;
                        Vue.nextTick(function () {
                            that.$emit('isTeamLeader', that.isTeamLeader);
                            if (that.isTeamLeader) {
                                $('#you_have_been_assigned_team_leader_modal_' + that.id).modal('show');
                            }
                        });
                    }
                };
                let c1 = window.Echo.join(`test.${this.testObj.testUuid}.team.${this.testObj.myProgression.team.uuid}`).listen('TeamReporterUpdated', h1);
                this.echoChannels.push({
                    channel: c1,
                    event: 'TeamReporterUpdated',
                    handler: h1,
                });

                window.Echo.join(`test.${this.testObj.testUuid}.team.${this.testObj.myProgression.team.uuid}`)
                    .here((users) => {
                        for (var i = 0; i < users.length; i++) {
                            let idx = _.findIndex(that.testObj.myProgression.team.students, function (o) {
                                return o.uuid == users[i].uuid;
                            });
                            if (idx != -1) {
                                // that.testObj.myProgression.team.students[
                                //     idx
                                // ]._isPresent = true;
                                that.testObj.myProgression.team.students[idx].isPresent = true;
                            }
                        }
                        that.testObj.myProgression.team.students.splice();
                    })
                    .joining((user) => {
                        let idx = _.findIndex(that.testObj.myProgression.team.students, function (o) {
                            return o.uuid == user.uuid;
                        });
                        if (idx != -1) {
                            // that.testObj.myProgression.team.students[
                            //     idx
                            // ]._isPresent = true;
                            that.testObj.myProgression.team.students[idx].isPresent = true;
                        }
                        that.testObj.myProgression.team.students.splice();
                    })
                    .leaving((user) => {
                        let idx = _.findIndex(that.testObj.myProgression.team.students, function (o) {
                            return o.uuid == user.uuid;
                        });
                        if (idx != -1) {
                            // that.testObj.myProgression.team.students[
                            //     idx
                            // ]._isPresent = false;
                            that.testObj.myProgression.team.students[idx].isPresent = false;
                        }
                        that.testObj.myProgression.team.students.splice();
                    });

                let h3 = (e) => {
                    that.testObj.teamsNotAllowedToUpdateTeamLeader = e.teamsNotAllowedToUpdateTeamLeader || [];
                    that.testObj.teamsNotAllowedToUpdateTeamLeader.splice();
                };
                let c3 = window.Echo.private(`test.${this.testObj.testUuid}.student`).listen('TeacherTeamReporterToggled', h3);
                this.echoChannels.push({
                    channel: c3,
                    event: 'TeacherTeamReporterToggled',
                    handler: h3,
                });

                let h4 = (e) => {
                    that.testObj.allowTeamReporterToMarkAttendance = e.allowTeamReporterToMarkAttendance;
                };

                let c4 = window.Echo.private(`test.${this.testObj.testUuid}.reporter`).listen('TeamReporterMarkAttendanceToggled', h4);
                this.echoChannels.push({
                    channel: c4,
                    event: 'TeamReporterMarkAttendanceToggled',
                    handler: h4,
                });

                let h5 = (e) => {
                    let studentIndex = -1;
                    for (let i = 0; i < that.testObj.myProgression.team.students.length; i++) {
                        if (that.testObj.myProgression.team.students[i].uuid === e.userPlacementTestUuid) {
                            studentIndex = i;

                            that.testObj.myProgression.team.students[i].isPresent = e.isPresent;
                            break;
                        }
                        if (studentIndex !== -1) {
                            break;
                        }
                    }
                };
                let c5 = window.Echo.join(`test.${this.testObj.testUuid}.team.${this.testObj.myProgression.team.uuid}`).listen('StudentAttendanceUpdated', h5);
                this.echoChannels.push({
                    channel: c5,
                    event: 'StudentAttendanceUpdated',
                    handler: h5,
                });
            }
        },
        viewTeam() {
            $('#assign_reporter_' + this.id).modal('show');
        },
        changeTeamLeader(student) {
            var that = this;
            let oldTeamLeaderUuid = this.testObj.myProgression.team.teamLeaderUuid;
            this.testObj.myProgression.team.teamLeaderUuid = student.uuid;
            axios
                .put('/student/tests/' + this.testObj.uuid + '/update/team-leader', {
                    userPlacementTestUuid: student.uuid,
                })
                .then(function (response) {
                    $('#assign_reporter_' + that.id).modal('hide');
                    that.$emit('isTeamLeader', that.isTeamLeader);
                    if (that.isTeamLeader) {
                        $('#you_have_been_assigned_team_leader_modal_' + that.id).modal('show');
                    }
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Team leader has been changed',
                    });
                })
                .catch(function (errors) {
                    $('#assign_reporter_' + that.id).modal('hide');
                    that.testObj.myProgression.team.teamLeaderUuid = oldTeamLeaderUuid;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to change team leader  at this time, please try again later',
                    });
                });
        },
        getStudentInitials(student) {
            if (!student) {
                return '';
            }
            return (student.firstname ? student.firstname.charAt(0) : '') + (student.lastname ? student.lastname.charAt(0) : '');
        },
        getStudentName(student, limit = true) {
            if (!student) {
                return '';
            }
            var name = student.displayName ? student.displayName : student.firstname + (student.lastname ? ' ' + student.lastname : '');
            if (name.length > 20 && limit) {
                return name.slice(0, 17) + '...';
            }
            return name;
        },
        markStudentPresent(student) {
            var that = this;
            axios
                .post('/student/tests/' + student.uuid + '/attendance/present')
                .then(function (response) {
                    student.isPresent = true;
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: `${student.firstname} is mark as present`,
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'danger',
                        title: 'Error',
                        text: 'Something went wrong. Please try again later',
                    });
                });
        },
        markStudentAbsent(student) {
            var that = this;
            axios
                .post('/student/tests/' + student.uuid + '/attendance/absent')
                .then(function (response) {
                    student.isPresent = false;
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: `${student.firstname} is mark as absent`,
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'danger',
                        title: 'Error',
                        text: 'Something went wrong. Please try again later',
                    });
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.assume-leadership-div {
    border: 1px solid #0071be;
    border-radius: 3px;
    background: #cfe2f3;
    padding: 10px 20px;
}

span.assume-leadership {
    color: #222222;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
}
span.team-initial {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: #495060;
    line-height: 26px;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    text-align: center;
}

.fas.fa-user-circle {
    font-size: 20px;
    color: #8a8a8a;
}

.assign_reporter .profile_image {
    width: 48px;
    height: 48px;
    border-radius: 30px;
    margin-right: 10px;
}

.assign_reporter .actual-image {
    width: 100%;
    height: 100%;
    background: #222;

    background-position: center;
    background-size: cover;
}

.assignDiv {
    margin-top: 30px;
}

.assignDiv:first-child {
    margin-top: 0;
}

.status-avatar {
    min-width: 48px;
    height: 48px;
    margin-right: 10px;
    position: relative;

    .status-avatar-content {
        width: 100%;
        height: 100%;
        background: #2a3f54;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 18px;
        color: white;

        &.absent {
            opacity: 0.5;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .position-absolute {
        position: absolute;
        bottom: -2px;
        right: -5px;
        line-height: 0.8;

        &.present {
            color: #3c7858;
        }

        &.absent {
            color: #717171;
        }

        .fas.fa-circle {
            border: 3px solid #fff;
            border-radius: 50%;
        }
    }
}

.attendanceMarker {
    border-radius: 8px;
    padding: 6px 12px;

    &.present {
        background: #0071be;
        border: 1px solid #0071be;
        color: #fff;

        &:hover {
            background: #1941a6;
            border: 1px solid #1941a6;
        }
    }
}

.assume-leadership-div {
    .fa-user-circle {
        color: #222;
    }
}
</style>
