<template>
    <div>
        {{ result }}
    </div>
</template>

<script>
export default {
    data() {
        return {
            result: {},
        };
    },
    created() {
        if (this.$route.name == 'launchexternal') {
            this.sendLaunchExternal();
        } else if (this.$route.name == 'courses.accept-collaborator') {
            this.acceptCourseCollaborator();
        } else if (this.$route.name == 'courses.deny-collaborator') {
            this.denyCourseCollaborator();
        }
    },
    methods: {
        sendLaunchExternal() {
            var that = this;
            axios
                .post(`auth/external/tokens`, {
                    url: this.$route.query.url,
                    name: this.$route.query.name,
                })
                .then(function (response) {
                    that.result = response.data;
                    window.location = that.result.url + `?token=${that.result.token}`;
                })
                .catch(function (errors) {
                    that.result = _.get(errors, 'response.data');
                });
        },
        acceptCourseCollaborator() {
            var that = this;
            axios
                .post(`courses/${this.$route.query.courseUuid}/collaborator-requests/${this.$route.query.requestId}/approve`)
                .then(function (response) {
                    that.result = response.data;
                    that.$router.push({ name: 'courses.view', params: { id: that.$route.query.courseUuid }, query: { tab: 'collaborators' } });
                })
                .catch(function (errors) {
                    that.result = _.get(errors, 'response.data');
                    that.$router.push({ name: 'courses.view', params: { id: that.$route.query.courseUuid }, query: { tab: 'collaborators' } });
                });
        },
        denyCourseCollaborator() {
            var that = this;
            axios
                .post(`courses/${this.$route.query.courseUuid}/collaborator-requests/${this.$route.query.requestId}/deny`)
                .then(function (response) {
                    that.result = response.data;
                    that.$router.push({ name: 'courses.view', params: { id: that.$route.query.courseUuid }, query: { tab: 'collaborators' } });
                })
                .catch(function (errors) {
                    that.result = _.get(errors, 'response.data');
                    that.$router.push({ name: 'courses.view', params: { id: that.$route.query.courseUuid }, query: { tab: 'collaborators' } });
                });
        },
    },
};
</script>
