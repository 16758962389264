<template>
    <div class="panel-div">
        <p class="question">
            You have <strong>{{ total_points }} points</strong> to give out to your teammates
            <span class="visible-hidden"> The rules to consider when giving points are</span>
        </p>
        <div class="flex marginTop10">
            <p
                v-if="testObj.settings.shouldDistributePointsWithoutRepeatingValues"
                class="marginRight20 inlineFlex"
                :class="{
                    darkGreen: is_different_points,
                    greyText: !is_different_points,
                }"
            >
                <i
                    class="fas marginRight8"
                    :class="{
                        'fa-check-circle': is_different_points,
                        'fa-circle': !is_different_points,
                    }"
                ></i>
                Points to team members should be different
            </p>

            <p
                v-if="testObj.settings.shouldDistributeAllPoints"
                class="marginRight20 inlineFlex"
                :class="{
                    darkGreen: available_points == 0 && has_score,
                    greyText: available_points != 0,
                }"
            >
                <i
                    class="fas marginRight8"
                    :class="{
                        'fa-check-circle': available_points == 0 && has_score,
                        'fa-circle': available_points != 0,
                    }"
                    aria-hidden="true"
                ></i>
                Should distribute all points
            </p>

            <p
                v-if="testObj.settings.shouldGiveOneTeammateAboveTenPoints"
                class="marginRight20 inlineFlex"
                :class="{ darkGreen: is_above_ten, greyText: !is_above_ten }"
            >
                <i
                    class="fas marginRight8"
                    :class="{
                        'fa-check-circle': is_above_ten,
                        'fa-circle': !is_above_ten,
                    }"
                    aria-hidden="true"
                ></i>
                Should have at least one teammate with a point above 10
            </p>

            <p
                v-if="testObj.settings.shouldGiveOneTeammateBelowTenPoints"
                class="marginRight20 inlineFlex"
                :class="{ darkGreen: is_below_ten, greyText: !is_below_ten }"
            >
                <i
                    class="fas marginRight8"
                    :class="{
                        'fa-check-circle': is_below_ten,
                        'fa-circle': !is_below_ten,
                    }"
                    aria-hidden="true"
                ></i>
                Should have at least one teammate with a point below 10
            </p>
        </div>

        <div class="table-responsive">
            <table class="table marginTop20">
                <caption>
                    List of teammates
                </caption>
                <thead>
                    <tr>
                        <th>Teammate</th>
                        <th>
                            Points ( Remaining:
                            <span class="errorColor">{{ available_points }}</span>
                            )
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(model, idx) in testObj.michaelsenPoints" :key="'michaelsen-table-' + idx">
                        <td>
                            <legend class="visible-hidden">
                                row {{ idx }} student full name
                                {{ model.fullname }}
                            </legend>

                            <div class="flexOnly align-items" aria-hidden="true">
                                <div class="profile_image marginRight5">
                                    <div class="badge_profile img-circle">
                                        {{ initials(model.fullname) }}
                                    </div>
                                </div>
                                <div>{{ model.fullname }}</div>
                            </div>
                        </td>

                        <td>
                            <div class="numberInputButtons">
                                <button
                                    v-tooltip="
                                        model.point == 0 || disabled
                                            ? `Current point for ${model.fullname} is ${model.point == null ? 0 : model.point}, Cannot have negative point`
                                            : `Current point for ${model.fullname} is ${model.point == null ? 0 : model.point}, can decrease point to ${
                                                  model.point == null ? 0 : model.point - 1
                                              }`
                                    "
                                    class="btn btn-outline-dark"
                                    :class="{
                                        disabled: model.point == 0 || disabled,
                                    }"
                                    @click.prevent="model.point == 0 || disabled ? null : quantityDown(idx)"
                                >
                                    <i class="fas fa-minus" aria-hidden="true"></i>
                                </button>

                                <input
                                    v-tooltip="
                                        disabled
                                            ? `Current point for ${model.fullname} is ${
                                                  model.point == null ? 0 : model.point
                                              }, You have used all the points please use minus button to decrease value`
                                            : `Current point for ${model.fullname} is ${model.point == null ? 0 : model.point}, can increase point to ${
                                                  model.point == null ? 1 : model.point + 1
                                              }`
                                    "
                                    type="number"
                                    class="form-control"
                                    :value="model.point"
                                    min="0"
                                    :max="total_points"
                                    @keyup.prevent="changedPoint($event, idx)"
                                    :readonly="disabled ? true : undefined"
                                />

                                <button
                                    v-tooltip="
                                        available_points == 0 || disabled
                                            ? `Current point for ${model.fullname} is ${
                                                  model.point == null ? 0 : model.point
                                              }, You have used all the points please use minus button to decrease value`
                                            : `Current point for ${model.fullname} is ${model.point == null ? 0 : model.point}, can increase point to ${
                                                  model.point == null ? 1 : model.point + 1
                                              }`
                                    "
                                    class="btn btn-outline-dark margin0"
                                    :class="{
                                        disabled: available_points == 0 || disabled,
                                    }"
                                    @click.prevent="available_points == 0 || disabled ? null : quantityUp(idx)"
                                >
                                    <i class="fas fa-plus" aria-hidden="true"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { testMixins } from './../../../../../../mixins/test';
export default {
    props: ['testObj', 'disabled'],
    data() {
        return {
            debouncedUpdateAnswer: null,
            canSubmit: false,
        };
    },
    computed: {
        total_points() {
            return this.testObj.michaelsenPoints.length * _.get(this.testObj, 'settings.pointsAllocatedPerMember', 10);
        },
        available_points() {
            let used_points = 0;
            for (var i = 0; i < this.testObj.michaelsenPoints.length; i++) {
                used_points += this.testObj.michaelsenPoints[i].point ? this.testObj.michaelsenPoints[i].point : 0;
            }
            return this.total_points - used_points;
        },
        is_different_points() {
            for (var i = 0; i < this.testObj.michaelsenPoints.length; i++) {
                for (var j = 0; j < this.testObj.michaelsenPoints.length; j++) {
                    if (i != j && this.testObj.michaelsenPoints[i].point == this.testObj.michaelsenPoints[j].point) {
                        return false;
                    }
                }
            }
            return true;
        },
        is_above_ten() {
            for (var i = 0; i < this.testObj.michaelsenPoints.length; i++) {
                if (this.testObj.michaelsenPoints[i].point > 10) {
                    return true;
                }
            }
            return false;
        },
        is_below_ten() {
            for (var i = 0; i < this.testObj.michaelsenPoints.length; i++) {
                if (this.testObj.michaelsenPoints[i].point < 10 && this.testObj.michaelsenPoints[i].point) {
                    return true;
                }
            }
            return false;
        },
        has_score() {
            for (var i = 0; i < this.testObj.michaelsenPoints.length; i++) {
                if (this.testObj.michaelsenPoints[i].point == 0 || this.testObj.michaelsenPoints[i].point == null) {
                    return false;
                }
            }
            return true;
        },
    },
    watch: {
        available_points: {
            handler: function () {
                if (
                    ((this.testObj.settings.shouldDistributePointsWithoutRepeatingValues && this.is_different_points) ||
                        !this.testObj.settings.shouldDistributePointsWithoutRepeatingValues) &&
                    ((this.testObj.settings.shouldDistributeAllPoints && this.available_points == 0) || !this.testObj.settings.shouldDistributeAllPoints) &&
                    ((this.testObj.settings.shouldGiveOneTeammateAboveTenPoints && this.is_above_ten) ||
                        !this.testObj.settings.shouldGiveOneTeammateAboveTenPoints) &&
                    ((this.testObj.settings.shouldGiveOneTeammateBelowTenPoints && this.is_below_ten) ||
                        !this.testObj.settings.shouldGiveOneTeammateBelowTenPoints) &&
                    this.has_score
                ) {
                    this.canSubmit = true;
                    this.$emit('canSubmit', true);
                } else {
                    this.canSubmit = false;
                    this.$emit('canSubmit', false);
                }
            },
            deep: true,
        },
        canSubmit: {
            handler: function () {
                if (this.canSubmit) {
                    this.debouncedUpdateAnswer(this.testObj.michaelsenPoints);
                }
            },
            deep: true,
        },
    },
    created() {
        var that = this;
        if (!this.testObj._isPreview) {
            this.debouncedUpdateAnswer = _.debounce(
                function (data) {
                    var response = [];
                    for (var i = 0; i < data.length; i++) {
                        response.push({
                            uuid: data[i].userPlacementTestUuid,
                            point: data[i].point,
                        });
                    }
                    axios
                        .post(
                            '/student/michaelsen-points',
                            {
                                userPlacementTestUuid: that.testObj.uuid,
                                points: response,
                            },
                            { _internal: { hasLoadingBar: false } }
                        )
                        .then(function (response) {
                            //console.log(response);
                        })
                        .catch(function (errors) {
                            //clean out
                            for (var i = 0; i < data.length; i++) {
                                data[i].point = '';
                            }
                            that.$notify({
                                group: 'form',
                                type: 'error',
                                title: 'Error',
                                text: 'Something went wrong, please try again later',
                            });
                        });
                },
                1500,
                { trailing: true }
            );
        } else {
            this.debouncedUpdateAnswer = function () {
                return;
            };
        }
    },
    mounted() {
        if (
            ((this.testObj.settings.shouldDistributePointsWithoutRepeatingValues && this.is_different_points) ||
                !this.testObj.settings.shouldDistributePointsWithoutRepeatingValues) &&
            ((this.testObj.settings.shouldDistributeAllPoints && this.available_points == 0) || !this.testObj.settings.shouldDistributeAllPoints) &&
            ((this.testObj.settings.shouldGiveOneTeammateAboveTenPoints && this.is_above_ten) || !this.testObj.settings.shouldGiveOneTeammateAboveTenPoints) &&
            ((this.testObj.settings.shouldGiveOneTeammateBelowTenPoints && this.is_below_ten) || !this.testObj.settings.shouldGiveOneTeammateBelowTenPoints) &&
            this.has_score
        ) {
            this.$emit('canSubmit', true);
        } else {
            this.$emit('canSubmit', false);
        }
    },
    methods: {
        filterInteger(e) {
            if (!['Backspace', 'Delete', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
                return e.preventDefault(); // Don't do anything to the input value
            }
        },
        quantityUp(idx) {
            if (this.disabled) {
                return;
            }
            if (!this.testObj.michaelsenPoints[idx].point) {
                this.testObj.michaelsenPoints[idx].point = 0;
            }
            if (this.available_points != 0) {
                this.testObj.michaelsenPoints[idx].point++;
                //this.debouncedUpdateAnswer(this.testObj.michaelsenPoints);
            }
        },
        quantityDown(idx) {
            if (this.disabled) {
                return;
            }
            if (!this.testObj.michaelsenPoints[idx].point) {
                this.testObj.michaelsenPoints[idx].point = 0;
                //console.log("wrong spot minus clicked");
            }
            if (this.testObj.michaelsenPoints[idx].point != 0) {
                //console.log("minus clicked");
                this.testObj.michaelsenPoints[idx].point--;
                //this.debouncedUpdateAnswer(this.testObj.michaelsenPoints);
            }
        },
        changedPoint(event, idx) {
            let old_point = this.testObj.michaelsenPoints[idx].point;
            let new_point = parseInt(event.target.value);

            let temp_available_points = this.available_points + old_point - new_point;

            if (new_point == 0 || temp_available_points < 0) {
                $(event.target).val(old_point);
                return false;
            }

            if (!isNaN(new_point)) {
                this.testObj.michaelsenPoints[idx].point = new_point;
                $(event.target).val(new_point);
            } else {
                //cleared answer
                this.testObj.michaelsenPoints[idx].point = null;
            }
        },
        initials(name) {
            return name.charAt(0);
        },
    },
};
</script>
<style lang="scss" scoped>
.actual-image {
    width: 24px;
    height: 24px;

    background-position: center;
    background-size: cover;
}

.badge_profile {
    font-size: 12px;
}
</style>
