<template>
    <div
        ref="modal"
        class="modal primary-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="duplicateCourseModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="duplicateCourseModal-title" class="modal-title">Duplicate Course Confirmation</h2>
                </div>

                <div class="modal-body textAlignCenter">
                    <h3>Course Name</h3>
                    <h4>
                        {{ course.name }}
                        <template v-if="course.code"> ({{ course.code }}) </template>
                    </h4>

                    <p class="marginTop20">Are you sure you want to</p>
                    <p>make a copy of this course?</p>

                    <fieldset class="textAlignLeft newRadioField">
                        <legend class="margin0">Make a copy of</legend>
                        <div class="checkboxField">
                            <input
                                id="copyModules"
                                v-model="copyCourseForm.copyModules"
                                type="checkbox"
                                name="makeACopyOf"
                                :aria-checked="copyCourseForm.copyModules"
                                @change="toggleCourseDuplicate"
                            />
                            <label for="copyModules"> Copy modules to the new course </label>
                        </div>
                        <div class="checkboxField">
                            <input
                                id="copyActivities"
                                v-model="copyCourseForm.copyActivities"
                                v-tooltip="!copyCourseForm.copyModules ? 'Copy of Modules are not check' : ''"
                                :class="{
                                    'disabled hasTooltip': !copyCourseForm.copyModules,
                                }"
                                type="checkbox"
                                name="makeACopyOf"
                                :aria-checked="copyCourseForm.copyActivities"
                                @change="toggleCourseDuplicate"
                            />
                            <label for="copyActivities"> Copy activities to the new course </label>
                        </div>
                        <div class="checkboxField">
                            <input
                                id="copyActivityQuestions"
                                v-model="copyCourseForm.copyActivityQuestions"
                                v-tooltip="!copyCourseForm.copyActivities ? 'Copy of Activities are not check' : ''"
                                :class="{
                                    'disabled hasTooltip': !copyCourseForm.copyActivities,
                                }"
                                type="checkbox"
                                name="makeACopyOf"
                                :aria-checked="copyCourseForm.copyActivityQuestions"
                                @change="toggleCourseDuplicate"
                            />
                            <label for="copyActivityQuestions"> Copy activities questions to the new course </label>
                        </div>
                    </fieldset>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                    <button class="btn btn-success" @click.prevent="submit">Yes, Copy Course</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';

const router = useRouter();
const modal = ref(null);
const course = ref({});

const copyCourseForm = ref({
    copyModules: true,
    copyActivities: true,
    copyActivityQuestions: true,
});

const toggleCourseDuplicate = () => {
    if (copyCourseForm.value.copyModules == false) {
        copyCourseForm.value.copyActivities = false;
        copyCourseForm.value.copyActivityQuestions = false;
    }
    if (copyCourseForm.value.copyActivities == false) {
        copyCourseForm.value.copyActivityQuestions = false;
    }
};

const duplicate = (c = {}) => {
    course.value = c;
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .post('courses/' + course.value.uuid + '/duplicate', {
            copyModules: copyCourseForm.value.copyModules,
            copyActivities: copyCourseForm.value.copyActivities,
            copyActivityQuestions: copyCourseForm.value.copyActivityQuestions,
        })
        .then(function (response) {
            closeModal();
            router.push({
                name: 'courses.view',
                params: { id: response.data.data.uuid },
            });
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Course has been successfully copied',
            });

            Events.fire('fetchRolePermissions');
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Unable to copy course at this moment, please try again later',
            });
        });
};

defineExpose({ duplicate });
</script>
