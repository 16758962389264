<template>
    <div
        ref="modal"
        class="modal warning-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="dateChangedModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="dateChangeModal-title" class="modal-title">Date Updated</h2>
                </div>
                <div class="modal-body textAlignCenter">
                    <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                    <h2 class="marginTop40">You are now updating the dates for:</h2>
                    <h3 class="marginBottom20 fw-normal">
                        {{ course.name }}
                    </h3>

                    <template v-if="newStartDate != ''">
                        <h4 class="margin0"><strong>Current Start Date:</strong></h4>
                        <p class="fs-24px">
                            <span class="important">{{ convertToReadableDate(course.startDate, 'YYYY-MM-DD').date }}</span> to
                            <strong class="success">{{ newStartDate }}</strong>
                        </p>
                    </template>

                    <template v-if="newEndDate != ''">
                        <h4 :class="{ margin0: newStartDate == '', marginBottom0: newStartDate != '' }"><strong>Current End Date:</strong></h4>
                        <p class="fs-24px">
                            <span class="important">{{ convertToReadableDate(course.endDate, 'YYYY-MM-DD').date }}</span> to
                            <strong class="success">{{ newEndDate }}</strong>
                        </p>
                    </template>

                    <span class="inlineFlex marginTop20">
                        <div class="form-switch marginRight10">
                            <input id="updateModuleDatesToggle" v-model="applyToModulesToggle" type="checkbox" aria-label="Apply new course dates to modules" />
                            <span
                                role="switch"
                                :aria-checked="applyToModulesToggle"
                                class="slider round"
                                @click="applyToModulesToggle = !applyToModulesToggle"
                            />
                        </div>
                        <label for="updateModuleDatesToggle" class="textAlignLeft paddingTop2 fs-20px"> Apply new course dates to modules </label>
                    </span>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeModal()">No, Cancel</button>
                    <button class="btn btn-success" @click.prevent="submit()">Update Course Info</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick, watch } from 'vue';

const emit = defineEmits(['applyDateToModules']);

const modal = ref(null);
const course = ref({});
const newStartDate = ref('');
const newEndDate = ref('');
const applyToModulesToggle = ref(false);

const applyToModules = (c = {}, newStart = '', newEnd = '') => {
    course.value = c;
    newStartDate.value = newStart;
    newEndDate.value = newEnd;

    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    emit('applyDateToModules', applyToModulesToggle.value);
    closeModal();
};

defineExpose({ applyToModules });
</script>
