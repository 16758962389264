<template>
    <div
        ref="modal"
        class="modal primary-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="duplicateActivityModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="duplicateActivityModal-title" class="modal-title">Duplicate Activity</h2>
                </div>
                <div class="modal-body textAlignCenter">
                    <h2>Activity Name</h2>
                    <h3 class="fw-normal">{{ activity.name }}</h3>

                    <p class="marginTop20">Are you sure you want to</p>
                    <p>make a copy of the activity?</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                    <button class="btn btn-success" @click.prevent="submit">
                        <i class="fa fa-copy marginRight8" aria-hidden="true" />Yes, Duplicate Activity
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';

const modal = ref(null);
const activity = ref({});

const duplicate = (a = {}) => {
    activity.value = a;

    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .post(`activities/${activity.value.uuid}/duplicate`)
        .then(function (response) {
            closeModal();
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Activity has been successfully copied',
            });
            Events.fire('modules_listing_refresh');
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Unable to copy activity at this moment, please try again later',
            });
        });
};

defineExpose({ duplicate });
</script>
