<template>
    <div
        ref="modal"
        class="modal primary-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="generateGradesModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="generateGradesModal-title" class="modal-title">Generate {{ type == 'course' ? 'Course' : 'Activity' }} Grades Report</h2>
                </div>

                <div class="modal-body textAlignCenter">
                    <h3>{{ type == 'course' ? 'Course' : 'Activity' }} Name</h3>
                    <h4>
                        {{ values.name }}
                        <template v-if="type == 'course' && values.code"> ({{ values.code }}) </template>
                    </h4>

                    <p class="marginTop20">You will be notified when your</p>
                    <p>Grades Report is ready (approx. 2-3 minutes).</p>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                    <button class="btn btn-success" @click.prevent="submit">Yes, Proceed</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';

const modal = ref(null);
const values = ref({});
const type = ref('course');

const generate = (v = {}, t = 'course') => {
    values.value = v;
    type.value = t;
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .get(`/${type.value == 'course' ? 'courses' : 'tests'}/${values.value.uuid}/grades`, { responseType: 'blob' })
        .then((response) => {
            closeModal();
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'We will generate the Grades Report for this course and notify you once finished',
            });
        })
        .catch((error) => {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong while generating the Grades Report',
            });
        });
};

defineExpose({ generate });
</script>
