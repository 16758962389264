<template>
    <div
        ref="modal"
        class="modal danger-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="removeCollaborator-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="removeCollaborator-title" class="modal-title">Remove Collaborator</h2>
                </div>

                <div class="modal-body textAlignCenter">
                    <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />
                    <h3 class="marginTop40 fw-normal">You are removing</h3>
                    <h3>{{ collaborator.displayName }} ({{ collaborator.role }})</h3>
                    <h3 class="fw-normal marginTop10">
                        from <b>{{ course.name }}</b>
                    </h3>

                    <!-- <p class="marginTop20">Once the staff is removed</p>
                    <p>from the course, they will no longer</p>
                    <p>have access to view or manage</p>
                    <p>any content related to the course.</p> -->
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeModal()">Cancel</button>
                    <button class="btn btn-danger" @click.prevent="submit()">Remove Collaborator</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';

const emit = defineEmits(['collaborator-removed']);
const modal = ref(null);
const course = ref({});
const collaborator = ref({});

const remove = (c = {}, col = {}) => {
    course.value = c;
    collaborator.value = col;
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .delete(`courses/${course.value.uuid}/collaborators/${collaborator.value.id}`)
        .then((response) => {
            closeModal();
            emit('collaborator-removed');
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Collaborator removed successfully',
            });
        })
        .catch((error) => {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Unable to remove collaborator',
            });
        });
};

defineExpose({ remove });
</script>
