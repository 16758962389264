<template>
    <div class="col-xs-12 right-container">
        <div class="col-md-12 form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <h1>New Course</h1>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <course-form :form="form"></course-form>
                        <div class="buttonGroup marginTop30">
                            <button class="btn btn-success" :disabled="!canSend ? true : undefined" aria-label="Save" @click.prevent="submit">
                                <i class="far fa-save marginRight8" aria-hidden="true"></i>Save
                            </button>
                            <router-link v-slot="{ navigate }" :to="{ name: 'courses.index' }" custom>
                                <button class="btn btn-default" @click="navigate">Cancel</button>
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert2';
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    name: '',
                    code: '',
                    description: 'type!:array',
                    startDate: '',
                    endDate: '',
                    coverImage: '',
                },
                props: {},
                urls: {
                    post: 'courses',
                },
            }),
        };
    },
    components: {
        'course-form': require(`./form-partials/partial.course.vue`).default,
    },
    computed: {
        canSend() {
            if (this.form.model.name && this.form.model.startDate && this.form.model.endDate) {
                return true;
            }
            return false;
        },
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
            breadcrumb: [{ name: 'Home' }, { name: 'Courses', link: 'courses.index' }, { name: 'New Course' }],
        });
        this.form.model.startDate = moment().startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        this.form.model.endDate = moment().endOf('day').utc().add(6, 'months').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    },
    mounted() {
        this.form.model.description = {
            isHTML: false,
            description: '',
        };
    },
    methods: {
        submit() {
            var that = this;
            this.form.post().then(function (response) {
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'courses.view',
                        params: { id: response.data.data.uuid },
                    });

                    Events.fire('fetchRolePermissions');
                }
            });
        },
    },
};
</script>
