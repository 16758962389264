<template>
    <div
        ref="modal"
        class="modal default-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="chooseCollaboratorRole-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="chooseCollaboratorRole-title" class="modal-title">
                        <template v-if="course?.autoJoinRole?.id"> Change Course Collaborator Role </template>
                        <template v-else> Choose Course Collaborator Role </template>
                    </h2>
                </div>

                <div class="modal-body textAlignCenter">
                    <h3>
                        <template v-if="course?.autoJoinRole?.id"> Change Role </template>
                        <template v-else> Choose Role </template>
                        for
                    </h3>
                    <h4>
                        {{ course.name }}
                    </h4>

                    <p class="marginTop20">
                        Once roles are selected and toggles are enabled, all users in your organization can automatically join and be assigned to the designated
                        role.
                    </p>

                    <fieldset class="marginTop20 newRadioField smaller-ver textAlignLeft">
                        <legend>Choose Course Collaborator Role</legend>
                        <div v-for="role in roles" :key="role.id" class="radioField flexOnly align-items">
                            <input :id="id + '-' + role.name" v-model="selectedRole" type="radio" :value="role" name="Course Collaborator Role" />
                            <label :for="id + '-' + role.name" class="width100">
                                {{ role.name }}
                            </label>
                        </div>
                    </fieldset>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                    <button
                        v-tooltip="selectedRole.name == course?.autoJoinRole?.name ? 'Please select a different role' : ''"
                        class="btn btn-success"
                        :class="{ 'disabled hasToolTip': selectedRole.name == course?.autoJoinRole?.name }"
                        @click="submit()"
                    >
                        <i class="fas fa-save marginRight8" aria-hidden="true" /> Save Role
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';

const emit = defineEmits(['selectRole']);
const modal = ref(null);
const course = ref({});
const roles = ref([]);
const selectedRole = ref('');

const select = (c = {}) => {
    course.value = c;
    fetchRoles();
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const fetchRoles = () => {
    axios.get('course/roles').then((response) => {
        roles.value = response.data.data.filter((role) => role.name !== 'Course Owner');
        selectedRole.value = roles.value.filter((role) => role.name == course?.value?.autoJoinRole?.name)[0] || roles.value[0];
    });
};

const submit = () => {
    emit('selectRole', selectedRole.value);
    closeModal();
};

defineExpose({ select });
</script>
