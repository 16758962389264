<template>
    <div
        ref="modal"
        class="modal primary-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="duplicateModuleModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="duplicateModuleModal-title" class="modal-title">Duplicate Module(s)</h2>
                </div>
                <div class="modal-body textAlignCenter">
                    <div class="bg-grey padding15 marginBottom20 modalListingArray">
                        <h2 class="marginTop0">Module Name</h2>
                        <ul>
                            <li v-for="(module, idx) in selectedModules" :key="'list_of_module_copy_' + idx">{{ idx + 1 }}. {{ module.name }}</li>
                        </ul>
                    </div>

                    <p v-if="permission" class="marginTop20">Please select a course</p>
                    <p v-if="permission">you wish to copy the selected module(s) to.</p>

                    <p class="col-xs-12 col-md-8 col-md-offset-2 marginTop20">
                        <b>
                            Note: only the corresponding activities and questions will be copied; student and team configurations will remain in this course.
                        </b>
                    </p>

                    <div v-if="permission" class="form-group marginTop10 textAlignLeft">
                        <label>Course</label>
                        <select v-model="selectedCourse" class="form-control" :aria-label="selectedCourse">
                            <option :value="false">-</option>
                            <template v-for="(course, idx) in courseList" :key="`c-list-${idx}`">
                                <option :value="course">
                                    {{ course.name }}
                                </option>
                            </template>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                    <button
                        v-tooltip="selectedCourse ? '' : 'Please select a course'"
                        class="btn btn-success"
                        :class="{ 'disabled hasToolTip': selectedCourse == '' }"
                        @click.prevent="selectedCourse ? submit() : null"
                    >
                        <i class="fa fa-copy marginRight8" aria-hidden="true" />Yes, Duplicate Module
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';

const emit = defineEmits(['clearSelectedModules']);

const modal = ref(null);
const course = ref({});
const courseList = ref([]);
const modules = ref([]);
const selectedModules = ref([]);
const selectedCourse = ref(false);
const permission = ref(false);

const duplicate = (cc = {}, c = [], m = [], s = [], p = false) => {
    course.value = cc;
    courseList.value = c;
    modules.value = m;
    selectedCourse.value = false;
    selectedModules.value = [];
    permission.value = p;

    if (!permission.value) {
        selectedCourse.value = { uuid: course.value.uuid, name: course.value.name };
    }

    s.forEach((value) => {
        const index = m.findIndex((obj) => obj.uuid === value);
        selectedModules.value.push(m[index]);
    });

    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .post(`courses/${course.value.uuid}/modules/copy`, {
            courseUuid: selectedCourse.value.uuid,
            moduleUuids: selectedModules.value.map((module) => module.uuid),
        })
        .then(function (response) {
            closeModal();

            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Module(s) has been successfully copied',
            });

            emit('clearSelectedModules');
            Events.fire('modules_listing_refresh');
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Unable to copy module(s) at this moment, please try again later',
            });
        });
};

defineExpose({ duplicate });
</script>
