<template>
    <div
        id="useTemplateModal"
        ref="modal"
        class="modal primary-modal useTemplateModal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="useTemplateModal-title"
    >
        <div class="modal-dialog">
            <div class="preview modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="useTemplateModal-title" class="modal-title">Use Template</h2>
                </div>

                <div class="modal-body" :class="{ textAlignCenter: step == 1 }">
                    <h2 v-if="template">
                        {{ template.name }}
                    </h2>

                    <div class="stepper-container">
                        <div class="stepper-wrapper justifyCenter">
                            <button
                                class="stepper-item"
                                :class="{
                                    active: step == 1,
                                    completed: step == 2,
                                }"
                                @click.prevent="step = 1"
                            >
                                <template v-if="step == 1">
                                    <div class="step-marker-container">
                                        <div class="step-marker"></div>
                                    </div>
                                    <div class="visible-hidden">Current at step 1</div>
                                </template>
                                <template v-else-if="step == 2">
                                    <div class="step-completed material-icons" aria-hidden="true">check</div>
                                    <div class="visible-hidden">Completed Step 1</div>
                                </template>
                                <template v-else>
                                    <div class="visible-hidden">Step</div>
                                    <div class="step-counter">1</div>
                                </template>

                                <label> Course & Module </label>
                            </button>
                            <button
                                class="stepper-item"
                                :class="{
                                    active: step == 2,
                                    completed: step == 3,
                                }"
                                :disabled="!(values.courseUuid && values.moduleUuid)"
                                @click.prevent="values.courseUuid && values.moduleUuid ? (step = 2) : ''"
                            >
                                <template v-if="step == 2">
                                    <div class="step-marker-container">
                                        <div class="step-marker"></div>
                                    </div>
                                    <div class="visible-hidden">Current at step 2</div>
                                </template>
                                <template v-else-if="step == 3">
                                    <div class="step-completed material-icons" aria-hidden="true">check</div>
                                    <div class="visible-hidden">Completed Step 2</div>
                                </template>
                                <template v-else>
                                    <div class="visible-hidden">Step</div>
                                    <div class="step-counter">2</div>
                                </template>

                                <label> Activity </label>
                            </button>
                        </div>
                    </div>

                    <template v-if="step == 1">
                        <p>Please select course and module</p>
                        <p>you wish to use the selected template to</p>
                        <Field v-slot="{ field }" type="select" name="courseUuid">
                            <div class="marginTop20 textAlignLeft">
                                <label for="useCourseLabel">Course</label>
                                <div class="form-dropdown viewFilter marginLeft0">
                                    <select id="useCourseLabel" v-bind="field" class="form-control" aria-labelledby="useCourseLabel">
                                        <option :value="false">-</option>
                                        <template v-for="(course, idx) in coursesList" :key="`c-list-${idx}`">
                                            <option :value="course.uuid">
                                                {{ course.name }}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                            </div>
                        </Field>
                        <Field v-slot="{ field }" type="select" name="moduleUuid">
                            <div class="marginTop20 textAlignLeft">
                                <label for="useModuleLabel">Module</label>
                                <div class="form-dropdown viewFilter marginLeft0">
                                    <select id="useModuleLabel" v-bind="field" class="form-control" aria-labelledby="useModuleLabel">
                                        <option :value="false">-</option>
                                        <template v-for="(module, idx) in modulesList" :key="`m-list-${idx}`">
                                            <option :value="module.uuid">
                                                {{ module.name }}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                            </div>
                        </Field>

                        <p class="marginTop20">
                            No existing course?
                            <router-link :to="{ name: 'courses.create' }" class="link"> Create a course </router-link>
                        </p>
                        <p>Otherwise, you can <b>create module</b> within your course</p>
                        <p>and load the template from <b>create activity</b>.</p>
                    </template>
                    <template v-else-if="step == 2">
                        <BasicInfoForm :values="values" mode="edit"></BasicInfoForm>
                    </template>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                    <button
                        v-if="step == 1"
                        v-tooltip="!(values.courseUuid && values.moduleUuid) ? 'Please select course and module' : ''"
                        class="btn btn-success"
                        :class="{
                            'disabled hasToolTip': !(values.courseUuid && values.moduleUuid),
                        }"
                        :aria-label="!(values.courseUuid && values.moduleUuid) ? 'Next Step disabled' : 'Next Step'"
                        @click.prevent="values.courseUuid && values.moduleUuid ? (step = 2) : ''"
                    >
                        Next Step
                    </button>
                    <button
                        v-else-if="step == 2"
                        v-tooltip="!values.name ? 'Please fill in the name' : ''"
                        class="btn btn-success"
                        :class="{
                            'disabled hasToolTip': !values.name,
                        }"
                        :aria-label="!values.name ? 'Continue disabled' : 'Continue'"
                        @click.prevent="submit(values)"
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import { ref, nextTick, onBeforeMount, watch } from 'vue';
import { useForm, Field } from 'vee-validate';
import BasicInfoForm from './../form-partials/partial.basic_info.vue';
import * as yup from 'yup';
const router = useRouter();
const step = ref(1);
const modal = ref(null);
const template = ref(null);
const coursesList = ref([]);
const modulesList = ref([]);
var schema = yup.object({
    templateUuid: yup.string().required(),
    courseUuid: yup.string().required(),
    moduleUuid: yup.string().required(),
    name: yup.string().required(),
    type: yup.string().required(),
    description: yup.string().nullable(),
    descriptionIsHTML: yup.boolean().default(true),
    activityUuid: yup.string().required(),
    evaluationSettings: yup.object().shape({
        target: yup.string().default('peers'),
    }),
    others: yup.object().nullable(),
});
var initialValues = ref({
    templateUuid: '',
    courseUuid: '',
    moduleUuid: '',
    name: '',
    type: '',
    description: '',
    descriptionIsHTML: true,
    evaluationSettings: {
        target: 'peers',
    },
    others: null,
});
const { values, meta, setValues, setErrors } = useForm({
    initialValues: initialValues,
    validationSchema: schema,
    keepValuesOnUnmount: true,
});
function openModal() {
    nextTick(() => {
        $(modal.value).modal('show');
    });
}
function closeModal() {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
}
function fetchCoursesList() {
    axios.get('courses/list').then(function (response) {
        coursesList.value = response.data.data;
    });
}
function fetchModulesList() {
    axios.get('/courses/' + values.courseUuid + '/modules/list').then(function (response) {
        modulesList.value = response.data.data;
    });
}
function submit(values) {
    axios
        .post('activities', values)
        .then(function (response) {
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Successfully created activity from template',
            });
            closeModal();
            router.push({ name: 'courses.modules.activities.continue', params: { id: response.data.data.uuid } });
        })
        .catch(function (errors) {
            setErrors(_.get(errors, 'response.data.errors', {}));
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong. Please try again later',
            });
        });
}

function hydrate(data) {
    if (!data) {
        setValues(initialValues.value);
    } else {
        setValues(data);
    }
}
function create(data) {
    template.value = data;
    if (data.type || data.activityType == 'application') {
        hydrate({
            templateUuid: data.uuid,
            type: data.activityType || data.type,
            evaluationSettings: data.evaluationSettings,
            others: { applicationType: 'team', isApplicationGraded: true },
        });
    } else if (data.type || data.activityType == 'peerEvaluationV2') {
        hydrate({ templateUuid: data.uuid, type: data.activityType || data.type, evaluationSettings: data.evaluationSettings, others: [] });
    } else {
        hydrate({ templateUuid: data.uuid, type: data.activityType || data.type, evaluationSettings: data.evaluationSettings, others: null });
    }
    openModal();
}
watch(
    () => values.courseUuid,
    () => {
        fetchModulesList();
    }
);
onBeforeMount(() => {
    fetchCoursesList();
});
defineExpose({
    create,
});
</script>
