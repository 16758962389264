<template>
    <div
        ref="modal"
        class="modal primary-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="joinCourseModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="joinCourseModal-title" class="modal-title">Join Course</h2>
                </div>

                <div class="modal-body textAlignCenter">
                    <h3>Joining to</h3>
                    <h4>
                        {{ course.name }}
                        <template v-if="course.code"> ({{ course.code }}) </template>
                    </h4>

                    <p class="marginTop20">
                        You're joining the course as a
                        <b>
                            <template v-if="role == 'Super Admin'"> Course Admin. </template>
                            <template v-else> {{ course.autoJoin?.roleName }}. </template>
                        </b>
                    </p>
                    <p>If you prefer a different role,</p>
                    <p>
                        you can discuss it with the <b> {{ course.owner }} </b>
                    </p>
                    <p>to update on the collaborator page.</p>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                    <button class="btn btn-success" @click.prevent="submit">Join Course</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';

const router = useRouter();
const modal = ref(null);
const course = ref({});
const role = ref('');

const join = (c = {}, r = '') => {
    course.value = c;
    role.value = r;
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .post(`courses/${course.value.uuid}/collaborator-user`)
        .then(function (response) {
            closeModal();
            router.push({
                name: 'courses.view',
                params: { id: course.value.uuid },
            });
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Joined the course successfully',
            });

            Events.fire('fetchRolePermissions');
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Unable to join the course at this moment, please try again later',
            });
        });
};

defineExpose({ join });
</script>
