<template>
    <div
        id="viewAnswerExplanationModal"
        ref="modal"
        class="modal viewAnswerExplanationModal"
        :class="{
            'warning-modal': testObj.tratStatus != null && !['ended', 'completed'].includes(testObj.tratStatus),
            'default-modal': testObj.tratStatus == null || (testObj.tratStatus != null && ['ended', 'completed'].includes(testObj.tratStatus)),
        }"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="viewAnswerExplanationModal-title"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h1 id="viewAnswerExplanationModal-title" class="modal-title">View Answer & Explanation</h1>
                </div>

                <div class="modal-body">
                    <template v-if="testObj.tratStatus == null || (testObj.tratStatus != null && ['ended', 'completed'].includes(testObj.tratStatus))">
                        <h2 class="margin0">Question {{ getQuestionNo(question.group - 1, question.order - 1) }}</h2>

                        <div class="collapsingQuestion textAlignLeft marginTop20 fs-18px">
                            <button
                                class="btn"
                                :data-target="'#viewQuestion-' + getQuestionNo(question.group - 1, question.order - 1) + '-correctAnswer'"
                                data-toggle="collapse"
                                aria-expanded="false"
                                :aria-controls="'viewQuestion-' + getQuestionNo(question.group - 1, question.order - 1) + '-correctAnswer'"
                            >
                                Correct Answer
                            </button>

                            <div :id="'viewQuestion-' + getQuestionNo(question.group - 1, question.order - 1) + '-correctAnswer'" class="collapse padding10">
                                <template v-for="(option, idx) in question.options">
                                    <template v-if="option.isCorrect">
                                        <div class="radioField flex">
                                            <label>
                                                <span v-if="!testObj.settings.hideQuestionOptionLabels" class="withOptionKey"> {{ option.key }}. </span>
                                                <span class="paddingTop2" v-dompurify-html="option.content" />
                                            </label>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                        <div class="darkAnsExplanation marginTop20 fs-18px">
                            <div class="flexOnly align-items">
                                <i class="fa fa-info-circle marginRight8" aria-hidden="true" />
                                <p>The Explanation is:</p>
                            </div>

                            <div>
                                <i>
                                    <kr-math :key="question.uuid + '-hint-explanation'" :input="question.explanation" />
                                </i>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <h2>Temporarily Unavailable</h2>
                        <p>Because the TRAT is still ongoing, the answer will be visible once it's ended.</p>
                    </template>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['read']);
import { ref, nextTick } from 'vue';

const modal = ref(null);
const testObj = ref({});
const question = ref({});
const questionStatus = ref();

const openModal = () => {
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const read = (t = {}, q = {}) => {
    testObj.value = t;
    question.value = q;
    questionStatus.value = status;

    openModal();
};

const getQuestionNo = (question_group_idx, question_idx) => {
    let count = 0;
    for (var i = 0; i < question_group_idx; i++) {
        count += testObj.value.questions[i].length;
    }
    count += question_idx + 1;
    return count;
};

defineExpose({ read });
</script>
