<template>
    <div
        ref="modal"
        class="modal danger-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="archiveCourseModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="archiveCourseModal-title" class="modal-title">Archive {{ type === 'courses' ? 'Course' : 'Module' }}</h2>
                </div>
                <div class="modal-body textAlignCenter">
                    <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                    <h2 class="marginTop40">Archive {{ type === 'courses' ? 'Course' : 'Module' }}</h2>

                    <h3 class="marginBottom20 fw-normal capitalize">
                        {{ values.name }}
                    </h3>

                    <p class="marginTop20">Archiving this {{ type === 'courses' ? 'course' : 'module' }} will hide it</p>

                    <p>from your active {{ type === 'courses' ? 'courses' : 'modules' }} list.</p>

                    <p class="marginTop20">
                        <i>You can reinstate it later by filtering</i>
                    </p>

                    <p>
                        <i>your {{ type === 'courses' ? 'courses' : 'modules' }} and showing Archived {{ type === 'courses' ? 'courses' : 'modules' }}.</i>
                    </p>

                    <p class="marginTop20">
                        <b>Are you sure to archive the {{ type === 'courses' ? 'course' : 'module' }}?</b>
                    </p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                    <button class="btn btn-danger" @click.prevent="submit">
                        <i class="fa fa-archive marginRight8" aria-hidden="true" />Yes, Archive {{ type === 'courses' ? 'course' : 'module' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import { notify } from '@kyvg/vue3-notification';

const modal = ref(null);
const values = ref({});
const type = ref('courses');

const archive = (v = {}, t = 'courses') => {
    values.value = v;
    type.value = t;
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const submit = () => {
    axios
        .patch(type.value === 'courses' ? `/courses/${values.value.uuid}/archive` : `/modules/${values.value.uuid}`)
        .then(function (response) {
            closeModal();
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: `You have successfully archived this ${type.value === 'courses' ? 'course' : 'module'}`,
            });
            Events.fire(type.value + '_listing_refresh');
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong, please try again later',
            });
        });
};

defineExpose({ archive });
</script>
