<template>
    <div class="col-xs-12 right-container">
        <cl-tabs-nav :array-value="tabs" @emitCurrentTab="currentTab = $event" />

        <div class="tab-content" id="nav-tabContent">
            <template v-for="tab in tabs">
                <div
                    v-if="tab.show"
                    :key="tab.id"
                    :id="`${tab.id}-panel`"
                    class="tab-pane"
                    :class="{ 'in active': tab.id == currentTab, fade: tab.id != currentTab }"
                    role="tabpanel"
                    :aria-labelledby="`${tab.id}-tab`"
                    :hidden="currentTab != tab.id"
                    :tabindex="currentTab === tab.id ? 0 : -1"
                >
                    <div class="pageTitle">
                        <h1>{{ tab.label }}</h1>
                        <p class="col-md-6">
                            <template v-if="tab.id == 'teachers'">
                                This segment incorporates all the teachers who have been enrolled in your university’s account. There are varying levels of
                                access and these depend on the teacher’s function on the account.
                                <a href="https://help.intedashboard.com/how-do-i-reset-an-instructors-password" target="newTab">
                                    <i class="fas fa-info-circle" aria-hidden="true" />
                                    Reset an Instructor's Password
                                </a>
                            </template>
                            <template v-if="tab.id == 'students'">
                                This page contains all the students enrolled in your university’s account. Students who appear here can be added to courses and
                                divided into groups.
                                <a href="https://help.intedashboard.com/my-learner-cannot-see-the-activity" target="newTab">
                                    <i class="fas fa-info-circle" aria-hidden="true" />
                                    My student cannot see the activity
                                </a>
                            </template>
                        </p>
                    </div>

                    <teachers-listing v-if="tab.id == 'teachers'" />
                    <students-listing v-if="tab.id == 'students'" />
                </div>
            </template>
        </div>
        <kr-reset-password />
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import studentsListing from './students/partials/index.vue';
import teachersListing from './teachers/partials/index.vue';

const tabs = ref([
    {
        id: 'teachers',
        label: 'Teachers',
        show: true,
    },
    {
        id: 'students',
        label: 'Students',
        show: true,
    },
]);

const currentTab = ref('');

watch(currentTab, (newVal) => {
    var tabName = tabs.value.find((t) => t.id == newVal)?.label;

    document.title = `${tabName} | Course | InteDashboard | TBL Makes Teams Work`;

    Events.fire('topbar_update', {
        title: '<i class="fa fa-users"></i>&nbsp;People',
        breadcrumb: [
            { name: 'Home' },
            { name: 'People', link: 'people.index' },
            {
                name: tabName,
            },
        ],
    });
});
</script>
